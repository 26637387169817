import React from "react";
// Customizable Area Start
import {  Box,
  Button,
  Grid,
  Card,
  Modal,
  TextField,
  Typography,
  MenuItem,
   Select,
  RadioGroup, FormControlLabel, createMuiTheme,
  Radio, FormControl, Tabs, Tab, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from "@material-ui/core";
  import {  Close } from "@material-ui/icons";
import "./style.css";
import { ThemeProvider } from "@material-ui/styles";
import GetProjectTemplateFieldsController, {
  Props,
} from "./GetProjectTemplateFieldsController";
const templateIcon= require("../assets/tl-template.png");
const checklistIcon = require("../assets/tl-checklist.png");
const Checklist = require("../assets/Checklist.png");
const Template=require("../assets/Template.png");
const iconArrow = require("../assets/iconArrow.png");
import { invoiceimage } from "../../AdminConsole3/src/assets";
import { AiOutlineDelete } from "react-icons/ai";
import TemplateBreadcrumbs from '../../ProjectTemplates/src/TemplateBreadcrumbs.web'
// Customizable Area End

export default class AdminSubfolderManagement extends GetProjectTemplateFieldsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  handleCancel = () => {

};
  handleBack = () => {
    //istanbul ignore next
    if (this.query.get('sfid')) {
      if (localStorage?.getItem('user_type') == 'Superadmin') this.props.history.push(`/client_subfolder?cid=${this.query.get('cid')}`);
      else if (localStorage?.getItem('user_type') == 'Admin') this.props.history.push(`/clientsubfolders?cid=${this.query.get('cid')}`);
    }
    else {
      if (localStorage?.getItem('user_type') == 'Superadmin') return this.props.history.push(`/clients`, { state: "appliedFilter" });
      else if (localStorage?.getItem('user_type') == 'Admin') return this.props.history.push(`/clientmanagement`, { state: "appliedFilter" });
    }
  };

  setTab=(newValue:any)=>{
    if(this.state.template != "Telecom Template" && newValue == 1){
      return
    }
      this.setState({
          selectedInvoiceMethodTab: newValue
      })
  }

  renderDefineInvoice = () => {
       const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
          id:"customSuggestionsContainer1"
        },
      };

    if(this.state.defineInvoiceModal) {
        return (
            <Modal style={webStyle.modalbackdrop} open={this.state.defineInvoiceModal} onClose={this.handleCloseDefineInvoiceModal} data-test-id={"addModalpopup"}>
                  <div style={webStyle.modalcontentsDefine}>
                        <div style={webStyle.modalContent}>
                            <div style={webStyle.addworkspacemodaldiv}>
                                <div style={webStyle.addworkspaceseconddiv}>
                                    <Box style={webStyle.headerBox}>
                                        <h4 style={webStyle.addEditHeading}>Define Invoice</h4>
                                        <Close  onClick={this.handleCloseDefineInvoiceModal} style={webStyle.closebuttonstyle}/>
                                    </Box>
                                </div>
                            </div>
                            <div style={webStyle.addworkspacemaincontentdiv}>
                                <div style={webStyle.addworkspacedivcontent} className="addclientdivcontent">
                                    <div style={webStyle.addworkspaceelements}>
                                        <p style={webStyle.modaltextboxheader}><strong>Client id</strong></p>
                                        <TextField variant="outlined" fullWidth  value={this.state.subfolderClientId} disabled style={webStyle.textfieldDisabled}/>
                                        <p style={webStyle.modaltextboxheader}><strong>{"Client Name(Displayed on Invoices)*"}</strong></p>
                                        <TextField data-test-id="subfolderClientNameForDefineInvoiceID" variant="outlined" fullWidth  value={this.state.subfolderClientNameForDefineInvoice}
                                            onChange={(e)=>{
                                                this.setState({subfolderClientNameForDefineInvoice:e.target.value,
                                                    isSubfolderClientNameForDefineInvoiceError: false});
                                            }}
                                        />
                                        {
                                            this.state.isSubfolderClientNameForDefineInvoiceError &&
                                            <Typography style={webStyle.errorText}>Client name is required</Typography>
                                        }


                                        <p style={webStyle.modaltextboxheader}><strong>Currency*</strong></p>
                                        <Select
                                            variant="outlined"
                                            name="taxPay"
                                            MenuProps={MenuProps}
                                            value={this.state.selectedCurrency}
                                            // label="Age"
                                            className="input-type"

                                            required
                                            fullWidth
                                            style={{ height: "40px", textAlign: "left"}}
                                            onChange={this.handleChangeCurrency}
                                            >
                                                {this?.state?.currencyData.map((item:any)=><MenuItem value={item}>{item}</MenuItem>)}

                                            {/* <MenuItem value="true">Yes</MenuItem> */}
                                        </Select>
                        {this.state.isSelectedCurrencyError && <Typography style={webStyle.errorText}>Currency is required</Typography>}
                                        <p style={webStyle.modaltextboxheader}><strong>Invoice Frequency*</strong></p>
                                        <Select
                      variant="outlined"
                      name="taxPay"
                      value={this.state.selectedFrequency}
                      MenuProps={MenuProps}
                      className="input-type"

                      required
                      fullWidth
                      style={{ height: "40px", textAlign: "left"}}
                      onChange={this.handleChangeFrequency}
                    >
                      {this.state.frequencyData.map((item:any)=><MenuItem value={item}>{item}</MenuItem>)}
                    </Select>
                    {this.state.isSelectedFrequencyError && <Typography style={webStyle.errorText}>Invoice Frequency is required</Typography>}


                                        <p style={webStyle.modaltextboxheader}><strong>Description</strong></p>
                                        <TextField data-test-id="addSubfolderDescriptionForInvoiceID" variant="outlined" fullWidth multiline rows={5} placeholder="Type here" value={this.state.addSubfolderDescriptionForInvoice}
                                        onChange={(e:any) => {this.setState({addSubfolderDescriptionForInvoice: e.target.value},()=>{
                                            console.log('addSubfolderDescriptionForInvoice',this.state.addSubfolderDescriptionForInvoice);
                                            
                                        })}}/>
                                        
                                        <p style={webStyle.modaltextboxheader}><strong>Project Invoicing Structure*</strong></p>
                                       




                                    <Grid container>
                                        <FormControl style={{width:"100%"}}>
                                            <RadioGroup aria-label="projectInvoicingStructure" name="projectInvoicingStructure" value={this.state.projectInvoicingStructure} onChange={(e) => {
                                                //istanbul ignore next
                                                this.setState({ projectInvoicingStructure: e.target.value })
                                            }}
                                            row
                                            style={{width:"100%", justifyContent:"space-between"}}
                                            >
                                                <Grid item sm={6} style={{ paddingRight: "5px" }}>
                                                    <Box
                                                        style={{ border: '1px solid #e6e6e6', display: "flex", justifyContent: "center", borderRadius: "4px", paddingLeft:10 }}
                                                    >
                                                        <FormControlLabel
                                                            value="project_creation_date" control={<Radio color="primary" />} label="Project creation date" />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={6} style={{ paddingLeft: "5px" }}>
                                                    <Box
                                                        style={{ border: '1px solid #e6e6e6', borderRadius: "4px", display: "flex", justifyContent: "center", paddingLeft:10  }} >
                                                        <FormControlLabel
                                                            value="project_completion_date" control={<Radio color="primary" />} label="Project completion date" />
                                                    </Box>
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>





                                        <p style={webStyle.modaltextboxheader}><strong>Invoice Generation Method*</strong></p>
                                        <Grid
                                         container
                                        style={{
                                         border :"1px solid #e6e6e6",
                                         borderRadius: "4px"
                                        }}
                                         >
                                          
                                            <ThemeProvider theme={theme}>
                                         
                                            <Tabs
                                                value={this.state.selectedInvoiceMethodTab}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={(e,newValue)=>{
                                                  this.setTab(newValue)
                                                }}
                                                style={{width : "100%"}}
                                                aria-label="disabled tabs example"
                                                TabIndicatorProps={{
                                                    style: {backgroundColor: "transparent"}}}
                                                
                                               
                                            >
                                                <Tab 
                                                style={{
                                                    fontWeight : 'bold',
                                                    color : "#000",
                                                    width : "50%",
                                                    backgroundColor : this.state.selectedInvoiceMethodTab == 0 ?
                                                     "#fff" : "#EBEBEB",
                                                    }} 
                                                     label="Type and Side elevation structure" />
                                                <Tab 
                                                style={{
                                                    fontWeight : 'bold',
                                                    color : "#000",
                                                    // paddingLeft : "50px",
                                                    // paddingRight : "63px",
                                                    width : "50%",
                                                    backgroundColor : this.state.selectedInvoiceMethodTab == 1 ? "#fff" : "#EBEBEB",
                                                    }}
                                                     label="Kw Structure"/>
                                            </Tabs>
                                            </ThemeProvider>
                                        
                                        {this.renderTypeSideView()}
                                        {this.renderKWStructureView()}
                                        

                                       {this.state.selectedInvoiceMethodTab == 0 && <Grid item xs={12} style={{padding : "0px 20px 20px 20px"}}> 
                                            <p style={webStyle.modaltextboxheader}><strong>Additional cost per file{" (Side Elevation)*"}</strong></p>
                                            <TextField data-test-id="additionalCostPerFileid" variant="outlined" fullWidth 
                                            disabled={!this.state.isAdditionalCost}
                                                value={this.state.additionalCostPerFile} onChange={(e)=>{
                                                    this.setState({additionalCostPerFile:e.target.value, isAdditionalCostPerFileError:false})
                                                }}/>
                                        {this.state.isAdditionalCostPerFileError && <Typography style={webStyle.errorText}>Additional cost per file is required</Typography>}
                                        </Grid>}
                                        </Grid>

                                        {this.renderTextComponents()}
                                    </div>
                                </div>
                            </div>
                            <div style={webStyle.addworkspacebuttonstyle}>
                                <Button style={webStyle.cancelButton} onClick={this.handleCloseDefineInvoiceModal}>Cancel</Button>
                                <Button data-test-id="addworkspaceButtonid" style={webStyle.addworkspaceButton} onClick={()=>{
                                        if(this.state.invoiceStatus){
                                            this.editDefineInvoice()
                                        }else{
                                            this.saveDefineInvoice()
                                        }
                                    }}>Save</Button>
                            </div>
                            
                        </div>
                    </div>
                </Modal>
        )
    }
  }

  renderTextComponents=()=>{
    return <>
    <p style={webStyle.modaltextboxheader}><strong>Tax Components*</strong></p>
    <Grid container sm={12} alignItems="center" style={{margin:"10px 0px"}}
    >
        <Grid item sm={4}>
            <p style={{color: "#8a8a8a"}}><strong>{"CGST (%)"}</strong></p>
        </Grid>
        <Grid item sm={8}>
            <TextField data-test-id="cgstinputid" variant="outlined" fullWidth value={this.state.cgst} onChange={(e)=>{
                this.setState({cgst:e.target.value, isCgtsError:false})
            }}/>
        </Grid>
        <Grid item sm={4}/>
        <Grid item sm={8}>
            {this.state.isCgtsError && <Typography style={webStyle.errorText}>CGST is required</Typography>}
        </Grid>
    </Grid>
    <Grid container sm={12} alignItems="center" style={{margin:"10px 0px"}}
    >
        <Grid item sm={4}>
            <p style={{color: "#8a8a8a"}}><strong>{"IGST (%)"}</strong></p>
        </Grid>
        <Grid item sm={8}>
            <TextField  data-test-id="igstinputid" variant="outlined" fullWidth value={this.state.igst} onChange={(e)=>{
                this.setState({igst:e.target.value, isIgstError:false})
            }}/>
        </Grid>
        <Grid item sm={4}/>
        <Grid item sm={8}>
            {this.state.isIgstError && <Typography style={webStyle.errorText}>IGST is required</Typography>}
        </Grid>
    </Grid>
    <Grid container sm={12} alignItems="center" style={{margin:"10px 0px"}}
    >
        <Grid item sm={4}>
            <p style={{color: "#8a8a8a"}}><strong>{"SGST (%)"}</strong></p>
        </Grid>
        <Grid item sm={8}>
            <TextField  data-test-id="sgstinputid" variant="outlined" fullWidth value={this.state.sgst} onChange={(e)=>{
                this.setState({sgst:e.target.value, isSgstError:false})
            }}/>

        </Grid>
        <Grid item sm={4}/>
        <Grid item sm={8}>
            {this.state.isSgstError && <Typography style={webStyle.errorText}>SGST is required</Typography>}
        </Grid>
    </Grid>
    <Grid container sm={12} alignItems="center" style={{margin:"10px 0px"}}
    >
        <Grid item sm={4}>
            <p style={{color: "#8a8a8a"}}><strong>{"Other Tax (%)"}</strong></p>
        </Grid>
        <Grid item sm={8}>
            <TextField data-test-id="otherTaxID" variant="outlined" fullWidth value={this.state.otherTax} onChange={(e)=>{
                this.setState({otherTax:e.target.value, isOtherTaxError:false})
            }}/>
        </Grid>
        <Grid item sm={4}/>
        <Grid item sm={8}>
            {this.state.isOtherTaxError && <Typography style={webStyle.errorText}>Other Tax is required</Typography>}
    </Grid>
    </Grid>
    </>
  }

  renderTypeSideView=()=>{
    return this.state.selectedInvoiceMethodTab == 0 && (
                                         
                                            <Grid
                                             item xs={12} 
                                             style={{padding:"20px"}}>
                                               <TableContainer>
                                                    <Table size="small" aria-label="a dense table" style={{borderRadius :"4px", border :"1px solid #e6e6e6"}}>
                                                        <TableHead style={{backgroundColor :"#EBEBEB"}}>
                                                        <TableRow>
                                                            <TableCell style={webStyle.cellHeadingStyle}>
                                                            Project Type
                                                               </TableCell>
                                                            <TableCell style={webStyle.cellHeadingStyle}>Price per file</TableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {this.state.projectTypes?.map((row:any) => (
                                                            <TableRow>
                                                            <TableCell style={webStyle.cellStyle}>{row?.project_type}</TableCell>
                                                            <TableCell >
                                                                <TextField
                                                                data-test-id="priceperfilterinputid"
                                                                type="number"
                                                                InputProps={{ disableUnderline: true }}
                                                                value={row.price_per_file}
                                                                onChange={(e)=>{
                                                                    let tempArr = this.state.projectTypes?.map((item:any)=>{
                                                                        if(item?.project_type == row?.project_type){
                                                                            return {...item, price_per_file: e.target.value}
                                                                        }
                                                                        return item
                                                                    });

                                                                    this.setState({projectTypes: tempArr, isProjectTypesError:false})
                                                                }}
                                                                />

                                                            </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                               
{this.state.selectedInvoiceMethodTab == 0 && this.state.isProjectTypesError && <Typography style={webStyle.errorText}>Price per file is required</Typography>}
                                            </Grid>
                                        
    )
  }

  renderKWStructureView=()=>{
return(
    this.state.selectedInvoiceMethodTab == 1 && (
                                            <>
                                                <Grid item xs={12} style={{ padding: "20px" }}>
                                                    <TableContainer
                                                        // style={{
                                                        //     borderRadius: '4px',
                                                        //     border: '1px solid #e6e6e6',
                                                        // }}
                                                    >
                                                        <Table size="small" aria-label="a dense table"
                                                        style={{
                                                            borderRadius: '4px',
                                                            border: '1px solid #e6e6e6',
                                                        }}
                                                        
                                                        >
                                                            <TableHead style={{ backgroundColor: "#EBEBEB" }}>
                                                                <TableRow>
                                                                    <TableCell style={webStyle.kwStructureHeading}>From</TableCell>
                                                                    <TableCell style={webStyle.kwStructureHeading}>To</TableCell>
                                                                    <TableCell style={webStyle.kwStructureHeading}>Price per unit</TableCell>
                                                                    {/* <TableCell style={webStyle.kwStructureHeading}></TableCell> */}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.state.kwStructure.map((row: any, index:any) => (
                                                                    <TableRow>
                                                                        <TableCell style={webStyle.KwcellStyle}>
                                                                            <TextField
                                                                            data-test-id="kwStructureinputid"
                                                                            type="number"
                                                                                value={row?.from ? row.from : ""}
                                                                                onChange={(e) => {
                                                                                    let tempArr = this.state.kwStructure?.map((item: any, i:any) => {
                                                                                        if (i == index) {
                                                                                            return { ...item, from: e.target.value }
                                                                                        }
                                                                                        return item
                                                                                    });

                                                                                    this.setState({ kwStructure: tempArr, isKwStructureError:false, isKwStructureDataError:false })
                                                                                }}
                                                                                InputProps={{ disableUnderline: true }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell style={webStyle.KwcellStyle}  >
                                                                            <TextField
                                                                             data-test-id="kwStructuretooinputid"
                                                                            type="number"
                                                                                value={row?.to ? row.to : ""}
                                                                                onChange={(e) => {
                                                                                    let tempArr = this.state.kwStructure?.map((item: any, i:any) => {
                                                                                        if (i == index) {
                                                                                            return { ...item, to: e.target.value }
                                                                                        }
                                                                                        return item
                                                                                    });
                                                                                    this.setState({ kwStructure: tempArr, isKwStructureError:false, isKwStructureDataError:false })
                                                                                }}

                                                                                InputProps={{ disableUnderline: true }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell style={webStyle.KwcellStyle} >
                                                                        <Grid direction="row" style={{display:'flex', alignItems:"center"}}>
                                                                            <TextField
                                                                            type="number"
                                                                            data-test-id="kwStructurepriceperunitinputid"
                                                                                value={row?.price_per_unit ? row.price_per_unit:""}
                                                                                onChange={(e) => {
                                                                                    let tempArr = this.state.kwStructure?.map((item: any, i:any) => {
                                                                                        if (i == index) {
                                                                                            return { ...item, price_per_unit: e.target.value }
                                                                                        }
                                                                                        return item
                                                                                    });

                                                                                    this.setState({ kwStructure: tempArr, isKwStructureError:false, isKwStructureDataError: false })
                                                                                }}

                                                                                InputProps={{ disableUnderline: true }}
                                                                            />
                                                                            <AiOutlineDelete
                                                                        style={{cursor:"pointer", fontSize:20}}
                                                                        onClick={()=>{
                                                                            console.log('row',row)
                                                                            this.deleteKwData(row?.id, index)
                                                                        }}
                                                                        />
                                                                        </Grid>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                {this.state.selectedInvoiceMethodTab == 1 && this.state.isKwStructureDataError && <Typography style={webStyle.errorText}>Data entered for kw structure is not correct</Typography>}
                                                {this.state.selectedInvoiceMethodTab == 1 && this.state.isKwStructureError && <Typography style={webStyle.errorText}>From, To and Price per unit is required </Typography>}

                                                </Grid>


                                                <Grid item xs={12} container justifyContent="center">
                                                    <Button
                                                        // color="primary"
                                                        data-test-id="addnewrangeid"
                                                        onClick={() => {
                                                            let isError = false;

                                                            for (const element of this.state.kwStructure) {
                                                                if(element.from == "" || element.to == "" || element.price_per_unit == ""){
                                                                  this.setState({isKwStructureError:true});
                                                                  isError = true;
                                                                }
                                                              }

                                                              if(isError){
                                                                return;
                                                              }

                                                            let data = [...this.state.kwStructure, {
                                                                "from": "",
                                                                "to": "",
                                                                "price_per_unit": ""
                                                            }]
                                                            this.setState({ kwStructure: data })
                                                        }}
                                                        style={webStyle.newRangebtn}>Add new range</Button>
                                                </Grid>
                                        </>
                                        )
)
  }

  setLocalStorageDate=()=>{ 
    localStorage.setItem('clientName', this.state.subfolderClientName);
    localStorage.setItem('subFolderClientId', this.state.subfolderClientId);
    localStorage.setItem('projectInvoicingStructure', this.state.projectInvoicingStructure);
    localStorage.setItem('invoiceId', this.state.invoiceId);
    localStorage.setItem('clientId', this.state.clientId);
    localStorage.setItem('isFromTemplatePath', "true");
    localStorage.setItem('fromTemplateTid', this.query.get('tid'));
    localStorage.setItem('fromTemplateCid', this.query.get('cid'));
  }
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div style={webStyle.container} className="boxcontainer">
          <div className="content1" style={webStyle.content}>
          <TemplateBreadcrumbs
                navigation={""}
                id={""}
                that={this}
                history={this.props.history} />
          </div>
          <div className="content" style={webStyle.contents}>
            <Grid container spacing={1}>
              <Card
                style={webStyle.twocards}
                onClick={() =>
                  //istanbul ignore next
                  this.props.history.push(`/projectlist?cid=${this.query?.get('cid')}&sfid=${this.query?.get('sfid') || ""}&tid=${this.query?.get('tid')}`)}
              >
                <div>
                  <div style={webStyle.iconimages}>
                     <img
                      src={localStorage.getItem('user_type')=='Superadmin'? Template:templateIcon}
                      alt="template"
                      style={webStyle.icons}
                    />
                  </div>
                  <Button style={webStyle.cardLabel}><b>Template</b></Button>
                </div>
              </Card>
              <Card
                style={webStyle.twocards}
                onClick={() => 
                  //istanbul ignore next
                  this.props.history.push(`/definechecktable?cid=${this.query?.get('cid')}&sfid=${this.query?.get('sfid') || ""}&tid=${this.query?.get('tid')}`)}
              >
                <div>
                  <div style={webStyle.iconimages}>
                    <img
                      src={localStorage.getItem('user_type')=='Superadmin'? Checklist:  checklistIcon}

                      alt="checklist"
                      style={webStyle.icons}
                    />
                  </div>
                  <Button style={webStyle.cardLabel}><b>Checklist</b></Button>
                </div>
              </Card>
            </Grid>
            {localStorage.getItem('user_type') == "Superadmin" && !this.state.subfolderLength && this.state.projectTypes?.length > 0 && <Grid container  spacing={2} style={{marginBottom: 20, marginTop: 10, marginLeft: -20}}>
                                <Grid item sm={12} md={6} lg={3}>
                                    <Card
                                    onClick={this.handleDefineInvoiceModalOpen}
                                    style={{height: 250, backgroundColor: "white", margin: 10, marginTop: 10, marginBottom: 10, display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", justifyContent: "center", cursor :"pointer"}}>
                                    <img
                                    onClick={this.handleDefineInvoiceModalOpen}
                                    src={invoiceimage} 
                                    style={{width: 100, height: 100}}
                                    />
                                    <Button
                                        onClick={this.handleDefineInvoiceModalOpen}
                                        style={{textTransform: "none", marginTop: 20}}><b>Define Invoice</b></Button>
                                    </Card>
                                </Grid>
                                {this.state.invoiceStatus && <Grid item sm={12} md={6} lg={3}>
                                    <Card 
                                    onClick={()=>{
                                      //istanbul ignore next
                                      this.setLocalStorageDate();
                                      this.props.history.push(`/client_invoices_superadmin?clientName=${this.state.subfolderClientName}&subFolderClientId=${this.state.subfolderClientId}&projectInvoicingStructure=${this.state.projectInvoicingStructure}&invoiceId=${this.state.invoiceId}&clientId=${this.state.clientId}&path=reviewChecklist&cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`)
                                    }}
                                    style={{height: 250, backgroundColor: "white", margin: 5, marginTop: 10, marginBottom: 10,  display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", justifyContent: "center", cursor: 'pointer',
                                    }}>
                                    <img
                                    onClick={()=>{
                                      //istanbul ignore next
                                      this.setLocalStorageDate();
                                      this.props.history.push(`/client_invoices_superadmin?clientName=${this.state.subfolderClientName}&subFolderClientId=${this.state.subfolderClientId}&projectInvoicingStructure=${this.state.projectInvoicingStructure}&invoiceId=${this.state.invoiceId}&clientId=${this.state.clientId}&path=reviewChecklist&cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`)
                                    }}
                                    src={invoiceimage} 
                                    style={{width: 100, height: 100}}/>
                                    <Button style={{textTransform: "none", marginTop: 20}} 
                                    onClick={()=>{
                                      this.setLocalStorageDate();
                                      this.props.history.push(`/client_invoices_superadmin?clientName=${this.state.subfolderClientName}&subFolderClientId=${this.state.subfolderClientId}&projectInvoicingStructure=${this.state.projectInvoicingStructure}&invoiceId=${this.state.invoiceId}&clientId=${this.state.clientId}&path=reviewChecklist&cid=${this.query.get('cid')}&tid=${this.query.get('tid')}`)
                                    }}
                                    ><b>Client Invoices</b></Button>
                                    </Card>
                                </Grid>}
                                </Grid>}

          </div>
          {this.renderDefineInvoice()}
        </div>

      </>
    );
  }
}
// Customizable Area End
// Customizable Area Start
const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
          textTransform :"none",
        "&.Mui-selected": {
          background: "#fff"
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
        },
      }
    }
  }
});

const webStyle = {
  container: {
    backgroundColor: "#eeeeee",
    height: "100vh",
    color: "#5f5f5f",
    fontFamily: "sans-serif",
    width: "85vw",
    top: "50px",
    right: 0,
    padding: "30px 20px 10px 0px",
  },

  breadcrumb: {
  marginLeft: 30,
  marginBottom: -10
  },

  content: {
    margin: 0,
    marginLeft: 25
  },

  sign: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "20px",
    fontFamily: "sans-serif",
    zIndex: 1300,
  },

  headnav: {
    marginLeft: 10,
    marginTop: "-55px",
    zIndex: 1300,
  },

  contents: {
    marginLeft: 30,
  },

  twocards: {
    display: "flex",
    height: 200,
    width: 400,
    marginRight: 30,
    marginTop: 20,
    padding: 20,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    cursor: 'pointer'
  },

  iconimages: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  arrow: {
    backgroundColor: "white",
    width: 35,
    height: 35,
    marginLeft: 30,
    borderRadius: 8,
    cursor: 'pointer'
  },

  icons: {
    width: 120,
    height: 120,
  },

  iconname: {
    marginLeft: 28,
    marginRight: 20,
  },

  iconnamecheck: {
    marginLeft: 32,
    marginRight: 20,
  },
  textfieldDisabled: {
    backgroundColor: "#e8e8e8"
},

  modalbackdrop: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    alignContent: "center"
  
  },
  modalcontentsDefine:{
    // width: "30%",
    width: "565px",
    height: "85%",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    alignItems: "left",
    justifyContent: "left",
    margin: 20,
    overflowX: "scroll",
    overflowY: "scroll",
} as React.CSSProperties,
modalContent: {
  //    margin: 20,
  //    marginTop: -10,
  },

  addworkspacemodaldiv: {
      backgroundColor: "white",
      marginLeft: 0,
      marginright: 0,
  },

  addworkspaceseconddiv: {
      display: "flex",
      justifyContent: "space-between",
  },

  addEditHeading: {
      marginLeft: 20,
  },
  headerBox: {
    borderBottom: '1px solid #e8e8e8',
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
} as React.CSSProperties,
closebuttonstyle: {
  marginTop: 20,
  marginRight: 20,
},
addworkspacemaincontentdiv: {
  backgroundColor: "white",
  marginBottom: 10,
  borderBottom: "1px solid #e8e8e8",
},

addworkspacedivcontent: {
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  marginRight: 20,
} as React.CSSProperties,

deleteworkspacedivcontent: {
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  marginRight: 20,
  marginTop: -13
} as React.CSSProperties,

addworkspacecontentdiv: {
  marginTop: 20
},

deleteworkspacecontentdiv :{
  marginTop: 20,
  marginBottom: 10,
},

addworkspceelements: {
  marginBottom: 20
},

addworkspacebuttonstyle: {
  backgroundColor: "white",
  marginTop: 20,
  display: "flex",
  justifyContent: "flex-end",
  marginRight: 20,
},

deleteworkspacebuttonstyle: {
  backgroundColor: "white",
  marginTop: 10,
  display: "flex",
  justifyContent: "flex-end",
  marginRight: 20,
},

addworkspaceelements: {
  marginBottom: 20
},
modaltextboxheader: {
  color: "#7a7a7a"
},
cancelButton: {
  display: "flex",
  width: "100px",
  border: "1px solid #cecece",
  height: "40px",
  backgroundColor: "#e8e8e8",
  marginLeft: 10,
  color: "black",
  fontWeight: 600,
  textTransform: "none",
}as React.CSSProperties,

addworkspaceButton: {
  display: "flex",
  width: "100px",
  border: "1px solid #cecece",
  height: "40px",
  backgroundColor: "#42a5f5",
  marginLeft: 10,
  color: "white",
  fontWeight: 600,
  textTransform: "none",
} as React.CSSProperties,
errorText: {color:"#f44336", fontSize:15, marginTop:5},
cellStyle : {
  borderRight : "1px solid #e6e6e6",
  width :"50%"
}as React.CSSProperties,
cellHeadingStyle :{
  borderRight : "1px solid #e6e6e6",
  width :"50%",
  fontWeight: "bold",
 }as React.CSSProperties,
 kwStructureHeading:{
  borderRight : "1px solid #e6e6e6",
  fontWeight: "bold",
  }as React.CSSProperties,

KwcellStyle :{
  borderRight : "1px solid #e6e6e6",
},
  newRangebtn: {
    textTransform: "none",
    color: "#42a5f5",
  } as React.CSSProperties,
  cardLabel: {
    textTransform: "none",
    marginTop: 20,
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  } as React.CSSProperties,
  arrowbox: {
    width: 36,
    height: 36,
    marginLeft: 34,
    cursor: 'pointer',
    borderRadius: 6,
    background: "white",
    backgroundBlendMode: "normal",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  },
};

// Customizable Area End
