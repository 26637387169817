// Customizable Area Start
import React from 'react';
import {
    Box,
    Grid,
    Card,
    CircularProgress
} from "@material-ui/core";
import { tltemplateimage } from './assets';
import { tlchecklistimage } from './assets';
import "./style.css";
import TLClientManageController from "./TLClientManageController";
export default class TLClientManagement extends TLClientManageController {
    render() {
        // const { loading, isTemplateCreated } = ;
        const renderTemplateSection = () => {
            if (this.state.loading) return (<Box style={webStyle.loader}><CircularProgress size={50} /></Box>);
            else if (this.state.isTemplateCreated) return (<div className="content" style={webStyle.contents}>
                <h3>{this.state.clientName}</h3>
                <Grid container spacing={1}>
                    <Card data-test-id="templateCardID" style={webStyle.twocards} onClick={() => this.saveBreadCrumbsDataAndRedirect('template')}>
                        <div>
                            <div style={webStyle.iconimages}>
                                <img src={tltemplateimage} alt="template" style={webStyle.icons} />
                            </div>
                            <div>
                                <h4 style={webStyle.iconname}>Template</h4>
                            </div>
                        </div>
                    </Card>
                    <Card data-test-id="checklistCardID" style={webStyle.twocards} onClick={() => this.saveBreadCrumbsDataAndRedirect('checklist')}>
                        <div>
                            <div style={webStyle.iconimages}>
                                <img src={tlchecklistimage} alt="checklist" style={webStyle.icons} />
                            </div>
                            <div>
                                <h4 style={webStyle.iconnamecheck}>Checklist</h4>
                            </div>
                        </div>
                    </Card>
                </Grid>
            </div>)
            else return (<Box style={{ minHeight: '60vh' }}>
                <div style={webStyle.noData}>No Data Found</div>
            </Box>)
        }
        return (
            <>
                <div style={webStyle.container} className="boxcontainer">
                    <div className="content1" style={webStyle.content}>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Box style={webStyle.sign} className="headnavbar">
                                    <h3 style={webStyle.headnav}>
                                        Details
                                    </h3>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    {renderTemplateSection()}
                </div>
            </>
        )
    }
}
const webStyle = {

    container: {
        backgroundColor: "#eeeeee",
        height: "100%",
        color: "#5f5f5f",
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right: 0,
        padding: "30px 20px 10px 0px",
    },

    content: {
        margin: 0
    },

    sign: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 1300,
    },


    headnav: {
        marginLeft: 10,
        marginTop: "-55px",
        zIndex: 1300,
    },

    contents: {
        marginLeft: 30
    },

    twocards: {
        display: "flex",
        height: 200,
        width: 400,
        marginRight: 30,
        marginTop: 20,
        padding: 20,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    iconimages: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    icons: {
        width: 120,
        height: 120,
    },

    iconname: {
        marginLeft: 28,
        marginRight: 20,
    },

    iconnamecheck: {
        marginLeft: 32,
        marginRight: 20,
    },
    loader: {
        minHeight: '55vh',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    } as React.CSSProperties,
    noData: {
        height: '100%',
        display: 'grid',
        placeItems: 'center'
      } as React.CSSProperties,
}
// Customizable Area End