// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { projectCarouselData, status } from "../../utilities/src/types";
import { ProjectCard } from '../../utilities/src/KanbanComponents';

export const configJSON = require("./config");

export const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    lilLargerDesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1350 },
      items: 3.5,
    },
    inBetweenDesktop: {
      breakpoint: { max: 1350, min: 1250 },
      items: 3.2,
    },
    lilSmallDesktop: {
      breakpoint: { max: 1250, min: 1150 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1150, min: 850 },
      items: 2.2,
    },
    mobile: {
      breakpoint: { max: 850, min: 770 },
      items: 2,
    },
    sm1more: {
      breakpoint: { max: 770, min: 590 },
      items: 1.5,
    },
    small: {
      breakpoint: { max: 590, min: 0 },
      items: 1,
    },
  };

export interface Props {
    projectStatus: keyof status,
    data: projectCarouselData,
    card: typeof ProjectCard,
    isVertical: boolean,
    statusColor: string
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    currentSlide: number,
    ActiveKeyBoardControl: boolean,
    deviceType: keyof typeof responsive,
    prevSlide: number,
}

interface SS {
    id: number;
}

export default class ProjectCarouselController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            currentSlide: 0,
            ActiveKeyBoardControl: false,
            deviceType: "desktop",
            prevSlide: 0,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
    }


    activeKeyBoardControlToggleTrue = () => {
        this.setState({
            ActiveKeyBoardControl: true
        });
    }
    activeKeyBoardControlToggleFalse = () => {
        this.setState({
            ActiveKeyBoardControl: false
        });
    }
    beforeChangeHandler = (nextSlide: number, element: string | undefined = "desktop") => {
        this.setState({
            currentSlide: nextSlide,
            deviceType: element as keyof typeof responsive
        });
    }
    afterChangeHandler = (prevSlide: number) => {
        this.setState({
            prevSlide: prevSlide
        })
    }

}

// Customizable Area End