import React from 'react';
// Customizable Area Start
import {Paper, Divider, Grid, Box, Link, CircularProgress, Button} from '@material-ui/core';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import {  Bar, Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,);
import {FullscreenExit, KeyboardArrowLeft, KeyboardArrowRight, Loop, ArrowLeftRounded} from '@material-ui/icons';
import './Graphs.css';


import FullSizeGraphController, { Props} from "./FullSizeGraphController";
// Customizable Area End

export default class FullSizeGraph extends FullSizeGraphController {
// Customizable Area Start
    constructor(props: Props) {
        super(props);
        this.chartRefer = React.createRef()
    }

    componentDidMount(): any {
        let token = localStorage.getItem("token")
        if(token) {
            this.getData()
    }
    }

    getData = () => {
        let completeData = this.props.location?.state.state;
        let headerdata = this.props.location?.state.title;
        let dataCount = this.props.location?.state.dataCount;
        this.setState({headerValue: headerdata})
        this.setState({fullSizeData: completeData})
        this.setState({myDataCount: dataCount})
        if(dataCount?.length < 15){
            this.setState({buttondisableLeft: true})
            this.setState({buttondisableRight: true})
        }
        else{
            this.setState({buttondisableRight: false})
        }
        if(headerdata === "Total Projects(Clientwise)" || headerdata === "Clients' Projects (Rolewise)"){
            let drilltotalProjectsClientWisedata = this.props.location.state.drilldowndata
            this.setState({options: this.state.baroptionclientwiseFullSize})
            this.setState({optionsNodata: this.state.baroptionclientwiseNodataFullSize})
            this.setState({totalProjectsClientWise: drilltotalProjectsClientWisedata})
        }
        this.setState({analytics_workspace_id: localStorage.getItem("analytics_workspace")})
        this.setState({loader: false})
    }

   
      handleRefresh = () => {
        this.setState({loader:true})
        this.analyticsGraphDetails()
    }

    handleBack = () => {
        this.setState({selectedData: false})
    }

    handleBarteam = () => {
        document.addEventListener("keydown", this.handleArrowKeys)
    }

    handleArrowKeys = (event: any) => {
        const numbars = this.state.fullSizeData?.labels.length
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardatateamwise(-15, -15)
        }
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardatateamwise(15, 15)
        }
    }

    renderZoomoutMapDisplay = () => {
        return(
            <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 25, marginRight: -20, borderRadius: "50%"}}>
            {localStorage.getItem('role_id') === "Superadmin" && 
                <Link onClick={() => this.props.history.push("/analytics_graph", {state: this.state.analytics_workspace_id})} underline='none'  style={{color: 'black'}} className="graph_superadmin">
                    <FullscreenExit  style={{cursor: "pointer"}} />
                </Link>
            }
            {localStorage.getItem('role_id') === "Admin" && 
                <Link onClick={() => this.props.history.push("/analytics_admin", {state: this.state.analytics_workspace_id})} underline='none'  style={{color: 'black'}} className="graph_admin">
                    <FullscreenExit  style={{cursor: "pointer"}} />
                </Link>
            }
      
            {localStorage.getItem('role_id') === "Designer/QC/QA" && 
                <Link onClick={() => this.props.history.push("/designer_analytics", {state: this.state.analytics_workspace_id})} underline='none'  style={{color: 'black'}}>
                    <FullscreenExit  style={{cursor: "pointer"}} />
                </Link>
            }
        </Box>
        )
    }

    renderFullSizeGraphDetails = () => {
        return (
            <div className="content"style={webStyle.contents}>
            <Grid container>
                <Grid item lg={6} sm={6}>
                    <h4>Analytics</h4>
                </Grid>
                <Grid item lg={6} sm={6}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: 60}}>
                        {localStorage.getItem('role_id') === "Superadmin" ? <p style={{display: "flex", justifyContent: 'flex-end', marginRight: 10, textDecoration: "underline", marginTop: 24, cursor: "pointer"}} onClick={this.handleRefresh} className="superadmin_refresh"> Refresh</p>:
                        <p  className="superadmin_refresh" style={{display: "flex", justifyContent: 'flex-end', marginRight: 10, textDecoration: "underline", marginTop: 24, cursor: "pointer"}} onClick={this.handleRefresh}><Loop style={{marginTop: -4}}/> Refresh</p>}
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item lg={12} sm={12}>
                <Paper  style={{height: 550}} onClick={this.handleBarteam}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                        {this.state.refreshing ? <h4 style={{paddingLeft: 20,}}>{this.state.refreshedHEaderValue}</h4>:<h4 style={{paddingLeft: 20,}}>{this.state.headerValue}</h4>}
                        </div>
                        <Divider />
                        <div style={{height: 430}}>
                            {this.state.tableContent ? <div style={{height: 430}}><p>Table data will display here</p></div> : 
                            <div style={{height: 430}}>
                                <Grid container>
                                    <Grid item sm={11} style={{marginTop: 30, marginLeft: 40}}>
                                        <div style={{height: 430}}>
                                            {this.state.refreshing ? 
                                                <Bar data={this.state.refreshedFullsizeData}
                                                options={this.state.refreshedFullsizeData?.labels.length > 0  ? this.state.options : this.state.optionsNodata} 
                                                height={'180px'} style={webStyle.barchartstyle}
                                                ref={(reference: any) => (this.chartRefer = reference)}
                                                /> : 
                                                <Bar data={this.state.fullSizeData}
                                                options={this.state.fullSizeData?.labels?.length > 0  ? this.state.options : this.state.optionsNodata} 
                                                height={'180px'} style={webStyle.barchartstyle}
                                                ref={(reference: any) => (this.chartRefer = reference)}
                                                />
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item sm={1}></Grid>
                                </Grid>

                                <p style={{color: "black"}}>{this.state.fullSizeClientWise.labels}</p>
                            </div>
                            }
                            <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,}}>
                                <Button className="leftbutton" style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -10}}  disabled={this.state.buttondisableLeft}>
                                    <KeyboardArrowLeft onClick={() => this.bardatateamwise(-3, -3)} style={{cursor: "pointer"}}  className='left-arrow' />
                                </Button>
                                <Button className="rightbutton" style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -10}}  disabled={this.state.buttondisableRight}>
                                    <KeyboardArrowRight onClick={() => this.bardatateamwise(3, 3)}  style={{cursor: "pointer"}}  className="right-arrow"/>
                                    </Button>
                                {this.renderZoomoutMapDisplay()}
                            </div>
                        </div>

                    </Paper>
                </Grid>
            </Grid>
        </div>
        )
    }

    renderDrilldownFullSize = () => {
        return (
            <div>
                <Grid container>
                            <Grid item lg={6} sm={6}>
                                {this.state?.selectedData && <div style={{display: "flex", flexDirection: "row", margin: 20}}><ArrowLeftRounded  
                               style={webStyle.arrow}  onClick={this.handleBack}/><h4  style={{color: "#6a6f7a", marginLeft: 20}}>{this.state.clientValue}</h4></div>
                                }
                            </Grid>
                </Grid>
                            <Paper style={{minHeight: "400", maxHeight: "auto", flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10, marginLeft: 20,}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                    <h4 style={{paddingLeft: 20,}}>Total Projects (Typewise)</h4>
                                </div>
                                <Divider />
                                <Grid container>
                                    <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                                        <Doughnut 
                                        options={this.state.TLDoughnutChartOptions} 
                                        data = {this.state.analyticsDatatypewise}
                                        width={'100px'} height={'100px'}
                                        />
                                    </Grid>
                                    <Grid item lg={8} sm={6}>
                                        <div style={{maxWidth: 850, minHeight: "230", maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                        display: 'flex', flexDirection: 'row', flexGrow:1}}>
                                            <div style={{display: "flex", flexDirection: "column", width: '250px', justifyContent: "center", 
                                            alignItems: "center", borderRight: '2px solid #e8e8e8', marginTop: 20, marginBottom: 20}}>
                                                <h4 style={{fontSize: 24, marginLeft: -50, marginTop: -10,}}>{this.state.drilldowntotalNoOfProjectsTypewise}</h4>
                                                <div>
                                                    <p style={{marginTop: -25, color: "#9fa0a2"}}>Total projects</p>
                                                </div>
                                            </div> 
                                            <Grid container>
                                                <div style={{marginTop: 40, marginLeft: 40}}>
                                                    <Grid container>
                                                        {this.state.totalProjectsTypewiseanalytics.map((item: any) => {
                                                            if(item.count > 0) {
                                                                return (
                                                                    <Grid item lg={6} sm={12}>
                                                                        <div style={{display: "flex",}}>
                                                                            <Grid container spacing={1} style={{marginRight: 20}}>
                                                                                <Grid item sm={1}>
                                                                                    <Box><p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p></Box>
                                                                                </Grid>
                                                                                <Grid item sm={5}>
                                                                                    <Box style={{ marginTop: 10, marginBottom: 10, marginLeft: 4, }}><p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.type}</p></Box>
                                                                                </Grid>
                                                                                <Grid item sm={5}>
                                                                                    <Box style={{marginLeft: 50,marginTop: 10, marginBottom: 10,}}><p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p></Box>
                                                                                </Grid>
                                                                                <Grid item sm={1}></Grid>                        
                                                                            </Grid>
                                                                        </div> 
                                                                    </Grid>
                                                                )
                                                            }
                                                        })}
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>

                        <br/>
                        <br/>
                        <Paper style={{minHeight: "400", maxHeight: "auto", flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10, marginLeft: 20,}}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                <h4 style={{paddingLeft: 20,}}>Client Projects (Statuswise)</h4>
                            </div>
                            <Divider />
                            <Grid container>
                                <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                                    <Doughnut 
                                    options={this.state.TLDoughnutChartOptions} 
                                    data = {this.state.analyticsDatastatuswise}
                                    width={'100px'} height={'100px'}
                                    />
                                </Grid>
                                <Grid item lg={8} sm={6}>
                                    <Grid container>
                                        <div style={{width: 850, minHeight: "235", maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                        display: 'flex', flexDirection: 'row'}}>
                                            <Grid container>
                                                {this.state.totalProjectsStatuswiseanalytics.map((item: any) => {
                                                    if(item.count > 0) {
                                                    return (
                                                        <Grid item lg={3} md={6} sm={12}>
                                                            <div style={{display: "flex",}}>
                                                                <Grid container>
                                                                    <Grid item sm={1}></Grid>
                                                                    <Grid item sm={1}>
                                                                        <p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${item.color_code}`,marginLeft: -10,}}></p>
                                                                    </Grid>
                                                                    <Grid item sm={5}>
                                                                        <p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{item.status}</p>
                                                                    </Grid>
                                                                    <Grid item sm={5}>
                                                                        <p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{item.count}</p>
                                                                    </Grid>
                                                                    {/* <Grid item sm={1}></Grid> */}
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    )
                                                    }
                                                })}
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
            </div>
        )
    }

// Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <div style={webStyle.container} className="boxcontainer">
                {this.state.loader ? <Grid item sm={12}><Box  style={webStyle.circularprogress}><CircularProgress size={50} /></Box></Grid>
                  :
                  <>{this.state.selectedData ? this.renderDrilldownFullSize() : this.renderFullSizeGraphDetails()}</>
                    // this.renderFullSizeGraphDetails()
                }
            </div>
            </>
        )
    }
}
 // Customizable Area End
 // Customizable Area Start
const webStyle = {

    container: {
        backgroundColor: "#eeeeee",
        height: "100%",
        color: "#5f5f5f",
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right:0,
        padding:"30px 20px 10px 0px",
        marginLeft: '15vw',
        boxSizing: "border-box",
        position: "fixed",
        overflow: "scroll"
    }as any,

    innerGridcontainer: {
        // marginLeft: 0,
    },

    content: {
        margin: 0
    },
    
    sign: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 0,
    },

    
    headnav: {
        marginLeft: 10,
        marginTop: "-55px",
        zIndex: 1300,
    },

    contents: {
        marginLeft: 30
    },

    twocards: {
        display: "flex",
        height: 100,
        width: 350,
        marginRight: 30,
        marginTop: 20,
        padding: 20,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    iconimages: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },

    icons: {
        width: 120,
        height: 120,
    },

    iconname: {
        marginLeft: 28,
        marginRight: 20,
    },

    iconnamecheck: {
        marginLeft: 32,
        marginRight: 20,
    },

    text: {
        backgroundColor: "white", 
        color: "#000000", 
        margin: 10,
        marginRight: 20,
    },

 

      barchartstyle: {
        maxHeight: "450px",
        // maxWidth: "650px"
    },

 
    circularprogress: {
        height: "100%",
        // margin: "100",
        marginTop: -50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        // color: "#6e6e6e"
      } as any,
      arrow: {
        backgroundColor: "white",
        width: 30,
        height: 30,
        marginTop: 12,
        cursor: "pointer"
    }as any,
    

}


function getDataClear() {
    throw new Error('Function not implemented.');
}
// Customizable Area End