import React from 'react';
// Customizable Area Start
import {Paper, Divider, Grid, Box, Link, CircularProgress, Button} from '@material-ui/core';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Doughnut, Bar,} from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,);
import {ZoomOutMap, ArrowLeft, ArrowRight, ArrowBackIos, Loop} from '@material-ui/icons';

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CalendarIcon } from "./assets";
import './Graphs.css';


import AdminAnalyticsController, { Props} from "./AdminAnalyticsController";
// Customizable Area End

export default class AdminAnalytics extends AdminAnalyticsController {
// Customizable Area Start
    constructor(props: Props) {
        super(props);
        this.chartReference = React.createRef()
        this.chartReferenceTeamwise = React.createRef()
        this.chartReferenceOverdue = React.createRef()
    }

    async componentDidMount(): Promise<any> {
        let token = localStorage.getItem("token")
        this.handleDateChange = this.handleDateChange.bind(this)
        if(token) {
            let from = sessionStorage.getItem("FromDate")
            let to = sessionStorage.getItem("ToDate")
            console.clear()
            console.log("from To", from, to)
            if(from && to) {
                this.previousDataExists()
                this.setState({selectedData: false})
                this.analyticsGraphDetailsDate("date",from,to);
            }
            else {
            this.setState({selectedData: false})
            this.analyticsGraphDetails()
            }
    }
    }

    handleBarclient = () => {
        document.addEventListener("keydown", this.handleArrowKeys)
        document.removeEventListener("keydown", this.handleArrowKeysError)
        document.removeEventListener("keydown", this.handleArrowKeysOverdue)
    }

    handleBarteam = () => {
        document.addEventListener("keydown", this.handleArrowKeysError)
        document.removeEventListener("keydown", this.handleArrowKeys)
        document.removeEventListener("keydown", this.handleArrowKeysOverdue)
    }
    handleBaroverdue = () => {
        document.addEventListener("keydown", this.handleArrowKeysOverdue)
        document.removeEventListener("keydown", this.handleArrowKeysError)
        document.removeEventListener("keydown", this.handleArrowKeys)
    }
    handleArrowKeys = (event: any) => {
        const numbars = this.state.bardataclientwise.labels?.length

        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardata(3, 3)
        }
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardata(-3, -3)
        }
    }

    handleArrowKeysError = (event: any) => {
        const numbars = this.state.bardatateamwise.labels.length
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardatateamwise(-3, -3)
        }
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardatateamwise(3, 3)
        }
    }

    handleArrowKeysOverdue = (event: any) => {
        const numbars = this.state.bardataoverdue.labels.length
        if(event.keyCode === 39 && this.state.activeBarIndex < numbars - 1) {
            this.setState({activeBarIndex: this.state.activeBarIndex + 1})
            this.bardataoverdue(3, 3)
        }
        if(event.keyCode === 37 && this.state.activeBarIndex > 0) {
            this.setState({activeBarIndex: this.state.activeBarIndex - 1})
            this.bardataoverdue(-3, -3)
        }
    }

    handleBackAdmin = () => {
        this.setState({selectedData: false})

    }
    
    handleDateChange(date: any) {
        this.setState({ selecteddate: date });
      }


        handleClose = async () => {
            this.setState({isSuccessModal: false})
            this.setState({tokenError: false})
            await this.props.history?.push("/EmailAccountLoginsBlock")
        }

        handleRefresh = () => {
            this.setState({loader: true})
            this.setState({from: null})
            this.setState({to: null})
            this.handleDatePicker(null)
            sessionStorage.removeItem("FromDate")
            sessionStorage.removeItem("ToDate")
            this.analyticsGraphDetails()
        }

        renderDrillDown = () => {
            return(
            <div> 
            <Paper style={webStyle.admindrilldownpaperone}>
                <div style={webStyle.admindrilldowndivone}>
                    <h4 style={webStyle.admindrilldownheadone}>Total Projects (Typewise)</h4>
                </div>
                <Divider />
                <Grid container>
                    <Grid item lg={4} sm={6} style={webStyle.admindrilldowngridone}>
                        <Doughnut 
                        options={this.state.TLDoughnutChartOptions} 
                        data = {this.state.analyticsDatatypewise}
                        width={'100px'} height={'100px'}
                        />
                    </Grid>
                    <Grid item lg={8} sm={6}>
                        <div style={webStyle.admindrilldowntypedivone}>
                            <div style={webStyle.admindrilldowntypedivtwo}>
                                <h4 style={webStyle.admindrilldowntypehead}>{this.state.drilldowntotalNoOfProjectsTypewise}</h4>
                                <div>
                                    <p style={webStyle.admindrilldowntypepone}>Total projects</p>
                                </div>
                            </div> 
                            <Grid container>
                                <div style={webStyle.admindrilldowntypegridtwo}>
                                    <Grid container>
                                        {this.state.totalProjectsTypewiseanalytics?.map((drilltypeitem: any) => {
                                            if(drilltypeitem.count > 0) {
                                                return (
                                                    <Grid item lg={6} sm={12}>
                                                        <div style={webStyle.adminmapdivflex}>
                                                            <Grid container spacing={1} style={webStyle.adminmapdivmargin}>
                                                                <Grid item sm={1}>
                                                                    <Box><p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${drilltypeitem.color_code}`,marginLeft: -10,}}></p></Box>
                                                                </Grid>
                                                                <Grid item sm={5}>
                                                                    <Box style={webStyle.admindrilltypeboxone}><p style={webStyle.admindrilltypepone}>{drilltypeitem.type}</p></Box>
                                                                </Grid>
                                                                <Grid item sm={5}>
                                                                    <Box style={webStyle.admindrilltypeboxtwo}><p style={webStyle.admindrilltypeptwo}>{drilltypeitem.count}</p></Box>
                                                                </Grid>
                                                                <Grid item sm={1}></Grid>                        
                                                            </Grid>
                                                        </div> 
                                                    </Grid>
                                                )
                                            }
                                        })}
                                    </Grid>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <br/>
            <br/>
            <Paper style={{minHeight: 400, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", maxHeight: "auto", marginBottom: 10,}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                    <h4 style={{paddingLeft: 20,}}>Total Projects (Statuswise)</h4>
                </div>
                <Divider />
                <Grid container>
                    <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10}}>
                        <Doughnut 
                        options={this.state.TLDoughnutChartOptions} 
                        data = {this.state.analyticsDatastatuswise}
                        width={'100px'} height={'100px'}
                        />
                    </Grid>
                    <Grid item lg={8} sm={6}>
                        <Grid container>
                            <div style={{maxWidth: 850, minHeight: 255, maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                            display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                                <Grid container>
                                    {this.state.totalProjectsStatuswiseanalytics?.map((idrillstatustem: any) => {
                                        if(idrillstatustem.count > 0) {
                                        return (
                                            <Grid item lg={3} md={6} sm={12}>
                                                <div style={{display: "flex",}}>
                                                    <Grid container>
                                                        <Grid item sm={1}></Grid>
                                                        <Grid item sm={1}>
                                                            <p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${idrillstatustem.color_code}`,marginLeft: -10,}}></p>
                                                        </Grid>
                                                        <Grid item sm={5}>
                                                            <p style={webStyle.admindrillstatpone}>{idrillstatustem.status}</p>
                                                        </Grid>
                                                        <Grid item sm={5}>
                                                            <p style={webStyle.admindrillstatptwo}>{idrillstatustem.count}</p>
                                                        </Grid>
                                                        <Grid item sm={1}></Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        )
                                        }
                                    })}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
            )
        }

        renderAdminData = () => {
            if(!this.state.chartError){
            return (  
                <> 
                {!this.state.tokenError &&            
            <div>
                                    <Paper style={{minHeight: 400, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", maxHeight: "auto", marginBottom: 10,}}>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                            <h4 style={{paddingLeft: 20,}}>Total Projects (Typewise)</h4>
                                        </div>
                                        <Divider />
                                            <Grid container>
                                                <Grid item lg={4} sm={6} style={{height: 300, marginLeft: -10, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", padding: "1rem", maxHeight: "500px", marginBottom: 10,}}>
                                                    <Doughnut 
                                                    options={this.state.optionspie} 
                                                    data = {this.state.analyticsData}
                                                    width={'100px'} height={'100px'}
                                                    />
                                                </Grid>
                                                <Grid item lg={8} sm={6}>
                                                    <div style={{maxWidth: 850, minHeight: 230, maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
                                                    display: 'flex', flexDirection: 'row', flexGrow: 1, }}>
                                                        <div style={{display: "flex", flexDirection: "column", width: '250px', justifyContent: "center", 
                                                        alignItems: "center", borderRight: '2px solid #e8e8e8', marginTop: 20, marginBottom: 20}}>
                                                            <h4 style={{fontSize: 24, marginLeft: -50, marginTop: -10,}}>{this.state.totalNoOfProjectsTypewise}</h4>
                                                            <div>
                                                                <p style={{marginTop: -25, color: "#9fa0a2"}}>Total projects</p>
                                                                <p style={{marginTop: -10,color: "#9fa0a2", textAlign: "start"}}>(All Clients)</p>
                                                            </div>
                                                        </div> 
                                                        <Grid container>
                                                            <div style={{marginTop: 40, marginLeft: 40}}>
                                                                <Grid container>
                                                                    {this.state.totalProjectsTypewise?.map((itemadmin: any) => {
                                                                        if(itemadmin.count > 0) {
                                                                            return (
                                                                                <Grid item lg={6} sm={12}>
                                                                                    <div style={{display: "flex",}}>
                                                                                        <Grid container spacing={1} style={{marginRight: 20}}>
                                                                                            <Grid item sm={1}>
                                                                                                <Box><p style={{display: "inline-block", marginTop: 14, marginBottom: 10,  height: "10px", width: "10px", borderRadius: "50%", backgroundColor:`${itemadmin.color_code}`,marginLeft: -10,}}></p></Box>
                                                                                            </Grid>
                                                                                            <Grid item sm={5}>
                                                                                                <Box style={{ marginTop: 10, marginBottom: 10, marginLeft: 4, }}><p style={{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}}>{itemadmin.type}</p></Box>
                                                                                            </Grid>
                                                                                            <Grid item sm={5}>
                                                                                                <Box style={{marginLeft: 30,marginTop: 10, marginBottom: 10,}}><p style={{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}}>{itemadmin.count}</p></Box>
                                                                                            </Grid>
                                                                                            <Grid item sm={1}></Grid>                        
                                                                                        </Grid>
                                                                                    </div> 
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                    </Paper>
                                    <br/>
                                    <br/>
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} sm={12}>
                                            <Paper  style={{height: 430,  flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10,}} onClick={this.handleBarclient}>
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                                    <h4 style={{paddingLeft: 20,}}>Total Projects(Clientwise)</h4>
                                                </div>
                                                <Divider />
                                                <div style={{height: 340}}>
                                                <Grid container>
                                                    <Grid item sm={10} style={{marginTop: 30, marginLeft: 20, flexGrow: 1,}}>
                                                        <div style={{width: 545}}>
                                                        <Bar data={this.state.bardataclientwise} 
                                                        options={this.state.bardataclientwise.labels?.length > 0 ? this.state.baroptionclientwiseAdmin : this.state.baroptionclientwiseNodataAdmin}
                                                        height={'180px'} style={webStyle.barchartstyle}
                                                        ref={(reference: any) => (this.chartReference = reference)}
                                                        />
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={1}></Grid>
                                                </Grid>
                                                </div>
                                                <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,height: 10}}>
                                                    <Button className='leftbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableLeftClient}>
                                                        <ArrowLeft onClick={() => this.bardata(-3, -3)} style={{cursor: "pointer"}} />
                                                    </Button>
                                                    <Button className='rightbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableRightClient}>
                                                        <ArrowRight onClick={() => this.bardata(3, 3)} style={{cursor: "pointer"}} />
                                                    </Button>
                                                    <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                                                        <Link onClick={() => {this.props.history.push("/detailedgraph", {state: this.state.bardataclientwise, title: "Total Projects(Clientwise)", drilldowndata: this.state.totalProjectsClientWise, dataCount: this.state.myDataCount})}} underline='none' style={{color: 'black'}}>
                                                            <ZoomOutMap style={{cursor: "pointer"}} />
                                                        </Link>
                                                    </Box>
                                                </div>
                                            </Paper>
                                        </Grid>
                                        <Grid item lg={6} sm={12}>
                                            <Paper style={{height: 430, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10,}} onClick={this.handleBarteam}>
                                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}}>
                                                    <h4 style={{paddingLeft: 20,}}>No of Errors(Teamwise)</h4>
                                                </div>
                                                <Divider />
                                                <div style={{height: 340}}>
                                                <Grid container>
                                                    <Grid item sm={10} style={{marginTop: 30, marginLeft: 20}}>
                                                        <div style={{width: 545}}>
                                                        <Bar data={this.state.bardatateamwise} 
                                                        options={this.state.bardatateamwise.labels?.length > 0 ? this.state.baroptionteamwise: this.state.baroptionteamwiseNodataAdmin} 
                                                        height={'180px'} style={webStyle.barchartstyle}
                                                        ref={(reference: any) => (this.chartReferenceTeamwise = reference)}
                                                        />
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={1}></Grid>
                                                </Grid>
                                                </div>
                                                <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,height: 10}}>
                                                    <Button className='leftbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableLeftError}>
                                                        <ArrowLeft onClick={() => this.bardatateamwise(-3, -3)} style={{cursor: "pointer"}} />
                                                    </Button>
                                                    <Button className='rightbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableRightError}>
                                                        <ArrowRight onClick={() => this.bardatateamwise(3, 3)} style={{cursor: "pointer"}} />
                                                    </Button>
                                                    <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                                                        <Link onClick={() => {this.props.history?.push("/detailedgraph", {state: this.state.bardatateamwise, title: "No of Errors(Teamwise)", dataCount: this.state.myDatacountBarteamwise})}} underline='none' style={{color: 'black'}}>
                                                            <ZoomOutMap style={{cursor: "pointer"}} />
                                                        </Link>
                                                    </Box>
                                                </div>
                                            </Paper>
                                        </Grid>
                                        <Grid item lg={6} sm={12}>
                                            <Paper style={webStyle.adminoverduepaperone} onClick={this.handleBaroverdue}>
                                                <div style={webStyle.adminoverduedivone}>
                                                    <h4 style={webStyle.adminoverduehead}>Overdue Files(Clientwise)</h4>
                                                </div>
                                                <Divider />
                                                <div style={webStyle.adminoverduebarheight}>
                                                <Grid container>
                                                    <Grid item sm={10} style={webStyle.adminoverduebargrid}>
                                                        <Bar data={this.state.bardataoverdue} 
                                                        options={this.state.bardataoverdue.labels?.length > 0 ?this.state.baroptionoverdueAdmin : this.state.baroptionoverdueNodataAdmin} 
                                                        height={'180px'} style={webStyle.barchartstyle}
                                                        ref={(reference: any) => (this.chartReferenceOverdue = reference)}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={1}>
                                                        <div>
                                                            <div style={webStyle.adminoverduenamediv}>
                                                            <p style={webStyle.adminoverduenamebackground}></p>
                                                            <p style={webStyle.adminoverduenamepone}>overdue files for QC</p>
                                                            </div>
                                                            <div style={webStyle.adminoverduenamedivtwo}>
                                                            <p style={webStyle.adminoverduenamebackgroundtwo}></p>
                                                            <p style={webStyle.adminoverduenameptwo}>overdue files for production</p>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                </div>
                                                <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10,height: 10}}>
                                                    <Button className='leftbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableLeftOverdue}>
                                                        <ArrowLeft onClick={() => this.bardataoverdue(-5, -5)} style={{cursor: "pointer"}} />
                                                    </Button>
                                                    <Button className='rightbutton' style={{backgroundColor: "white", height: 25, minWidth: 25, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 5, marginTop: -30}} disabled={this.state.buttondisableRightOverdue}>
                                                        <ArrowRight  onClick={() => this.bardataoverdue(5, 5)} style={{cursor: "pointer"}} />
                                                    </Button>
                                                    <Box style={{backgroundColor: "#e8e8e8", height: 30, width: 30, border: '1px solid #e8e8e8', display: 'flex', justifyContent: "center", alignItems: "center", marginLeft: 10, marginTop: 1, marginRight: -20, borderRadius: "50%"}}>
                                                        <Link onClick={() => {this.props.history?.push("/detailedgraph", {state: this.state.bardataoverdue, title: "Overdue Files(Clientwise)", dataCount: this.state.overdueDataArray})}}underline='none' style={{color: 'black'}}>
                                                            <ZoomOutMap style={{cursor: "pointer"}} />
                                                        </Link>
                                                    </Box>
                                                </div>
                                            </Paper>`
                                        </Grid>
                                    </Grid>
                                </div>
                                }
                                </> 
            )
            }
        }

        renderHeaderValues = () => {
            return (
                <>
                    {this.state.selectedData ?
                                            <div style={webStyle.divnav}>  
                         <Box style={webStyle.sign} className="headnavbar">
                            <Button variant="contained" style={webStyle.backbutton} onClick={this.handleBackAdmin}>
                                <ArrowBackIos fontSize="small" style={webStyle.arrowback} />
                                <span style={webStyle.back} className="buttontext">Back</span>
                            </Button>
                            <h3 style={webStyle.headnav}>
                                {this.state.clientValue}
                            </h3>
                            
                         </Box>
                        </div>
                    :
                        <div style={webStyle.divnav}>
                            <h3 style={webStyle.headnavAna}  className="headnavbaranalytics">
                                Analytics
                            </h3>
                        </div>
                    }
                </>
            )
        }

        renderDateandRefreshButton = () => {
            return(
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: 60}}>
                <DateRangePicker
                value={this.state.dateRange}
                className={"calender"}
                onChange = {(a)=>{this.handleDatePicker(a)}}
                calendarIcon={
                <img src={CalendarIcon} style={{height: '19', paddingBottom: '1'}} />
                }
                format={"dd/MM/y"}
                dayPlaceholder={"DD"} monthPlaceholder={"MM"} yearPlaceholder={"YYYY"}
                />
                <p style={{display: "flex", justifyContent: 'flex-end', marginRight: 10, textDecoration: "underline", marginTop : 24, cursor: "pointer"}} onClick={this.handleRefresh}data-test-id = 'handleAdminRefresh' className='handleAdminRefresh'><Loop style={{marginTop: -4}}/> Refresh</p>
            </div>
            )
        }

// Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <div style={webStyle.container} className="boxcontainer" data-test-id="mock-chart-component">
                {this.renderHeaderValues()}
                    {this.state.loader ? <Grid item sm={12}><Box style={webStyle.circularprogressadmin}><CircularProgress size={50} /></Box></Grid>
                        :
                        <div>
                        {this.state.chartError ?  <div style={webStyle.errormessageContent}><p>{this.state.errorStatement}</p></div> : 
                        <div className="content"style={webStyle.contentsadmin}>
                            <Grid container>
                                <Grid item lg={6} sm={6}>
                                   {/* <h4>Analytics</h4>  */}
                                </Grid>
                                <Grid item lg={6} sm={6}>
                                    {!this.state.selectedData && this.renderDateandRefreshButton()
                                    }
                                </Grid>
                            </Grid>
                            
                            {this.state.selectedData ? this.renderDrillDown() : this.renderAdminData()}
                        </div>
                        }
                        </div>
                    }
                </div>
            </>
        )
    }
}
 // Customizable Area End
 // Customizable Area Start
const webStyle = {

    container: {
        backgroundColor: "#eeeeee",
        height: "100%",
        color: "#5f5f5f",
        fontFamily: "sans-serif",
        width: "85vw",
        top: "50px",
        right:0,
        padding:"30px 20px 10px 0px",
        marginLeft: '15vw',
        boxSizing: "border-box",
        // position: "fixed",
        overflow: "scroll"
    }as any,

    innerGridcontainer: {
        // marginLeft: 0,
    },

    content: {
        margin: 0
    },
    
    sign: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 1300,
        marginTop: -80,
        marginLeft: -220
    },

    
    headnav: {
        marginLeft: 10,
        marginTop: "22px",
        color: "#6a6f7a",
        fontWeight: "normal",
        fontFamily: "sans-serif",
        zIndex: 1300,
    }as any,

    contentsadmin: {
        marginLeft: 30
    },

    circularprogressadmin: {
        display: "flex",
        height: "100%",
        marginTop: -50,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
    } as any,

    signback: {
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: "20px",
        fontFamily: "sans-serif",
        zIndex: 1300,
    },

    backbutton: {
        paddingLeft: "1%",
        paddingRight: "1%",
        backgroundColor: "#efefef",
        color: "#9a9ea1",
        width: "100px",
        height: '32px',
        marginTop: "15",
        marginLeft: "235"

    },

    arrowback: {
        color: 'gray',
        fontSize: "14px",
    },

    back: {
        marginLeft: 8,
        fontSize: "14px",
        textTransform: "none",
    }as any,

    headnavTop: {
        marginLeft: 10,
        marginTop: "-55px",
        zIndex: 1300,
    },

    headnavAna: {
        marginLeft: 35,
        marginTop: -55,
        zIndex: 1300
    },

    barchartstyle: {
        maxHeight: "300px",
        maxWidth: "650px"
    },

    errormessageContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        fontSize: 16,
        fontWeight: 600,
        color: "#6a6f7a",
        fontFamily: "sans-serif",
        margin: 300
    }as any,

    
    headnavAnalytics: {
        marginLeft: 250,
        marginTop: "22px",
        color: "#6a6f7a",
        fontWeight: "bold",
        fontFamily: "sans-serif",
    }as any,

    divnav: {
        display: "flex",
        justifyContent: "flex-start",
    },
    admindrilldownpaperone: {minHeight: 400, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", maxHeight: "auto", marginBottom: 10,}as any,
    admindrilldowndivone:{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}as any,
    admindrilldownheadone:{paddingLeft: 20,},
    admindrilldowngridone:{height: 300, marginLeft: -10},
    admindrilldowntypedivone:{maxWidth: 850, minHeight: 230,  maxHeight: "auto", marginTop: 40, marginLeft: -40, border: '2px solid #e8e8e8', backgroundColor: "#f9f9f9",
    display: 'flex', flexDirection: 'row', flexGrow: 1}as any,
    admindrilldowntypedivtwo:{display: "flex", flexDirection: "column", width: '250px', justifyContent: "center", 
    alignItems: "center", borderRight: '2px solid #e8e8e8', marginTop: 20, marginBottom: 20}as any,
    admindrilldowntypehead:{fontSize: 24, marginLeft: -50, marginTop: -10,},
    admindrilldowntypepone:{marginTop: -25, color: "#9fa0a2"},
    admindrilldowntypegridtwo:{marginTop: 40, marginLeft: 40},
    adminmapdivflex:{display: "flex",},
    adminmapdivmargin:{marginRight: 20},
    admindrilltypeboxone:{ marginTop: 10, marginBottom: 10, marginLeft: 4, },
    admindrilltypepone:{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4, textAlign: "start"}as any,
    admindrilltypeboxtwo:{marginLeft: 50,marginTop: 10, marginBottom: 10,},
    admindrilltypeptwo:{marginLeft: 20, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign: "start"}as any,
    admindrillstatpone:{color: "#9fa0a2", marginTop: 10, marginBottom: 10, marginLeft: 4,textAlign: "start"}as any,
    admindrillstatptwo:{marginLeft: 15, color: "#000000", fontWeight: 600, marginTop: 10, marginBottom: 10,textAlign:"start"}as any,
    adminoverduepaperone:{height: 430, flexGrow: 1, display: "flex", flexDirection: "column", textAlign: "center", marginBottom: 10,}as any,
    adminoverduedivone:{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60}as any,
    adminoverduehead:{paddingLeft: 20,},
    adminoverduebarheight:{height: 340},
    adminoverduebargrid:{marginTop: 30, marginLeft: 20},
    adminoverduenamediv:{display: "flex", flexDirection: "row", rotate: "270deg", width: 126, height: 200, marginLeft: 53, marginRight: 20, marginTop: -16}as any,
    adminoverduenamebackground:{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#FFBF00",margin: '5px 3px 7px 14px',}as any,
    adminoverduenamepone:{height: 50, marginLeft: 10, marginTop: 0, fontSize: 13},
    adminoverduenamedivtwo:{display: "flex", flexDirection: "row", rotate: "270deg", width: 126, height: 50, marginLeft: -12}as any,
    adminoverduenamebackgroundtwo:{margin: "-6px 6px 7px -13px", height: "10px", width: "10px", borderRadius: "50%", backgroundColor: "#1F51FF",}as any,
    adminoverduenameptwo:{marginLeft: 3, marginTop: -11, height: 50, fontSize: 13},

}

// Customizable Area End