  // Customizable Area Start
import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Select,
  MenuItem,
  FormControl,
  Button,

  TextField,
  Avatar,

} from "@material-ui/core";
import { PureComponent } from "react";
import "../../KanbanBoard/src/ProjectCard.css";
import moment from 'moment';
import { CloseIcon, DeleteSvg, DownloadSvg, FileSvg, GreenCheckSvg, UploadSvg } from "../../KanbanBoard/src/assets";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import TeamPlayerController from "../../../components/src/TeamPlayerController";
import Carousel from "react-multi-carousel";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineMessage
} from "react-icons/ai";
import { ImAttachment } from "react-icons/im";

const configJSONBase = require("../../../framework/src/config");

let indexCounter = 0
import { activity, checkListData, commentProps, customMentionType, projectDataType, projectCardData, projectCarouselData, status } from "./types";

export class Centered extends Component {
    render(): React.ReactNode {
      const { children } = this.props;
      return (
        <>
          <Box display={"flex"} justifyContent={"center"}>
            {children}
          </Box>
        </>
      );
    }
  }
  
  
  export class IndividualAttachment extends Component<{ isUploading: boolean, data: projectDataType["additional_attr"]["attachments"][0], deleteAttachment: (id:string|number)=>boolean }, {showDeleteIcon: boolean, removeLink: boolean}>{
    constructor(props: { isUploading: boolean, data: projectDataType["additional_attr"]["attachments"][0], deleteAttachment: (id:string|number)=>boolean }) {
      super(props);
      this.state = {
        showDeleteIcon: false,
        removeLink: false,
      }
    }
    attachmentName = (data: projectDataType["additional_attr"]["attachments"][0]) => {
      const path = data?.attributes?.attachment?.url.split("/")
      
      if (path && path.length>1) {
        return path[path.length - 1]
      }
      return data?.attributes?.attachment?.name
    }
  
    canBeDisplayed = (url: string | undefined) => {
      if(url){
        const spliUrl = url.split("/")
        const extensionSplit = spliUrl[spliUrl.length - 1].split(".")
        const extension = extensionSplit[extensionSplit.length - 1]
  
        const allowedExtensions = ['png', 'jpeg', 'jpg']
  
        const result = allowedExtensions.find((str) => str === extension.toLocaleLowerCase());
  
        return result
      }
    }
  
    render(): React.ReactNode {
      const { data, deleteAttachment } = this.props
      return (
        <Box style={{width: '100%', maxHeight:'73%'}}
        >
          <a className="linkOnClick" href={configJSONBase.baseURL + data?.attributes?.attachment?.url || "NA"} target="_blank" download style={{ width: '100%', top: -8 }}>
          
            <Box
              style={{ background: 'white', height: '100%', width: '100%', borderRadius: 4, position: 'relative' }}
              className={"attachmentOuterBox"}
              onMouseEnter={()=>{
                this.setState({
                  showDeleteIcon: true
                })
              }}
              onMouseLeave={()=>{
                this.setState({
                  showDeleteIcon: false
                })
              }}
            >
              { this.state.showDeleteIcon && <Box onMouseEnter={
                ()=>{
                  this.setState({
                    removeLink: true
                  })
                }
              } onMouseLeave={
                ()=>{
                  this.setState({
                    removeLink: false
                  })
                }
              } onClick={(e)=> { e.preventDefault(); e.stopPropagation(); deleteAttachment(data?.id)}} ><img src={DeleteSvg} className={"deleteAtt"}  /> </Box>}
              {/* {isUploading  "Uploading"} */}
              <Box style={{ width: '100%' }}>
                <Box style={{ height: '100%', width: '100%', borderRadius: 4, textAlign: 'center', position: 'relative' }}>
  
                  {this.canBeDisplayed(data?.attributes?.attachment?.url)?
                  <img src={ configJSONBase.baseURL + data?.attributes?.attachment?.url} style={{
                    height: '100%', width: '100%',
                    borderRadius: 4
                  }}  alt="" />
                  :
                  <>
                    <img src={FileSvg} className="uploadFile" height={80} style={{maxHeight: '100%'}}/>
                    <p className="uploadText" style={{
                      position: "absolute",
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: 130,
                      color: 'grey'
                    }} ><b>{this.attachmentName(data)}</b></p>
                  </>
                  }
                </Box>
              </Box>
            </Box>
          </a>
          
          <Box>
            <p style={{display: 'flex', fontSize: '1rem', alignItems: 'center'}} >
            <Avatar
              style={{ height: "20px", width: "20px" }}
              src={ data?.attributes?.added_by?.attributes?.image || undefined}
            />
            <span style={{fontSize: 13, marginLeft: 5,}} ><b>{data?.attributes?.added_by?.attributes?.first_name + " " + data?.attributes?.added_by?.attributes?.last_name}</b></span>
           </p>
           <Box style={{fontSize: 10}} >Added on {moment(data?.attributes?.updated_at).format("D MMM YY on hh:mm a")}</Box>
          </Box>
        </Box>
      )
  
    }
  }
  

  
export class AttachmentsForComments extends Component<{data: {name:string}, deleteCommentAttachment: (index: number) => void, index: number, imageLink: string }, {showDeleteIcon: boolean, removeLink: boolean, imageLink: string}>{
    constructor(props: {data: {name:string}, deleteCommentAttachment: (index: number) => void, index: number, imageLink: string }) {
      super(props);
      this.state = {
        showDeleteIcon: false,
        removeLink: false,
        imageLink: ""
      }
    }
  
    componentDidMount(): void {
      this.setState({
        imageLink: this.props.imageLink
      })
    }
  
    componentDidUpdate(prevProps: Readonly<{data: {name:string}, deleteCommentAttachment: (index: number) => void, index: number, imageLink: string }>): void {
      if(prevProps.data.name != this.props.data.name){
        this.setState({
          imageLink: this.props.imageLink
        })
      }
    }
  
    canBeDisplayed = (data:{name:string}) => {
      
      const extensionSplit = data?.name.split(".")
      const extension = extensionSplit[extensionSplit.length - 1]
  
      const allowedExtensions = ['png', 'jpeg', 'jpg']
  
      const result = allowedExtensions.find((str) => str === extension.toLocaleLowerCase());
  
      return result
    }
  
    render(): React.ReactNode {
      const { data, deleteCommentAttachment,index } = this.props
      return (
        <Box style={{width: '100%', maxHeight:'78%'}}
        >
            <Box
              style={{ background: 'white', height: '100%', width: '100%', borderRadius: 4, position: 'relative' }}
              className={"attachmentOuterBox"}
              onMouseEnter={()=>{
                this.setState({
                  showDeleteIcon: true
                })
              }}
              onMouseLeave={()=>{
                this.setState({
                  showDeleteIcon: false
                })
              }}
            >
              { this.state.showDeleteIcon && <Box onMouseEnter={
                ()=>{
                  this.setState({
                    removeLink: true
                  })
                }
              } onMouseLeave={
                ()=>{
                  this.setState({
                    removeLink: false
                  })
                }
              } onClick={(e)=> { e.preventDefault(); e.stopPropagation(); deleteCommentAttachment(index)}} ><img src={DeleteSvg} className={"deleteAtt"}  /> </Box>}
              {/* {isUploading  "Uploading"} */}
              <Box style={{ width: '100%' }}>
                <Box style={{ height: '100%', width: '100%', borderRadius: 4, textAlign: 'center', position: 'relative' }}>
  
                  {this.canBeDisplayed(data)?
                  <img src={ this.state.imageLink} style={{
                    height: '100%', width: '100%',
                    borderRadius: 4
                  }}  alt="" />
                  :
                  <>
                    <img src={FileSvg} className="uploadFile" height={80} style={{maxHeight: '100%'}}/>
                    <p className="uploadText" style={{
                      position: "absolute",
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: 130,
                      color: 'grey'
                    }} ><b>{this.props.data.name}</b></p>
                  </>
                  }
                </Box>
              </Box>
            </Box>
          
          <Box>
            <p style={{display: 'block', fontSize: '1rem', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} >
              {this.props.data.name}
           </p>
          </Box>
        </Box>
      )
  
    }
  }
  
  interface AttachmentsProps {
    isUploading: boolean;
    handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
    Attachments: projectDataType["additional_attr"]["attachments"]
    deleteAttachment: (id: string | number) => boolean
  }
  
  export class Attachments extends Component<AttachmentsProps, { file: [] }>{
    constructor(props: AttachmentsProps) {
      super(props);
      this.state = {
        file: [],
      }
    }
  
    UploadFile = () => {
      return (<>
        <Box style={this.props.Attachments.length % 4  == 0 ? {width: '100%', height: '100%',position: 'relative'}: {width: '100%', height:'73%',position: 'relative'}}>
          <Box style={{ border: '1px dashed rgba(95, 83, 89, 0.16)', height: '100%', width: '100%', borderRadius: 4, textAlign: 'center', background: 'white' }}>
            <img src={UploadSvg} className="uploadFile" />
            <p className="uploadText" style={{
                    position: 'relative',
                    float: 'left',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'grey'
                  }} >ADD AN ATTACHMENT</p>
          </Box>
          <input type="file" className="upload-input" id="uploadfileinput" onChange={this.props.handleUpload} />
        </Box>
      </>)
    }
  
    render(): React.ReactNode {
      return (
        <Box>
          <p style={{fontSize: 20}} >Attachments</p>
          <Box
            className="attachmentWrapper"
          >
            {this.props.Attachments.map((e: projectDataType, index: number) => {
              return <IndividualAttachment
                isUploading={this.props.isUploading && index == this.props.Attachments.length - 1}
                data={e}
                deleteAttachment={this.props.deleteAttachment}
              />
            })}
            <this.UploadFile />
          </Box>
        </Box>
      )
    }
  }
  
  export class Comment extends PureComponent<{ comment:commentProps['comment'], editComment: (newComment: string, id: string | number) => boolean, deleteComment: (id: string | number) => boolean,  CustomMention: customMentionType['customMention'], handleChange:(newPlainTextValue: string, newValue: string, mentions: {id: string;}[]) => void }, { edit: boolean, newComment: string, isHide: boolean }> {
    commentRef: React.RefObject<HTMLDivElement>
    constructor(props: { comment:commentProps['comment'], editComment: (newComment: string, id: string | number) => boolean, deleteComment: (id: string | number) => boolean,  CustomMention: customMentionType['customMention'], handleChange:(newPlainTextValue: string, newValue: string, mentions: {id: string;}[]) => void }) {
      super(props);
      this.commentRef = React.createRef();
      this.state = {
        edit: false,
        newComment: this.props.comment.attributes.comments|| "Comment Unavailable",
        isHide: false,
      }
    }
  
    forceDownload = (href : string, fileName: string) => {
      fetch(href)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
    
    listener = (event:MouseEvent|TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!this.commentRef.current || this.commentRef.current.contains(event.target as Node)) {
        return;
      }
      this.setState({ edit: false, newComment: this.props.comment.attributes.comments })
      
    };
    async componentDidMount() {
      document.addEventListener("mousedown", this.listener);
      document.addEventListener("touchstart", this.listener);
      this.setState({
        newComment: this.props.comment.attributes.comments
      })
    }
  
    async componentWillUnmount() {
      document.removeEventListener("mousedown", this.listener);
      document.removeEventListener("touchstart", this.listener);
    }
    render(): React.ReactNode {
      if (this.state.isHide) return <></>
      const { comment, editComment, deleteComment, CustomMention, handleChange } = this.props;
      const date = moment(comment.attributes.updated_at).fromNow()
      return (
        <>
          <Box margin={"20 0"}>
            <Box display={"flex"} alignItems={"center"}>
              <Box>
                <Avatar
                  style={{ height: "20px", width: "20px" }}
                  src={ configJSONBase.baseURL + comment?.attributes?.added_by?.attributes?.image}
                />
              </Box>
              <Box fontWeight={"bold"} fontSize={"0.9rem"} margin={"0 10"}>
                {comment.attributes.added_by.attributes.first_name + " " + comment.attributes.added_by.attributes.last_name}
              </Box>
              <Box color={"#C1C2C8"} fontSize={"0.8rem"}>
                {date}
              </Box>
            </Box>
            <Box marginLeft={"5%"} marginTop={"0.9vh"} fontSize={"1rem"}>
              {this.state.edit && comment.attributes.added_by.id == localStorage.getItem("id") ?
                <>
                <></>
                <div ref={this.commentRef} id={"EditCommentMention"} style={{position:'relative'}} >
                    <CustomMention 
                    onChange={(e,newValue, newPlainTextValue, mentions) => {this.setState({ newComment: e.target.value }); handleChange(newPlainTextValue, newValue, mentions)}}
                    value={this.state.newComment} 
                    placeholder={"Add a Comment"}
                    autoFocus
                  />
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                    >
                      <Box >
                        <Button
                          style={{
                            fontSize: "1.5vhv",
                            fontWeight: "bolder",
                            textTransform: "none",
                            position: 'absolute',
                            top: '0',
                            marginLeft: '10px'
                          }}
                          variant={"contained"}
                          className="sendBtn"
                          disabled={!this.state.newComment.trim().length}
                          onClick={(e) => {e.preventDefault(); e.stopPropagation() ; this.setState({ edit: false }); editComment(this.state.newComment, comment.id); comment.attributes.comments = this.state.newComment }}
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                </div>
                </> :
                <Box id="commentMention">
                    <CustomMention 
                      onChange={() => {}}
                      value={comment.attributes.comments} 
                      placeholder={""}
                      readOnly
                    />
                  { comment.attributes?.files?.length > 0 ? 
                  comment.attributes?.files?.map((el)=>{
                    return <Box>
                    <Box style={{marginTop: 10, background: 'white', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <Box>
                        <Typography style={{padding: 10, fontSize: 10, color: 'grey', display: 'flex', alignItems: 'center' }}>
                          <img src={FileSvg} height={20} />
                          {el.split("/")[el.split("/").length - 1]}
                        </Typography>
                      </Box>
                      <Box>
                          <img src={DownloadSvg} alt="" height={20} style={{padding: 10, cursor: 'pointer'}} onClick={()=>this.forceDownload(configJSONBase.baseURL + comment.attributes?.files[0], comment.attributes?.files[0].split("/")[comment.attributes?.files[0].split("/").length - 1])} />
                      </Box>
                    </Box>
                  </Box>
                  })
                :
                null}
                </Box>
  
              }
            </Box>
            <Box>
              {!this.state.edit && comment.attributes.added_by.id == localStorage.getItem("id") ?
                <Box className="taskBoardCommentFont" >
                  <span onClick={() => this.setState({ edit: true })} style={{ cursor: 'pointer' }} >
                    Edit
                  </span>
                  <span style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                    onClick={() => {
                       deleteComment(comment.id);
                    }}
                  >
                    Delete
                  </span>
                </Box> : ""}
            </Box>
          </Box>
        </>
      );
    }
  }
  
  interface LabelWithInputsProps {
    label: string;
    isDisable: boolean;
    value: string;
    data: projectDataType['fields'][0];
    updateProjectDetails: (value: string, item: {id: string | number;}) => void
    debouncerHandler: (value: string, data: {id: number,field_type:string}, what: string) => void;
  }
  
  export class LabelWithInput extends PureComponent<LabelWithInputsProps, { input: string }> {
    ref: React.RefObject<unknown>
    constructor(props: LabelWithInputsProps) {
      super(props);
      this.state = {
        input: this.props.value,
      }
      this.ref = React.createRef()
    }
    componentDidMount(): void {
      if(this.props.data.field_type == "Calendar" && this.props.value != "NA" && this.props.value != "")
        this.setState({
          input: moment(this.props.value).format("DD-MM-YYYY")
        })
      else if(this.props.data.field_type == "Real Time"  && this.props.value != "NA"){
        this.setState({
          input: moment(this.props.value).format("DD-MM-YYYY HH:mm")
        })
      }
    }
  
    componentDidUpdate(prevProps: Readonly<LabelWithInputsProps>): void {
      if(this.props.value !== prevProps.value){
        if(this.props.data.field_type == "Calendar" && this.props.value != "NA" && this.props.value != "")
          this.setState({
            input: moment(this.props.value).format("DD-MM-YYYY")
          })
        else if(this.props.data.field_type == "Real Time" && this.props.value != "NA"){
          this.setState({
            input: moment(this.props.value).format("DD-MM-YYYY HH:mm")
          })
        }
        else{
          this.setState({
            input: this.props.value
          })
        }
      }
      if(this.state.input != this.props.value ){
        if(this.ref.current !== document.activeElement){
          if(this.props.data.field_type == "Calendar" && this.props.value != "NA" && this.props.value != ""){
            this.setState({
              input: moment(this.props.value).format("DD-MM-YYYY")
            })
          }else if(this.props.data.field_type == "Real Time"){
            this.setState({
              input: moment(this.props.value).format("DD-MM-YYYY HH:mm")
            })
          }else
            this.setState({
              input: this.props.value
            })
        }
      }
    }
    
    render(): React.ReactNode {
      const { label, isDisable, data, debouncerHandler } = this.props;
      return (
        <>
          <Box height={"100%"} width={"90%"} padding={'10px'}>
            <Box className={"labelFont"} marginBottom={"3%"}>
              <Box height={"32px"} style={{display:'flex', alignItems:'flex-end'}} >{label}</Box>
            </Box>
            <Box>
              <TextField
                inputRef={this.ref}
                multiline={label === "Comments"}
                style={{ backgroundColor: isDisable ? "#EFEFEF" : "" }}
                size={"small"}
                fullWidth
                id="outlined-basic"
                disabled={isDisable}
                variant="outlined"
                value={this.state.input}
                onChange={(e) => {
                  this.setState({ input: e.target.value });
                }}
                onBlur={(e)=>debouncerHandler(e.target.value, data, "onBlur")}
                onFocus={(e)=>debouncerHandler(e.target.value, data, "onFocus")}
              />
            </Box>
          </Box>
        </>
      );
    }
  }
  
  interface LabelWithSelectProps {
    label: string;
    value: Array<{value:string, id:number, on_leave:boolean, image:string}>
    available: boolean;
    defaultValue: string | number;
    disabled: boolean;
    data: projectDataType['fields'][0];
    updateProjectDetails: (value: string, item: {id: string | number;}) => void
    personID: string
    currentFinulentStatus: string
  }
  export class LabelWithSelect extends Component<LabelWithSelectProps, {defaultValue: string}> {
  
    constructor(props: LabelWithSelectProps) {
      super(props);
      this.state = {
        defaultValue: this.props.personID
      }
    }
  
    arr = [{ value: 10, name: 'Ten' },
    { value: 20, name: 'Twenty' },
    { value: 30, name: 'Thirty' },
    ]
  
    componentDidUpdate(prevProps: Readonly<LabelWithSelectProps>): void {
      if(prevProps.personID !== this.props.personID){
        this.setState({
          defaultValue: this.props.personID
        })
      }
    }
  
    photoChahiye = false
    
    render(): React.ReactNode {
      if(this.props.label == "State"){
        console.log(this.state.defaultValue, 'asdasdasd')
      }
      if(this.props.label == "Designer" || this.props.label == "Internal QC" || this.props.label == "Internal QA")
        this.photoChahiye = true
  
      const { label, available = false, disabled, data, updateProjectDetails, currentFinulentStatus, value } = this.props;
      value.sort((a:{value:string}, b:{value:string}) => a.value.localeCompare(b.value))
      return (
        <>
          <Box height={"100%"} width={"90%"} padding={'10px'}>
            <Box className={"labelFont"} marginBottom={"3%"}>
              <Box height={"32px"} style={{display:'flex', alignItems:'flex-end'}} >{label}</Box>
            </Box>
            <Box>
                { label != "Finulent Status" ? 
              <FormControl size={"small"} variant={"outlined"} fullWidth>
                <Select
                  style={{ backgroundColor: disabled ? "#EFEFEF" : "" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.defaultValue}
                  // value={this.state.defaultValue == "" ? "NA" : this.state.defaultValue}
                  // defaultValue={this.state.defaultValue == "" ? "NA" : this.state.defaultValue}
                  disabled={disabled}
                  onChange={(e) => {
                      updateProjectDetails(e.target.value as string, data)
                      this.setState({defaultValue: e.target.value as string})
                    }
                  }
                >
                  {(label == "Designer" || label == "Internal QC" || label == "Internal QA") && <MenuItem value={"NA"} >NA</MenuItem>}
                  {!available ? this.arr.map((item) => {
                    return <MenuItem value={item.value}>{item.name}</MenuItem>
                  })
                    :
                    this.props.value.map((item) => {
                      return <MenuItem value={item.id} >
                       <Box component={'span'} style={{display: 'flex' , alignItems: 'center'}} >
                        { this.photoChahiye && <Avatar src={configJSONBase.baseURL + item.image} component={'span'} style={{height: 19, width: 19}} />}
                        <Typography style={this.photoChahiye?{marginLeft: 10, lineHeight: 0}: {lineHeight: 1, marginTop: 2}}>
                          {item.value}
                        </Typography>
                        {item.on_leave && <Box style={{height: 5, width: 5, backgroundColor: '#b70915', borderRadius: '50%', marginLeft: 14}} />}
                       </Box>
                      </MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              :
              <FormControl size={"small"} variant={"outlined"} fullWidth>
                {localStorage.setItem("finulentStatusValue",currentFinulentStatus)}
                <Select
                  style={{ backgroundColor: disabled ? "#EFEFEF" : "" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentFinulentStatus}
                  disabled={disabled}
                  onChange={(e) => updateProjectDetails(e.target.value as string, data)
                  }
                >
                  
                  {!available ? this.arr.map((item) => {
                    return <MenuItem value={item.value}>{item.name}</MenuItem>
                  })
                    :
                    this.props.value.map((item: { value: string, id: number }) => {
                      return <MenuItem value={item.id} >{item.value}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            }
            </Box>
          </Box>
        </>
      );
    }
  }
  
  export class SectionHead extends Component {
    render(): React.ReactNode {
      return (
        <>
          <Box
            padding={"5px"}
            borderRadius={"0px 30px 30px 0px;"}
            component={"span"}
            fontWeight={"bold"}
            fontSize={"15px"}
            bgcolor={"#F3AC17"}
          >
            {this.props.children}
          </Box>
        </>
      );
    }
  }
  
  interface ShowActivityProps{
    activity: unknown[];
    CustomMention: customMentionType['customMention'];
  }
  
  export class ShowActivity extends Component<ShowActivityProps>{
    constructor(props: ShowActivityProps) {
      super(props);
    }
    addAttachmentDisplayName = (data:activity['activity']) => {
      const result = data.attributes?.attachment?.attributes?.attachment?.url.split("/")
      
      if(result)
        return result[result.length - 1]
      else{
        if(data.attributes?.previous_data != null)
         return data.attributes?.previous_data
        else
          return "File has been Deleted"
      }
    }
  
    activityType = (data : activity['activity'], CustomMention: customMentionType['customMention']) => {
      if(data?.attributes?.title == "changed_status"){
        return <>
           <Typography style={{fontSize: 12}} >
              Changed status from <b>{data.attributes?.previous_data}</b> to <b>{data.attributes?.new_data}</b>
           </Typography>
        </>
      }
      if(data?.attributes?.title == "deleted_an_attachment"){
        return <>
           <Typography style={{fontSize: 12, display: 'flex', alignItems: 'center' }} >
              Deleted an attachment
              <Typography style={{fontSize: 10, color: 'grey', marginLeft: '5px'}} >
                {data.attributes?.previous_data || "File has been Deleted" }
              </Typography>
           </Typography>
        </>
      }
      if(data?.attributes?.title == "added_an_attachment"){
        return <>
           <Typography style={{fontSize: 12, display: 'flex', alignItems: 'center' }} >
              Added an attachment
           </Typography>
           <Box style={{marginTop: 10, background: 'white', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box>
                <Typography style={{padding: 10, fontSize: 10, color: 'grey', display: 'flex', alignItems: 'center' }}>
                  <img src={FileSvg} height={20} />
                  {this.addAttachmentDisplayName(data)}
                </Typography>
              </Box>
              {data.attributes?.attachment != null ? <Box>
                <Link to={data.attributes?.attachment?.attributes?.attachment?.url} target="_blank" download>
                  <img src={DownloadSvg} alt="" height={20} style={{padding: 10}} />
                </Link>
              </Box> : null}
           </Box>
        </>
      }
      if(data?.attributes?.title == "added_a_comment"){
        return <>
           <Box style={{fontSize: 12}} className={'activityMention'} >
              Added a comment
              <Box style={{fontSize: 10, color: 'grey', marginTop: 5}}>
                  <CustomMention 
                    onChange={() => {}}
                    value={data.attributes?.new_data} 
                    placeholder={""}
                    readOnly
                  />
                  </Box>
              </Box>
        </>
      }
      if(data?.attributes?.title == "deleted_a_comment"){
        return <>
            <Box style={{fontSize: 12}} className={'activityMention'}>
              Deleted a comment
              <Box style={{fontSize: 10, color: 'grey', marginTop: 5}} >
                <CustomMention 
                    onChange={() => {}}
                    value={data.attributes?.previous_data} 
                    placeholder={""}
                    readOnly
                  />
              </Box>
            </Box>
        </>
      }
      if(data?.attributes?.title == "updated_project"){
        return <>
            <Box style={{fontSize: 12}} className={'activityMention'}>
              Updated a field
              <Box style={{fontSize: 10, color: 'grey', marginTop: 5}} >
                <Typography style={{fontSize: 10, color: 'grey', marginLeft: '5px'}} >
                  {data.attributes?.new_data }
                </Typography>
              </Box>
            </Box>
        </>
      }
      if(data?.attributes?.title == "edited_a_comment"){
        return <>
           <Box style={{fontSize: 12}} className={"activityMention"} >
              Edited a comment
              <Box>
                <Typography style={{fontSize: 12}} >
                  New comment
                </Typography>
                <Box style={{fontSize: 12, color: 'grey'}} >
                  <CustomMention 
                    onChange={() => {}}
                    value={data.attributes?.new_data} 
                    placeholder={""}
                    readOnly
                  />
                </Box>
                <Typography style={{fontSize: 12, marginTop: 10}} >
                  Previous Comment
                </Typography>
                <Box style={{fontSize: 12, color: 'grey'}} >
                  <CustomMention 
                    onChange={() => {}}
                    value={data.attributes?.previous_data} 
                    placeholder={""}
                    readOnly
                  />
                </Box>
              </Box>
           </Box>
        </>
      }
    }
  
    PerActivity = (activityData:activity['activity'], CustomMention: customMentionType['customMention']) => {
      const date = moment(activityData?.attributes?.updated_at).format("D MMM YY on hh:mm a")
      return <>
        <Box style={{marginBottom: '0.75rem'}} >
          <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Avatar
              style={{ height: "20px", width: "20px" }}
              src={configJSONBase.baseURL + activityData?.attributes?.perfom_by?.attributes?.image || undefined}
            />
            <Typography style={{marginLeft: 5, fontWeight: 'bold' }} >{activityData?.attributes?.perfom_by?.attributes?.first_name + activityData?.attributes?.perfom_by?.attributes?.last_name }</Typography>
            <Typography style={{marginLeft: 10, color: 'grey', fontSize: 10}} >{date}</Typography>
          </Box>
          <Box style={{marginLeft: 27}}>
            {this.activityType(activityData,CustomMention)}
          </Box>
        </Box>
        <hr className="horizontalLineStyle" />
      </>
    }
  
    render(): React.ReactNode {
      const { activity, CustomMention } = this.props
      return(<>
        <Box style={{marginBottom: '1rem', width: '75%'}} >
        {/* <this.PerActivity activity={activity[0]} /> */}
        {activity.map((el)=>{
          return this.PerActivity(el as activity['activity'], CustomMention)
        })}
        </Box>
      </>)
    }
  }
  
  interface CheckListModalProps {
    open: boolean
    handleClose: () => void
    checkListData: checkListData[]
    updateCheckList: (id: number, designer: boolean | string, qc: 0 | 1 | string) => void
    finulentStatus: string
    disableDesginerChecklist: boolean
    handleCheckListModalClose: ()=>void
  }
  
  interface CheckListModalState {
    checkBoxCounter: number[]
    qcCounter: number[]
    altQCCount: number
  }
  
  export class CheckListModal extends PureComponent<CheckListModalProps,CheckListModalState > {
    constructor(props: CheckListModalProps) {
      super(props);
      this.state = {
        checkBoxCounter: [],
        qcCounter: [],
        altQCCount: 0
      }
    }
  
    handleCheckBoxCounter = (number: number) => {
      let arr= [...this.state.checkBoxCounter]
      if(this.state.checkBoxCounter.includes(number)){
       
        const index = arr.indexOf(number);
        if (index > -1) {
          arr.splice(index, 1);
        }
        this.setState({
          checkBoxCounter: arr
        })
      }else{
        this.setState({
          checkBoxCounter: [...arr, number]
        })
      }
    }
  
    handleQCCounter = (number: number, value: string) => {
      let arr= [...this.state.qcCounter]
      let count = this.state.altQCCount
  
      if(value == "null"){
        if(this.state.qcCounter.includes(number)){
        
          const index = arr.indexOf(number);
          if (index > -1) {
            arr.splice(index, 1);
            count--
          }
          this.setState({
            qcCounter: arr,
            altQCCount: count
          })
        }
      }else if( value == '1'){
        count++
        if(!this.state.qcCounter.includes(number)){
          this.setState({
            qcCounter: [...arr, number]
          })
        }
        this.setState({
          altQCCount: count
        })
      }else if(value == "0"){
        if(this.state.qcCounter.includes(number)){
          count--
          this.setState({
            altQCCount: count
          })
        }
      }
    }
  
    updateCounters = () => {
      let arr:number[] = []
      let someCount: number[] = []
      let count = 0
      
      this.props.checkListData.map((el, index: number)=> {
        if(el.attributes.designer){
          arr.push(index)
        }
        if(el.attributes.qc != null ){
          someCount.push(index)
          if(typeof el.attributes.qc == "string" && el.attributes.qc != "0")
            count++
        }
      })
  
      this.setState({
        checkBoxCounter: arr,
        qcCounter: someCount,
        altQCCount : count
      })
    }
  
    componentDidMount(): void {
      this.updateCounters()
      indexCounter++
    }
  
    render(): React.ReactNode {
      const { open, handleClose,checkListData, updateCheckList, disableDesginerChecklist, handleCheckListModalClose } = this.props
  
      const GreenCheckbox = withStyles({
        root: {
          color: '#f5a923',
          '&$checked': {
            color: '#f5a923 !important',
          },
        },
        checked: {},
      })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  
      const columns = [
        { id: 'code', label: 'S no', minWidth: 25 },
        { id: 'name', label: 'Error Parameter', minWidth: 350 },
        { id: 'code2', label: 'Designer', minWidth: 25 },
        {
          id: 'population',
          label: 'QC',
          minWidth: 100,
          format: (value: number) => value.toLocaleString('en-IN'),
        }
      ];
  
      return (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disablePortal={true}
            style={{zIndex: 1111}}
          >
            <Box className="checkListModalDivCenter">
              <Box style={{background: '#f7f8fb', width: '50%', height: 'auto', borderRadius: '4px', overflow: 'auto', maxHeight:800, overflowY: "hidden"}} >
                <Box width={"auto"} height={"50px"} style={{background: 'white', display:'flex', justifyContent:'space-between', padding: '20px', alignItems: 'center', borderRadius: '4px'}}>
                  <Typography style={{fontWeight: 'bold'}} >
                    CheckList
                  </Typography>
                  <Typography onClick={handleClose} style={{cursor: 'pointer'}}>
                    <img src={CloseIcon} />
                  </Typography>
                </Box>
                <Box>
                  <TableContainer style={{maxHeight: '80%', marginTop: 20, marginBottom: 20}} id={"ChecklistTableContainer"}>
                    <Table stickyHeader aria-label="sticky table" style={{width: '100%', margin: 'auto' }}>
                      <TableHead >
                        <TableRow hover={false} >
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{ minWidth: column.minWidth, background: '#f5a923', border: '1px solid #f5a923' }}
                            >
                              <b>{column.label}</b>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {checkListData.map((el, index: number)=>{
                          return (
                            <TableRow role="checkbox" tabIndex={-1} key={el.id}>
                              <TableCell key={el.id} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca'}} >
                                {index + 1}
                              </TableCell>
                              <TableCell key={el.id} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca'}} >
                                {el.attributes.error_parameter}
                              </TableCell>
                              <TableCell key={el.id} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca'}} >
                                <GreenCheckbox name="checkedC" disabled={!el.attributes.is_designer} defaultChecked={!el.attributes.is_designer ? el.attributes.designer : this.state.checkBoxCounter.includes(index)} onChange={(e)=>{updateCheckList(el.id, e.target.checked, el.attributes.qc); this.handleCheckBoxCounter(index) }} />
                              </TableCell>
                              <TableCell key={el.id} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca'}} >
                              <Select
                                onChange={(e)=>{updateCheckList(el.id, el.attributes.designer, e.target.value as string); this.handleQCCounter(index, e.target.value as string) }}
                                displayEmpty
                                defaultValue={el.attributes.qc}
                                inputProps={{ 'aria-label': 'Without label' }}
                                disabled={ disableDesginerChecklist || !el.attributes.is_qc}
                                variant={"standard"}
                                disableUnderline
                                style={{width: '80px'}}
                              >
                                <MenuItem value={"null"} disabled >
                                  Select
                                </MenuItem>
                                {el.attributes.qc_values.map((item:string | number)=>{
                                  return  <MenuItem value={item} key={item}>
                                            {item}
                                          </MenuItem>
                                })}
                              </Select>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        <TableRow role="checkbox" tabIndex={-1} >
                          <TableCell key={"last1"} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca'}} >
                            
                          </TableCell>
                          <TableCell key={"last2"} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca', textAlign: 'end'}} >
                            Total
                          </TableCell>
                          <TableCell key={"last3"} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca', textAlign: 'center'}} >
                            {this.state.checkBoxCounter.length}
                          </TableCell>
                          <TableCell key={"last4"} style={{borderLeft: '1px solid #cacbca', borderBottom: '1px solid #cacbca'}} >
                            {this.state.altQCCount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box style={{display: 'flex', justifyContent: 'flex-end', margin: '3%'}}>
                    <Button
                    // style={{fontWeight: 'bold'}}
                    className={"sendBtn"}
                    variant={"contained"}
                    onClick={()=>{handleCheckListModalClose()}}
                    >
                      {`Save`}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
        </Modal>
        </>
      );
    }
  }


  interface ProjectCardProps {
    data:projectCardData
    priority:boolean
  }

  export class ProjectCard extends PureComponent<ProjectCardProps >{
  
    render() {
      const {history,projectData,dotColor, projectStatusColors} = this.props.data;
      const {client_name,attachment_count, priority, is_overdue} = projectData;
      let newDate: string | null | string [] = projectData.Project_created ? projectData.Project_created.split("/") : null;
      newDate = newDate ? [newDate[1],newDate[0],newDate[2]].join("/") : null;
      const projectDate = new Date(newDate as unknown as string).toDateString();
      let stringProjectDate = projectDate.split(" ");
      const [year,month,days] = [stringProjectDate[3],stringProjectDate[1],stringProjectDate[2]];
      
      let tags: string[] | unknown[] = []
      let output = ""
  
      const handleTags = () => {
        
        if(is_overdue){
          tags.push("Overdue")
        }
        if(priority == "Yes"){
          tags.push("Priority")
        }
        
        let tempArr: unknown[] = []
        
        if(tags.length > 0){
          tags.forEach((element,index)=>{
            if(index != 0){
              tempArr.push(<Box component={"span"} key={index} marginX={'5px'} style={{borderLeft: '1px solid red', height: '50px', opacity: 0.8}}></Box>)
            }
           tempArr.push(element)
          })
        }
  
        tags = tempArr
        return <Box  display={"flex"} justifyContent= {"flex-end"}>
                <Box fontSize= {"0.8rem"} paddingX={"2px"} style={{borderRadius: '0 3px 0 3px'}} bgcolor={"#FFD3D2"} border= {"1px solid red"}>{tags.map((item, index)=>{
                  if(index > 0){
                    output += " | " 
                  }
                  return item
                })}</Box>
              </Box>
      }
    
  
      return (
    
        <>
          <Box
            onClick={()=>{history.push("/projectStatus",{id:this.props.data.projectData.id, data:projectData, projectStatusColors: projectStatusColors, prevPath: window.location.pathname})}}
            border={(priority == "Yes" || is_overdue) ? "1px solid red" : "1px solid darkgrey"}
            bgcolor= {(priority == "Yes" || is_overdue) ? "#FEF4F3" : ""}  
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={"column"}
            className={"projectCard"}
          >
              {(priority == "Yes" || is_overdue) && handleTags()}
            <Box
              className={"projectCardSection"}
              height={"14%"}
              component={"div"}
              display={"flex"}
              marginTop={(is_overdue || priority == "Yes") ? "-14" : "0.8vh"}
              >
              <DotOnCard color={dotColor} />
              <Box className="lightText" margin={"1.2vh"}>
                Project Id
              </Box>
              <Box className="darkText"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '68%'
              }}
              >{projectData?.project_id }</Box>
            </Box>
            <Box
              className={"projectCardSection"}
              display={"flex"}
              alignItems={"center"}
              height={"14%"}
              component={"div"}
            >
              <Box className="darkText" 
              title={projectData["project_name"]}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block'
              }}>{projectData["project_name"]}</Box>
            </Box>
            
            <Box
              display={"flex"}
              alignItems={"center"}
              className={"projectCardSection"}
              height={"14%"}
              component={"div"}
            >
              <Box className="lightText">Client</Box>
              <Box className="darkText" margin={"1.2vh"}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              >
                 {client_name}
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              className={"projectCardSection"}
              height={"10%"}
              component={"div"}
            >
              <Box className="lightText">Sub Folder Name</Box>
              <Box className="darkText" margin={"1.2vh"}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              >
                 {projectData?.team_title || "NA"}
              </Box>
            </Box>
            <Box
              className={"projectCardSection"}
              height={"14%"}
              component={"div"}
              display={"flex"}
              alignItems={"center"}
            >
              <Box className="lightText">Type </Box>
              <Box className="darkText" margin={"1.2vh"}>
                 {projectData?.type?.project_type || "NA"}
              </Box>
            </Box>
            <Box
              bgcolor={priority == "Yes" || is_overdue ? "#f5e4e5" : '#F3F7FA'}
              className={"projectCardSection"}
              component={"div"}
              style={{ padding: '10 0' }}
              display={"flex"}
              marginLeft={0}
            >
              <TeamPlayer data={
                {
                  designer:{fname: this.props.data?.projectData?.designer?.first_name || this.props.data?.projectData?.designer as unknown as string, lname: this.props.data?.projectData?.designer?.last_name, image: this.props.data?.projectData?.designer_image || undefined },
                  IQA:{fname: this.props.data?.projectData["internal_qa"]?.first_name || this.props.data?.projectData["internal_qa"] as unknown as string, lname: this.props.data?.projectData["internal_qa"]?.last_name, image: this.props.data?.projectData?.internal_qa_image || undefined },
                  IQC:{fname: this.props.data?.projectData["internal_qc"]?.first_name || this.props.data?.projectData["internal_qc"] as unknown as string, lname: this.props.data?.projectData["internal_qc"]?.last_name, image: this.props.data?.projectData?.internal_qc_image || undefined }
              }} 
              forWhom="designer"
              fullData={this.props.data}
              />
              <div style={{borderLeft: '0.5px solid grey', height: '100%', opacity: 0.5}}></div>
              <TeamPlayer data={
                {
                  designer:{fname: this.props.data?.projectData?.designer?.first_name || this.props.data?.projectData?.designer as unknown as string, lname: this.props.data?.projectData?.designer?.last_name, image: this.props.data?.projectData?.designer_image || undefined },
                  IQA:{fname: this.props.data?.projectData["internal_qa"]?.first_name || this.props.data?.projectData["internal_qa"] as unknown as string, lname: this.props.data?.projectData["internal_qa"]?.last_name, image: this.props.data?.projectData?.internal_qa_image || undefined },
                  IQC:{fname: this.props.data?.projectData["internal_qc"]?.first_name || this.props.data?.projectData["internal_qc"] as unknown as string, lname: this.props.data?.projectData["internal_qc"]?.last_name, image: this.props.data?.projectData?.internal_qc_image || undefined }
              }}
              forWhom="IQC"
              fullData={this.props.data}
              />
              <div style={{borderLeft: '0.5px solid grey', height: '100%', opacity: 0.5}}></div>
              <TeamPlayer data={
                {
                  designer:{fname: this.props.data?.projectData?.designer?.first_name || this.props.data?.projectData?.designer as unknown as string, lname: this.props.data?.projectData?.designer?.last_name, image: this.props.data?.projectData?.designer_image || undefined },
                  IQA:{fname: this.props.data?.projectData["internal_qa"]?.first_name || this.props.data?.projectData["internal_qa"] as unknown as string, lname: this.props.data?.projectData["internal_qa"]?.last_name, image: this.props.data?.projectData?.internal_qa_image || undefined },
                  IQC:{fname: this.props.data?.projectData["internal_qc"]?.first_name || this.props.data?.projectData["internal_qc"] as unknown as string, lname: this.props.data?.projectData["internal_qc"]?.last_name, image: this.props.data?.projectData?.internal_qc_image || undefined }
              }} 
              forWhom="IQA" 
              fullData={this.props.data}
              />
  
            </Box>
            <Box
              className={"projectCardSection"}
              height={"14%"}
              component={"div"}
              display={"flex"}
              alignItems={"center"}
              padding={"10 0"}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                className="lightText"
                height={"100%"}
                width={"17%"}      
                gridGap={5}
              >
                <AiOutlineMessage /> {projectData.project_comments_count}
              </Box>
              <Box
                
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                className="lightText"
                height={"100%"}
                width={"17%"}
                gridGap={5}
              >
                <ImAttachment /> {attachment_count}
              </Box>
              <Box width={"64%"} display={"flex"} justifyContent={"center"}>
                <Box className="lightText" fontSize={"0.8rem"}>
                  Project Created
                </Box>
                <Box className="darkText" fontSize={"0.8rem"} marginLeft={"5px"}>
                  {days+" "+month+" "+year}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      
      );
    }
  }
  export class TeamPlayer extends TeamPlayerController{
  
    forPropValue = () => {
      if(this.props.forWhom == "IQA")
        return "Internal QA"
      else if(this.props.forWhom == "IQC")
        return "Internal QC"
      else
        return "Designer"
    }
    render(): React.ReactNode {
      return <Box
              height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-evenly"}
            width={"32.8%"}
          >
            <Box alignItems={"center"} display={"flex"} height={"40%"}>
              <Avatar src={configJSONBase.baseURL + this.state.image} style={{ width: "1.5rem", height: "1.5rem", marginLeft: 10 }} />
              <Box className="darkText nameText">{this.state.fname}</Box>
            </Box>
            <Box height={"40%"} justifyContent={"center"} display={"flex"}>
              <Box className="lightText">{this.forPropValue()}</Box>
            </Box>
          </Box>
        
    }
  }
  
  
  export class DotOnCard extends Component<{color:string}>{
    render(): React.ReactNode {
      const {color} = this.props;
        return <>
            <Box
            borderRadius={"50%"}
            bgcolor={color}
            width={"7"}
            height={"7"}
          />
        </>
    }
  }
    // Customizable Area End