// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSONBase = require("../../../framework/src/config");

export const configJSON = require("./config");
toast.configure();


export interface Props {
  navigation: any;
  id: string;
  that?: any;
  history?: any;
  match?: any;
}
interface S {
  clientData: any,
  loading: boolean,
  isTemplateCreated: boolean,
  clientName: string
}
interface SS {
  id: any
}
export default class UpdateSequenceModalController extends BlockComponent<Props, S, SS>{
  clientDetailsApiCallId: string = "";
  query: any = new URLSearchParams(this.props.history?.location?.search);

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];
    this.state = {
      clientData: {},
      loading: true,
      isTemplateCreated: false,
      clientName: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getClientDetails();
  }

  saveBreadCrumbsDataAndRedirect = async (path: 'template' | 'checklist') => {
    const { attributes, id: client_id } = this.state.clientData;
    const { workspace, client_name, client_subfolders } = attributes;
     //istanbul ignore next
    let subFolder = await client_subfolders?.data?.find((i: any) =>
      i?.attributes?.team_leaders?.data?.find((j: any) =>
        j.id === localStorage.getItem('id')));
    const template = attributes?.template || subFolder?.attributes?.template
    const breadcrumb_data: any = {
      workspace: { id: workspace?.id, name: workspace?.name },  
      client: { id: client_id, name: client_name },
      subfolder: { id: subFolder?.id, name: subFolder?.attributes?.team_title },
      template_name: template?.attributes?.title
    };
    //istanbul ignore next
    if (!subFolder) delete breadcrumb_data?.subfolder;
    localStorage.setItem('breadcrumb_data', JSON.stringify(breadcrumb_data));
    if (path === 'template') this.props.history.push(`/projectlist?cid=${client_id}&sfid=${subFolder?.id || ""}&tid=${template?.id}`);
    if (path === 'checklist') this.props.history.push(`/definechecktable?cid=${client_id}&sfid=${subFolder?.id || ""}&tid=${template?.id}`);
  }

  getClientDetails = () => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.clientDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_client_management/clients/user_client`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleApiSuccessRes = async (responseJson: any) => {
     //istanbul ignore next
    if (responseJson?.data) {
      this.setState({ clientData: responseJson?.data })
      // storing clientname if template created.
      if (responseJson?.data?.attributes?.template_completed) this.setState({ loading: false, isTemplateCreated: true, clientName: responseJson?.data?.attributes?.client_name })
      else if (responseJson?.data?.attributes?.client_subfolders?.data?.length) {
        let subFolder = await responseJson?.data?.attributes?.client_subfolders?.data?.find((i: any) =>
          i?.attributes?.team_leaders?.data?.find((j: any) =>
            j.id === localStorage.getItem('id')));
        // storing clientname > subfoldername if template created.
        if (subFolder?.attributes?.template_completed) this.setState({ loading: false, isTemplateCreated: true, clientName: responseJson?.data?.attributes?.client_name + ' > ' + subFolder?.attributes?.team_title })
        else this.setState({ loading: false, isTemplateCreated: false })
      }
      else this.setState({ isTemplateCreated: false, loading: false })
    }
    else this.setState({ isTemplateCreated: false, loading: false })
  }

  handleApiFailure = (responseJsn: any) => {
    this.setState({ loading: false })
    if ('string' == typeof responseJsn?.errors) {
      toast.error(responseJsn.errors, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000 })
    }
    else toast.error('Something went wrong, please try again later.', { autoClose: 2000, position: toast.POSITION.BOTTOM_RIGHT })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    //istanbul ignore next
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson?.errors && !responseJson?.error) {
        if (this.clientDetailsApiCallId === apiRequestCallId) {
          this.handleApiSuccessRes(responseJson)
        }
      }
      else {
        if (this.clientDetailsApiCallId === apiRequestCallId) {
          this.handleApiFailure(responseJson)
        }
      }
    }
  }
}
// Customizable Area End