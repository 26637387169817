import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./ProjectCard.css";
import "react-multi-carousel/lib/styles.css";
import { projectCarouselData, status } from "../../utilities/src/types";
import { ProjectCarousel } from "./ProjectCarousel.web"
import { ProjectCard } from "../../utilities/src/KanbanComponents";
// Customizable Area End

import KanbanBoardController, {
  Props,
} from "./KanbanBoardController";



export default class KanbanBoard extends KanbanBoardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <Box className={this.state.isVertical? "parentCarouselContainer" : "" } id={this.state.settingData?.theme == "dark" ? "darkTheme" : "" }>
      {Object.keys(this.props.projects).length != 0 ? this.state.statusFlow.map((element,index)=>{
          return <>
              <ProjectCarousel 
              key={index}
              data={this.props.data as unknown as projectCarouselData}
              projectStatus={element as keyof status}
              card={ProjectCard}
              isVertical={this.state.isVertical}
              statusColor={this.state.statusData[index]?.attributes?.color}
          />
          </>
        }) : 
        
        <Box style={{"height": "80vh",
        "margin": "auto",
        "display": "flex",
        "fontSize": "25px",
        "alignItems": "center",
        "fontWeight": "bold",
        "flexDirection": "column",
        "justifyContent": "center"}} >
        No Projects found
        </Box>}   
       </Box>
      </>
    // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End