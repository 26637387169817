import React from "react";
// Customizable Area Start
import "react-phone-input-2/lib/bootstrap.css";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  Badge,
  Collapse,
  ListItemText,
  Link,
  Modal,
  Button,
  Popover,
  Avatar
} from "@material-ui/core";
import moment from "moment";
const configJSONBase = require("../../../framework/src/config");
import { MdKeyboardArrowUp, MdKeyboardArrowDown,MdOutlineKeyboardArrowRight } from "react-icons/md";
import {
  clientactive,
  clientinactive,
  peopleinactive,
  reportingactive,
  taskboardactive,
  templatesactive,
  userprofileactive,
  reportinginactive,
  taskboardinactive,
  templatesinactive,
  userprofileinactive,
  analyticsactive,
  analyticsinactive,
  usermanagementactive,
  usermanagementinactive,
  userrequestactive,
  userrequestinactive,
  logout,
  modalCheck,
  drawerlogoother,
  bellicon
} from "./assets";
// Customizable Area End

// Customizable Area Start
import SidebarController, { Props } from "./SidebarController";
import "./sidebar.css";
import MegaMenu from "../../../components/src/MegaMenu";
import { LightTooltip } from "../../../components/src/LightToolTip";
// Customizable Area End
const drawerWidth = 440;
// Customizable Area Start
export default class SidebarOther extends SidebarController {
  modalStyle: any = getStatusModalStyle();
  constructor(props: Props) {
    super(props);
  }
  handleClick = () => {
    this.setState({ isopen: !this.state.isopen });
  };
  handlepeopleClick = () => {
    this.state.peopleOpen ?

      (this.setState({ peopleOpen: !this.state.peopleOpen }),
        localStorage.setItem("peopleOpen", "false")) :
      (this.setState({ peopleOpen: !this.state.peopleOpen }),
        localStorage.setItem("peopleOpen", "true"))
  };
  handleColor = (e: any) => {
    e.target.style.color = "white";
  };
  handleOutColor = (e: any) => {
    e.target.style.color = "#818589";
  };

  handleEdit = () => {
    console.log(this.props, "NAVIGATION PROPS")

  };


  renderModal = () => {
    const body = (
      <Box style={this.modalStyle}>
        <Box margin={"auto"} width={"60%"}>
          <img src={modalCheck} className="statusImage" />
        </Box>
        <Box fontWeight={"bold"} textAlign={"center"}>
          {this.state.StatusModalMessage}
        </Box>
        <Box marginY={"20px"} marginBottom={"50px"} display={"flex"} justifyContent={"center"}>
          <Button onClick={this.handleLogout} style={{ width: "42%" }} variant={"contained"}>
            OK
          </Button>
        </Box>
      </Box>
    )


    return <>
      <Box>
        <Modal
          open={this.state.isStatusModalVisible}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </Box>
    </>
  }

  isManager = () => {
    if (localStorage.getItem('role_id') === "Admin")
      return true
    else if ((localStorage.getItem('role_id') === "TL/Manager"))
      return true
    else
      return false
  }

  renderMegaMenu = () => {
    return <MegaMenu drawerList={this.state.drawerList} isMegaMenuLoading={this.state.isMegaMenuLoading} />
  }

  handleTooltipClose = (e: any) => {
    this.setState({
      isNotficationOpen: false
    })
  }

  handleTooltipOpen = () => {
    this.setState({
      isNotficationOpen: true
    })
  }


  reportingPage = () => (
    <>
      {((localStorage.getItem('role_id') === "Admin")) &&
        <Link href={`/reporting-template-selection?wid=${localStorage.getItem('workspace_id') || ""}`} underline="none">
          <List style={webStyle.input}>
            <ListItem>
              {this.getReportingIcon()}
              <ListItemText
                onClick={() => localStorage.setItem("modelname", "reporting")}
                onMouseOver={this.handleColor}
                onMouseOut={this.handleOutColor}
                disableTypography={true}
                className={window?.location?.href?.includes('reporting') ? "adminselected" : "labletext"}
              >
                Reporting
              </ListItemText>
            </ListItem>
          </List>
        </Link>

      }
    </>
  )

  getClientManagementIcon = () => {
    if (localStorage.getItem("modelname") == "Client Management") {
      return <img
        src={clientactive}
        alt="notification"
        style={webStyle.sidemenuLogo}
      />
    }
    return <img
      src={clientinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getPeopleManagementIcon=()=>{    
     return <img
            src={peopleinactive}
            alt="notification"
            style={webStyle.sidemenuLogo}
          />
  }
  getUserManagementIcon=()=>{
    if(localStorage.getItem("modelname")=="User Management" ){
      return <img
      src={usermanagementactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
    }
    return <img
      src={usermanagementinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getUserRequestIcon=()=>{
    if(localStorage.getItem("modelname")=="User Request" ){
      return <img
      src={userrequestactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
    }
    return <img
      src={userrequestinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getTemplatesIcon = () => {
    if (localStorage.getItem("modelname") == "Templates") {
      return <img
        src={templatesactive}
        alt="notification"
        style={webStyle.sidemenuLogo}
      />
    }
    return <img
      src={templatesinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getManagerTaskBoardIcon = () => {
    if (localStorage.getItem("modelname") == "/managertaskboard") {
      return <img
        src={taskboardactive}
        alt="notification"
        style={webStyle.sidemenuLogo}
      />
    }
    return <img
      src={taskboardinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getTaskBoardIcon = () => {
    if (localStorage.getItem("modelname") == "/taskboard") {
      return <img
        src={taskboardactive}
        alt="notification"
        style={webStyle.sidemenuLogo}
      />
    }
    return <img
      src={taskboardinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getAnalyticsIcon = () => {
    if (localStorage.getItem("modelname") == "Analytics") {
      return <img
        src={analyticsactive}
        alt="notification"
        style={webStyle.sidemenuLogo}
      />
    }
    return <img
      src={analyticsinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getReportingIcon = () => {
    if (localStorage.getItem("modelname") == "reporting") {
      return <img
        src={reportingactive}
        alt="notification"
        style={webStyle.sidemenuLogo}
      />
    }
    return <img
      src={reportinginactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }
  getProfileIcon = () => {
    if (localStorage.getItem("modelname") == "Profile") {
      return <img
        src={userprofileactive}
        alt="notification"
        style={webStyle.sidemenuLogo}
      />
    }
    return <img
      src={userprofileinactive}
      alt="notification"
      style={webStyle.sidemenuLogo}
    />
  }

  taskboardToRender = (page: string) => {
      return <>
        <Link underline="none" href={page}>
          <List style={webStyle.input}>
            <ListItem onClick={() => localStorage.setItem("modelname", page)}>
              {page == "/taskboard" ? this.getTaskBoardIcon() : this.getManagerTaskBoardIcon()}

              <ListItemText
                onMouseOver={this.handleColor}
                onMouseOut={this.handleOutColor}
                disableTypography={true}
                // className="labletext"
                className={localStorage.getItem("modelname") == page ? "adminselected" : "labletext"}
              >
                {page == "/taskboard" ? "Task Board" : " Manager Task Board" }
              </ListItemText>
            </ListItem>
          </List>
        </Link>
      </>
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <AppBar
          position="fixed"
          style={webStyle.appbar}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <img
                src={drawerlogoother}
                alt="logo"
                style={webStyle.logo}
              />
            </Typography>
            {window.location.pathname == "/taskboard" && <h3 className="headnavbar" style={{ top: '1.3%', marginLeft: '17%', zIndex: 200, color: 'rgb(95, 95, 95)' }} >Task Board</h3>}
            {window.location.pathname == "/managertaskboard" && <h3 className="headnavbar" style={{ top: '1.3%', marginLeft: '17%', zIndex: 200, color: 'rgb(95, 95, 95)' }} >Manager Task Board</h3>}
            {window.location.pathname == "/edittemplate" && <h3 className="headnavbar" style={{ top: '1.3%', marginLeft: '15.5vw', zIndex: 200, color: 'rgb(95, 95, 95)' }} >Templates</h3>}
            {window.location.pathname == "/reporting-template-selection" && <h3 className="headnavbar" style={{ top: '1.3%', marginLeft: '15.5vw', zIndex: 200, color: 'rgb(95, 95, 95)' }} >Reporting</h3>}
            <Box sx={webStyle.outerbox} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton aria-label="show 4 new mails" color="inherit">



                <NotificationMenu markAllAsRead={this.markAllAsReadNotfication} notificationData={this.state.allNotificationData} markAsRead={this.markAsReadNotfications} listener={this.sessionStorageSetter} />

              </IconButton>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <Badge style={webStyle.badge}>
                  <Link>
                    <img
                      src={logout}
                      alt="notification"
                      style={webStyle.navlogo}
                      onClick={this.handleLogout}

                    />
                  </Link>
                </Badge>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box style={webStyle.innerbox}>
          <Drawer
            variant="permanent"
            style={webStyle.drawer}
            className="drawers"
          >
            <div style={webStyle.drawerdiv}>
              <div style={webStyle.drawerlogo}>
                <Typography variant="h6" noWrap component="div">
                  <img
                    src={drawerlogoother}
                    alt="logo"
                    style={webStyle.logoother}
                  />
                </Typography>
              </div>

              <Box sx={webStyle.box}>



                {((localStorage.getItem('role_id') === "Admin") || (localStorage.getItem('role_id') === "TL/Manager")) &&
                  // <Link href="/clientmanagement" underline="none" >
                  <Link href={((localStorage.getItem('role_id') === "Admin") ? "/clientmanagement" : "/clientmanage")} underline="none" >
                    <List style={webStyle.input}>
                      <LightTooltip title={localStorage.getItem('user_type') == 'Admin' ? this.renderMegaMenu() : ""} arrow placement={"right"} interactive>
                        <ListItem onClick={() => localStorage.setItem("modelname", "Client Management")}>

                     
                    {this.getClientManagementIcon()}
                          <ListItemText
                            onMouseOver={this.handleColor}
                            onMouseOut={this.handleOutColor}
                            disableTypography={true}
                      className={localStorage.getItem("modelname")=="Client Management"? "adminselected":"labletext"}
                          >
                            Client Management
                          </ListItemText>
                         
                      </ListItem>
                        </LightTooltip>
                    </List>
                  </Link>
                }

                {((localStorage.getItem('role_id') === "Admin")) &&
                  <Link underline="none">
                    <List
                      onClick={this.handlepeopleClick}
                      // style={{ display: "flex" }}
                      style={webStyle.input}
                    >
                      <ListItem>
                        {this.getPeopleManagementIcon()}
                        <ListItemText
                          onMouseOver={this.handleColor}
                          onMouseOut={this.handleOutColor}
                          disableTypography={true}
                          primary="People Management"
                          className="labletext"
                        />
                        {localStorage.getItem("peopleOpen") == "true" ? (
                          <ExpandLess style={{ color: "#818589" }} />
                        ) : (
                          <ExpandMore style={{ color: "#818589" }} />
                        )}
                      </ListItem>
                    </List>
                    <Collapse
                      in={localStorage.getItem("peopleOpen") == "true"}
                      timeout="auto"
                      unmountOnExit
                      style={{ color: "#818589" }}
                    >
                      <List component="div" style={webStyle.input}>
                        <Link href="UsersManagements" underline="none">
                      <ListItem onClick={()=>localStorage.setItem("modelname","User Management")}>
                        {this.getUserManagementIcon()}
                            <ListItemText
                              onMouseOver={this.handleColor}
                              onMouseOut={this.handleOutColor}
                              disableTypography={true}
                              primary="User Management"
                              className={localStorage.getItem("modelname") == "User Management" ? "adminselected" : "labletext"}
                              onClick={() => this.handleEdit()}
                            />
                          </ListItem>
                        </Link>
                        <List style={webStyle.input}>
                          <Link href="AdminUserRequest" underline="none">
                      <ListItem onClick={()=>localStorage.setItem("modelname","User Request")}>
                      {this.getUserRequestIcon()}
                              <ListItemText
                                onMouseOver={this.handleColor}
                                onMouseOut={this.handleOutColor}
                                disableTypography={true}
                                primary="User Request"
                                className={localStorage.getItem("modelname") == "User Request" ? "adminselected" : "labletext"}
                              />
                            </ListItem>
                          </Link>
                        </List>
                      </List>
                    </Collapse>
                  </Link>
                }

                {((localStorage.getItem('role_id') === "Admin")) &&
                  <Link underline="none" href="/edittemplate">
                    <List style={webStyle.input}>
                      <ListItem onClick={() => localStorage.setItem("modelname", "Templates")}>
                        {this.getTemplatesIcon()}
                        <ListItemText
                          onMouseOver={this.handleColor}
                          onMouseOut={this.handleOutColor}
                          disableTypography={true}
                          className={localStorage.getItem("modelname") == "Templates" ? "adminselected" : "labletext"}
                        >
                          Templates
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Link>
                }
                {
                  this.isManager() && this.taskboardToRender("/managertaskboard")
                }
                {
                  this.taskboardToRender("/taskboard")
                }

                {/* <Link underline="none">
                    <List style={webStyle.input}>
                        <ListItem>
                            <ListItemText
                                onMouseOver={this.handleColor}
                                onMouseOut={this.handleOutColor}
                                disableTypography={true}
                                className="labletext"
                            >
                                Analytics
                            </ListItemText>
                        </ListItem>
                    </List>
                </Link> */}

                {(localStorage.getItem('role_id') === "TL/Manager") &&
                  <Link underline="none" href="/graph">
                    <List style={webStyle.input}>
                      <ListItem onClick={() => localStorage.setItem("modelname", "Analytics")}>
                        {this.getAnalyticsIcon()}
                        <ListItemText
                          onMouseOver={this.handleColor}
                          onMouseOut={this.handleOutColor}
                          disableTypography={true}
                          primary="Analytics"
                          className={localStorage.getItem("modelname") == "Analytics" ? "adminselected" : "labletext"}
                        >
                          Analytics
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Link>
                }
                {((localStorage.getItem('role_id') === "Designer/QC/QA")) &&
                  <Link underline="none" href="/designer_analytics">
                    <List style={webStyle.input}>
                      <ListItem onClick={() => localStorage.setItem("modelname", "Analytics")}>
                        {this.getAnalyticsIcon()}

                        <ListItemText
                          onMouseOver={this.handleColor}
                          onMouseOut={this.handleOutColor}
                          disableTypography={true}
                          primary="Analytics"
                          className={localStorage.getItem("modelname") == "Analytics" ? "adminselected" : "labletext"}
                        >
                          Analytics
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Link>
                }

                {/* <Link underline="none">
                  <List style={webStyle.input}>
                    <ListItem>
                      <ListItemText
                        onMouseOver={this.handleColor}
                        onMouseOut={this.handleOutColor}
                        disableTypography={true}
                        className="labletext"
                      >
                        Analytics
                      </ListItemText>
                    </ListItem>
                  </List>
                </Link> */}

                {((localStorage.getItem('role_id') === "Admin")) &&
                  <Link underline="none" href="/analytics_admin">
                    <List style={webStyle.input}>
                      <ListItem onClick={() => localStorage.setItem("modelname", "Analytics")}>
                        {this.getAnalyticsIcon()}

                        <ListItemText
                          onMouseOver={this.handleColor}
                          onMouseOut={this.handleOutColor}
                          disableTypography={true}
                          primary="Analytics"
                          className={localStorage.getItem("modelname") == "Analytics" ? "adminselected" : "labletext"}
                        >
                          Analytics
                        </ListItemText>
                      </ListItem>

                      {/* </ListItem> */}
                    </List>
                  </Link>
                }

                {this.reportingPage()}

                <Link underline="none" href={"userprofile"}>
                  <List style={webStyle.input} >
                    <ListItem onClick={() => localStorage.setItem("modelname", "Profile")}>
                      {this.getProfileIcon()}

                      <ListItemText
                        onMouseOver={this.handleColor}
                        onMouseOut={this.handleOutColor}
                        disableTypography={true}
                        className={localStorage.getItem("modelname") == "Profile" ? "adminselected" : "labletext"}
                      >
                        My Profile
                      </ListItemText>
                    </ListItem>
                  </List>
                </Link>
              </Box>
            </div>
          </Drawer>
        </Box>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
function getStatusModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    width: "18rem",
    borderRadius: "5px",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    border: "none"
  };
}



const webStyle = {

  input: {
    paddingBottom: 0,
  },
  label: {
    color: "#818589",
    fontFamily: "san-serif",
    fontWeight: "bold",
    fontSize: 15,
  },

  appbar: {
    backgroundColor: "white",
  },

  logo: {
    width: 100,
    height: 30,
  },

  outerbox: {
    flexGrow: 1
  },

  badge: {
    color: "white"
  },

  icon: {
    color: "black"
  },

  innerbox: {
    display: "flex",
    // flexDirection: "row"
  },

  drawer: {
    zIndex: 1300,
    width: 300,
  },

  box: {
    overflow: "auto",
    width: '15vw',
  },

  drawerdiv: {
    backgroundColor: "black",
    height: "100vh",
  },

  drawerlogo: {
    height: 60,
    width: "15vw",
    borderBottom: "1px solid #606060"
  },

  logoother: {
    width: 150,
    height: 45,
    margin: 10
  },

  navlogo: {
    width: 30,
    height: 30,
  },
  sidemenuLogo: {
    height: 22,
    width: 22,
    marginRight: 10
  },
  notficationMenu: {
    width: "30rem",
    backgroundColor: "white",
    padding: "0.5rem 1rem",
    display: "flex",

  },
}



type PropTypeMenu = {
  notificationData: any;
  markAsRead: any;
  markAllAsRead: any;
  listener: any;
}

export class NotificationMenu extends React.PureComponent<PropTypeMenu, { anchorEl: any }> {
  constructor(props: PropTypeMenu) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }


  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };






  render(): React.ReactNode {
    const allNotificationData = this.props.notificationData;
    const isUnreadNotification = () => {
      const unreadNotfication = allNotificationData.filter((el: any) => !el.attributes.is_read);
      const value = unreadNotfication.length == 0 ? true : false;
      return { value: value, numberOfUnreadNotificatiion: unreadNotfication.length };
    }

    return (
      <>
        <Box>
          <Box onClick={this.handleClick}>
            <Badge invisible={isUnreadNotification().value} badgeContent={isUnreadNotification().numberOfUnreadNotificatiion} color={"secondary"} style={webStyle.badge}>
              <img
                src={bellicon}
                alt="notification"
                style={webStyle.navlogo}
              />
            </Badge>
          </Box>
          <Popover
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ marginTop: "1.5rem" }}
          >
            <Box fontFamily={"sans-serif"} style={webStyle.notficationMenu} flexDirection={"column"}>

              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginY={"1rem"}>
                <Box fontWeight={"bold"} fontSize={"16px"}>Notifications</Box>
                {!isUnreadNotification().value && <Box onClick={this.props.markAllAsRead} className={"markAllRead"}>Mark all as read</Box>}
              </Box>
              <Box>
                {allNotificationData.length != 0 && <Box className="noficationBox">
                  <Box color={"darkgrey"} paddingLeft={"20px"}>New</Box>
                  {allNotificationData.map((el: any, index: number) => {
                    return !el.attributes.is_read && <NotficationCard key={index} notificationData={el} markAsRead={this.props.markAsRead} />
                  })}


                  <Box marginTop={"25px"}>
                    <Box color={"darkgrey"} paddingLeft={"20px"}>Old</Box>
                    {allNotificationData.sort((a: any, b: any) => new Date(b.attributes.created_at).valueOf() - new Date(a.attributes.created_at).valueOf()).map((el: any, index: number) => {
                      return el.attributes.is_read && <NotficationCard key={index} notificationData={el} markAsRead={this.props.markAsRead} />
                    })}



                  </Box>
                </Box>}
              </Box>
              <Box marginY={"0.5rem"} display={"flex"} justifyContent={"flex-end"}>
                <Link style={{ textTransform: "none", color: "black" }} href="/InAppNotification">
                  <Box padding={"5px 10px"} display={"flex"} alignItems={"center"} bgcolor={"#E1E0E5"} borderRadius={"15px"}>
                    <Box position={"sticky"} marginRight={"0.4rem"}>See all</Box>
                    <Box padding={"5px"} borderRadius={"50%"} bgcolor={"darkgrey"}><MdOutlineKeyboardArrowRight /></Box>
                  </Box>
                </Link>
              </Box>
            </Box>
          </Popover>
        </Box>
      </>
    );
  }
}

type NotficationCardProp = {
  notificationData: any;
  markAsRead: any;
}



export class NotficationCard extends React.PureComponent<NotficationCardProp, { showMore: boolean, setHeight: any }>{
  textref = React.createRef();
  constructor(props: NotficationCardProp) {
    super(props);
    this.state = {
      showMore: true,
      setHeight: false
    }
  }

  showMoreButtonToggler = (event: any) => {
    event?.stopPropagation();
    this.setState({ showMore: !this.state.showMore });
  }

  handleMarkAsRead = () => {
    const { id } = this.props.notificationData;
    const { is_read } = this.props.notificationData.attributes;
    !is_read && this.props.markAsRead(id);
  }

  commentRegex = (body: any) => {
    const regex = /@\[([^\]]+)\]\(\d+\)/g;
    return body.replace(regex, `<span class = "taggedPerson">$1</span>`)
  }

  renderColor = (role: any) => {
    const value = role == "Superadmin" ? "skyblue" : "orange";
    return value;
  }

  componentDidMount() {
    if (this.textref.current) {
      // @ts-expect-error
      const { height, fontSize } = window.getComputedStyle(this.textref.current);
      this.setState({ setHeight: Math.floor(parseFloat(height) / parseFloat(fontSize)), showMore: false });

    }
  }


  render() {
    const { is_read, title, remarks, body, created_at, perfom_by } = this.props.notificationData.attributes;
    const { showMore } = this.state;
    const role = localStorage.getItem("role_id");
    const unReadColor = role === "Superadmin" ? "#DFE9F4" : "#F9F0EB"
    return <>
      <Box className={"notificationCard"} onClick={this.handleMarkAsRead} padding={"15px 20px"} bgcolor={!is_read ? unReadColor : "#DFDFDF"} borderRadius={"5px"} margin={"10px 0px"}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} >
            <Box><Avatar style={{ height: "22px", width: "22px" }} src={configJSONBase.baseURL + perfom_by?.attributes.image} /></Box>
            <Box marginLeft={"15px"} fontWeight={"bold"}>{perfom_by?.attributes.first_name || "John"}  {perfom_by?.attributes.last_name || "Doe"}  </Box>
          </Box>
          <Box color={"darkgrey"}>{moment(created_at).format("DD MMM YY , hh:mm A")}</Box>
        </Box>
        <Box fontSize={"14px"} marginLeft={"35px"} marginTop={"10px"}>{remarks}</Box>

        {title && <Box>
          {/*@ts-expect-error*/}
          <Box dangerouslySetInnerHTML={{ __html: this.commentRegex(body) }} ref={this.textref} marginTop={"5px"} marginLeft={"35px"} color={"#A7A6A6"} overflow={"hidden"} fontSize={"13px"} textOverflow={"ellipsis"} whiteSpace={!showMore ? "nowrap" : ""}>
          </Box>

          {this.state.setHeight > 1 && <Box marginLeft={"35px"}><Button onClick={this.showMoreButtonToggler} style={{ fontWeight: "bold", color: role == "Superadmin" ? "skyblue" : "orange", textTransform: "none" }}>{showMore ? "see less " : "see more "}{showMore ? <MdKeyboardArrowUp color={this.renderColor(role)} fontSize={"25px"} /> : <MdKeyboardArrowDown color={this.renderColor(role)} fontSize={"25px"} />} </Button> </Box>}
        </Box>}
      </Box>
    </>
  }
}


// Customizable Area End
