// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { projectCardData, teamPlayerData } from "../../blocks/utilities/src/types";

export const configJSON = require("../../blocks/KanbanBoard/src/config");

export interface TPProps {
    data:teamPlayerData["data"],
    forWhom: "designer" | "IQA" | "IQC",
    fullData: projectCardData
}

interface TPS {
    fname:string,
    image: string | undefined | null
}

interface TPSS {
  id: {}
}

export default class TeamPlayerController extends BlockComponent<
  TPProps,
  TPS,
  TPSS
> {
  constructor(props: TPProps) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
        image: this.props.data[this.props.forWhom]?.image || undefined,
        fname: this.props.data[this.props.forWhom]?.fname ,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidUpdate(prevProps: Readonly<{ data: teamPlayerData["data"]; forWhom: "designer" | "IQA" | "IQC"; fullData: projectCardData }>) {
    if(this.props.data[this.props.forWhom]?.fname != prevProps.data[this.props.forWhom]?.fname){
      this.setState({
        image: this.props.data[this.props.forWhom]?.image || undefined,
        fname: this.props.data[this.props.forWhom]?.fname,
      })
    }
  }

}
// Customizable Area End