import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSONBase = require("../../../framework/src/config");
// Customizable Area End
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  that?: any;
  history?: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  workspaces: any;
  loading: boolean
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getWorkspacesForReportAPIId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getWorkspacesForReport = this.getWorkspacesForReport.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      workspaces: [],
      loading: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.getWorkspacesForReport();
  }
  // Customizable Area Start
  handleLeaveReport = () => {
    this.props.history.push("leaveReport")
  }
  handleReport = (item:any) => {
    this.props.history.push(`/reporting-template-selection?wid=${item.id}`)
  }
  // Customizable Area End
  getWorkspacesForReport = ()=>{
    const header = {
      "Content-Type": "application/json",
      token: window.localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWorkspacesForReportAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_workspace_management/workspaces?report=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  async receive(from: string, message: Message) {
        // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && !responseJson.error) {
        if (this.getWorkspacesForReportAPIId === apiRequestCallId) {
          this.setState({ workspaces: responseJson.data, loading: false });
        }
      } else {
        this.setState({ loading: false });
        toast.error('Something went wrong, please try again later.', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000 });
      }
    }
        // Customizable Area End
  }
}
