//Customizable Area Start

import React from "react";
import PhoneInput from "react-phone-input-2";
import MomentUtils from "@date-io/moment";

import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-pickers";

import Avatar from "@material-ui/core/Avatar";

import {

  Box,
  Button,

  Typography,
  createMuiTheme ,
  Checkbox,
  InputLabel,
  TextField,
  InputAdornment,
  Grid,

  TextareaAutosize,
  Select,
  MenuItem,
  Divider,
  Modal,
  Menu,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,

  TablePagination,

  CircularProgress,
  ListItemIcon,
  Tooltip


} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


import SearchBar from "material-ui-search-bar";


import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import GenericModal from "./GenericModal.web";
import GenericSuccessModal from "./GenericSuccessModal.web";
import { filter,darkFilter } from "./assets"
// Customizable Area End

import UserManagementController, { Props } from "./UserManagementController";
import "./UserManagement.css";


export const configJSONBase = require("../../../framework/src/config");

const materialTheme = createMuiTheme({
  overrides: {
    //   @ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        color: "black",
        backgroundColor: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"rgb(171 114 24)",
        backgroundImage: localStorage.getItem('user_type')=='Superadmin'?<></>:"radial-gradient(rgb(246 168 34), rgb(171 114 24))",
      },
    },

    MuiPickersDay: {
      day: {
        color:localStorage.getItem('user_type')=='Superadmin'? "black":"rgb(171 114 24)"
      },
      daySelected: {
        backgroundColor: localStorage.getItem('user_type')=='Superadmin'?"#e8e8e8":"rgb(171 114 24)",
        backgroundImage: localStorage.getItem('user_type')=='Superadmin'?<></>:"radial-gradient(rgb(246 168 34), rgb(171 114 24))",
      },
      dayDisabled: {
        color:localStorage.getItem('user_type')=='Superadmin'? "#e8e8e8":"rgb(171 114 24)"
      },
      current: {
        color:localStorage.getItem('user_type')=='Superadmin'? "#e8e8e8":"rgb(171 114 24)"
      },
      isSelected: {
        color: "white",
        backgroundColor: localStorage.getItem('user_type')=='Superadmin'? "#e8e8e8":"rgb(171 114 24)",
        backgroundImage:localStorage.getItem('user_type')=='Superadmin'?<></>: "radial-gradient(rgb(246 168 34), rgb(171 114 24))",
      },
    },

    MuiPickersToolbarButton: {
      toolbarBtn: {
        color:  localStorage.getItem('user_type')=='Superadmin'?"black":"white"
      },
      toolbarBtnSelected: {
        color: localStorage.getItem('user_type')=='Superadmin'?"black":"white"
      },
    },

    palette: {
      primary: "red",
    },
    MuiButton: {
      textPrimary: {
        color:localStorage.getItem('user_type')=='Superadmin'? "black":"rgb(171 114 24)"
      },
    },
    MuiIconButton: {
      root: {
        padding: "12px 12px 12px 12px",
      },
    },

    MuiPickersModal: {
      dialogAction: {
        color: localStorage.getItem('user_type')=='Superadmin'? "#e8e8e8":"rgb(171 114 24)"
      },
    },
    myComponent: {
      "& .MuiPickersDay-isSelected": {
        backgroundColor: "red",
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: localStorage.getItem('user_type')=='Superadmin'? "black":"rgb(171 114 24)"
      },
      dayLabel: {
        color:  localStorage.getItem('user_type')=='Superadmin'?"black":"rgb(171 114 24)"
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({ selectRoot: { '&:focus': { backgroundColor: 'yellow' } } }));
const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));


const Placeholder: React.FunctionComponent = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};




export default class UserManagement extends UserManagementController {
  // static get isLoggedIn(){
  //     return {isLoggedIn:localStorage.getItem('token')}
  // }

  constructor(props: Props) {
    super(props);

  }
  handleOpen = () => {
    this.setState({ genericModalOpen: true });
    this.getWorkspaces();
  }

  handleClose = () => {
    this.setState({
      genericModalOpen: false, isEditMode: false, firstname: '', lastname: '', birthDate: null,
      joiningDate: null,
      email: "",
      contactNo: "",
      password: '',
      role: "",
      desc: "",
      workspace: "",
      designation: "",
      fileData: "", page: true, isErrorEmail: false
    });
  };
  modalClose = () => {
    this.setState({ isSuccessModal: false, genericModalOpen: false, modalMsg: "", isEditMode: false, modalVarient: "" });
  };
  handleImage = (e: any) => {
    console.log( e.target.files[0],"FILe")
    this.setState({
      fileData: e.target.files[0],
      // imageName: e.target.files[0].name,
    });
  };
  handleDateChange = (date: any) => {
console.log(date, "Date")
    this.setState({ birthDate: date });
  };
  handleJoingDateChange = (date: any) => {
    this.setState({ joiningDate: date });
  };

  handleAction = () => {
    if (this.state.page) {
      this.setState({ page: false });
    }



    if (
      this.state.firstname &&
      this.state.lastname &&
      this.state.birthDate &&
      this.state.email &&
      this.state.contactNo &&
      this.state.designation &&
      this.state.role &&
      this.state.joiningDate&&
      this.state.workspace &&
      this.state.fileData
    ) {

      this.setState({ isErrorEmail: false });

      if (this.state.isEditMode) {
        this.updateUser()

      } else {
        this.handleAddAction();

      }
      this.setState({
        page: true,
        firstname: "",
        lastname: "",
        birthDate: null,
        joiningDate: null,
        email: "",
        contactNo: "",
        password: '',
        role: "",
        designation: "",
        desc: "",
        workspace: "",
        fileData: "",
        isErrorFirstName: false,
        isErrorLastName: false,
        isErrorPassword: false,

      })
    }

  };
  handleChange = (e: any) => {
    let char, email;
    e.persist();
    if (e.target.name == "password") {

      let password = e?.currentTarget?.value;

      const regex= /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/

      !regex.test(password) || password.length < 5
        ? this.setState({ isErrorPassword: true })
        : this.setState({ isErrorPassword: false });
      this.setState({ password: e?.currentTarget?.value });
    } else if (e.target.name == "firstname") {
      char = /^[a-zA-Z ]*$/.test(e.currentTarget.value);
      char
        ? this.setState({
          isErrorFirstName: false,
          firstname: e?.currentTarget?.value,
        })
        : this.setState({ isErrorFirstName: true });
    } else if (e.target.name == "lastname") {
      char = /^[a-zA-Z ]*$/.test(e.currentTarget?.value);
      char
        ? this.setState({
          isErrorLastName: false,
          lastname: e?.currentTarget?.value,
        })
        : this.setState({ isErrorLastName: true });
    } else {
      const val = {
        ...this.state,
        [e.target.name]: e.target.value,
      };
      this.setState(val);
    }
  };

  successErrModalBody = () => (
    <>
      <div>
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "sans-serif",
            fontWeight: "bold",
            // color: "red",
          }}
        >
          { this.state?.modalMsg}

        </Typography>
      </div>
    </>
  );
  modalBodyDataOne=()=>(
    <>
     <Box>
            <Grid container spacing={2}>
              <Grid item sm={6} spacing={2}>
                <InputLabel
                  className="inputLabel"

                >
                  First Name*
                </InputLabel>

                <TextField
                id="firstname"
                className="handle_change"
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  placeholder="John"
                  type="text"
                  name="firstname"
                  value={this.state.firstname}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
                {this.state.firstname === "" && !this.state.page && (
                  <p
                    className="errMsg"

                  >
                    {" "}

                    First Name required
                  </p>
                )}

                {this.state.isErrorFirstName && (
                  <p
                    className="character-message"

                  >
                    Only characters are allowed.
                  </p>
                )}
              </Grid>
              <Grid item sm={6}>
                <InputLabel
                  className="inputLabel"

                >
                  Last Name*
                </InputLabel>
                <TextField
                id="lastname"
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  placeholder="Doe"
                  type="text"
                  name="lastname"
                  value={this.state.lastname}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
                {this.state.lastname === "" && !this.state.page && (
                  <>
                    <p
                      className="errMsg"
                      style={{
                        color: "red",
                        marginTop: "2px",
                        fontFamily: "sans-serif",
                        fontSize: "12px",
                      }}
                    >
                      {" "}

                      Last Name required
                    </p>
                  </>
                )}
                {this.state.isErrorLastName && (
                  <p
                    className="character-message"

                  >
                    {" "}
                    Only characters are allowed.
                  </p>
                )}
              </Grid>

            </Grid>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item sm={6} style={webStyle.boxes}>
                <InputLabel
                  className="inputLabel"

                >
                  Birthdate*
                </InputLabel>

                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  style={{ background: "orange" }}
                >
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                    id="birthdate"
                    className="date_change"
                      keyboard
                      variant="outlined"
                      size="small"
                      fullWidth
                      maxDate={new Date().setDate(new Date().getDate() - 1)}
                      placeholder="DD/MM/YYYY"
                      format={"DD/MM/YYYY"}
                      mask={(value) =>
                        value
                          ? [
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]
                          : []
                      }
                      value={this.state.birthDate}
                      onChange={this.handleDateChange}
                      disableOpenOnEnter
                      animateYearScrolling={false}
                      autoOk={true}
                      clearable
                      onInputChange={(e: any) =>
                        console.log("Keyboard:", e.target.value)
                      }
                    />


                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                {this.state.birthDate === null && !this.state.page && (
                  <p
                    className="errMsg"

                  >
                    {" "}

                    Birth date required
                  </p>
                )}
              </Grid>
              <Grid item sm={6} style={webStyle.boxes}>
                <InputLabel
                  className="inputLabel"

                >
                  Contact Number*
                </InputLabel>

                <PhoneInput
               inputClass="contactno"
                  country={"in"}
                  enableSearch={true}
                  disableSearchIcon
                  inputStyle={{
                    padding: "10px 14px 10px 60px",
                    width: "100%",
                  }}
                  value={this.state.contactNo}
                  onChange={(phone) => this.setState({ contactNo: phone })}
                />
                {this.state.contactNo === "" && !this.state.page && (
                  <>
                    <p
                      className="errMsg"

                    >
                      Contact No required
                    </p>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container spacing={2} style={webStyle.boxes}>
              <Grid item sm={6} spacing={2}>
                <InputLabel className="inputLabel">Designation*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="designation"
                  placeholder="Select"
                  value={this.state.designation}
                  name="designation"
                  // label="Age"
                  onChange={this.handleChange}
                  variant="outlined"
                  required
                  fullWidth
                  style={{ height: "40px", textAlign: "left" }}
                >

                  {this.state.designationList && this.state.designationList?.map((item: any) => (
                    <MenuItem value={item.name}>{item.name} </MenuItem>
                  ))}

                </Select>
                {this.state.designation === "" && !this.state.page && (
                  <>
                    <p
                      className="errMsg"

                    >

                      Designation is required
                    </p>
                  </>
                )}

              </Grid>
              <Grid item sm={6}>
                <InputLabel className="inputLabel">Role*</InputLabel>
                <Select

                  labelId="demo-simple-select-label"
                  id="role"
                  placeholder="Select"
                  value={this.state.role}
                  name="role"
                  onChange={this.handleChange}
                  variant="outlined"
                  required
                  fullWidth
                  style={{ height: "40px", textAlign: "left" }}

                >

                  {this.state.roleData && this.state.roleData.map((item: any) => (
                    <MenuItem value={item}
                    >{item}</MenuItem>
                  ))}

                </Select>
                {this.state.role === "" && !this.state.page && (
                  <>
                    <p
                      className="errMsg"

                    >

                      Role is required
                    </p>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
    </>
  )
  modalBodyDataTwo=()=>(
    <>
    <Box style={webStyle.boxes}>
            <InputLabel
              className="inputLabel"

            >
              Description
            </InputLabel>
            <TextareaAutosize
            id="description"
              aria-label="minimum height"
              minRows={5}
              placeholder="Optional"
              style={{ width: "100%" }}
              onChange={this.handleChange}
              value={this.state.desc}
              name="desc"
              className="description"
            />
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <InputLabel
              className="inputLabel"

            >
              Official Email Id*
            </InputLabel>

            <TextField
            id="email"
              variant="outlined"
              size="small"
              required
              fullWidth
              placeholder="Email id here"
              type="text"
              onChange={this.handleChange}
              value={this.state.email}
              name="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <img src={mail} style={{ width: 30, height: 30 }} /> */}
                  </InputAdornment>
                ),
              }}
            />
            {this.state.email === "" && !this.state.page && (
              <p
                className="errMsgEmail"

              >

                Email is required
              </p>
            )}
            {this.state.isErrorEmail && (
              <p
                style={{
                  color: "red",
                  fontFamily: "sans-serif",
                  fontSize: "12px",
                }}
              >
                {" "}
                Email must end with @finulent.com
              </p>
            )}
          </Box>
          <Box>
            <InputLabel
              className="inputLabel" >
              Joining date*
            </InputLabel>

            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ background: "orange" }}
            >
              <ThemeProvider theme={materialTheme}>
                <DatePicker
                id="joiningdate"
                  keyboard
                  variant="outlined"
                  size="small"
                  fullWidth

                  placeholder="DD/MM/YYYY"
                  format={"DD/MM/YYYY"}
                  mask={(value) =>
                    value
                      ? [
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]
                      : []
                  }
                  value={this.state.joiningDate}
                  onChange={this.handleJoingDateChange}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  autoOk={true}
                  clearable
                  onInputChange={(e: any) =>
                    console.log("Keyboard:", e.target.value)
                  }
                />


              </ThemeProvider>
            </MuiPickersUtilsProvider>
            {this.state.joiningDate === null && !this.state.page && (
              <p
                className="errMsg"

              >
                {" "}

                Joining Date required
              </p>
            )}

          </Box>
    </>
  )
  modalBody = () => (
    <>
      <Grid>
        <Box sx={webStyle.parent}>
          <div className="imageupload" style={{ display: "flex" }}>

            <label className="input-label" htmlFor="input-label">

              <Box
                className="img-avatar"


              >
                {this.state.fileData ? (
                  <Avatar src={ typeof(this.state.fileData)=="string" ?this.state.fileData:URL.createObjectURL(this.state.fileData)} style={{width:"100%", height:"100%"}}>
                  </Avatar>

                ) : (

                  <CloudUploadOutlined style={{ color: "black" ,}}  />

                )}
              </Box>
              {this.state.isEditMode ? <>
              </> : <input
              className="userimage"
                id="input-label"
                type="file"
                accept="image/*"
                onChange={(e) => this.handleImage(e)}
              />}

              <h4 className="profile-name">UPLOAD PROFILE PICTURE *</h4>
              {this.state.fileData === "" && !this.state.page && (
                <p
                  className="errMsg"

                >
                  {" "}

                  Profile Picture is required
                </p>
              )}

            </label>

          </div>

          {this.modalBodyDataOne()}
          {this.modalBodyDataTwo()}
          <Box sx={webStyle.boxes}>
            <InputLabel
              className="inputLabel">
              Workspace*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="workspace"
              value={this.state.workspace}
              name="workspace"
              onChange={this.handleChange}
              variant="outlined"
              required
              fullWidth
              style={{ height: "40px" }}
            >
              {this.state.workspaceData && this.state.workspaceData.map((item: any) => (
                <MenuItem value={item.id}>{item.attributes?.name}</MenuItem>
              ))}

            </Select>
            {this.state.workspace === "" && !this.state.page && (
              <>
                <p
                  className="errMsg"

                >

                  Workspace is required
                </p>
              </>
            )}
          </Box>
          <Box sx={webStyle.boxes}>
            <InputLabel
              className="inputLabel"

            >
              Password*
            </InputLabel>
            <TextField
            id="password"
              variant="outlined"
              size="small"
              required
              fullWidth
              disabled={this.state.isEditMode}
              name="password"
              placeholder="********"
              type="password"
              style={{ borderRadius: "6px", fontSize: "12px" }}
              value={this.state.password}
              onChange={this.handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* <img src={password} style={{ width: 30, height: 30 }} /> */}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {/* <img src={eye} style={{ width: 30, height: 30 }} /> */}
                  </InputAdornment>
                ),
              }}

            />
            {this.state.password === "" && !this.state.page && (
              <>
                <p
                  className="errMsg"

                >

                  Password  is required
                </p>
              </>
            )}

            {this.state.isErrorPassword ? (
              <p
                // style={{ color: "red", textAlign:'left' }}
                className="message-main">
                Password must contain:
                <br />
                <p
                  className="sub-message"


                >
                  Minimum 8 characters
                </p>
                <p
                  className="sub-message"

                >
                  Atleast One Number, One Lowercase, One Uppercase and One
                  Special Character
                </p>
              </p>
            ) : (
              <p
                className="noerror-password"
              // style={{ color: "#9fa0a2", textAlign:"left" }}
              >
                <span
                  className="span-message"

                >
                  Password must contain:{" "}
                </span>
                <br />
                <p style={{ margin: 10, fontFamily: "sans-serif" }}>
                  Minimum 8 characters
                </p>
                <p style={{ marginLeft: 10, fontFamily: "sans-serif" }}>
                  Atleast One Number, One Lowercase, One Uppercase and One
                  Special Character
                </p>
              </p>
            )}
            {/* <hr className="solid"></hr> */}
            <Divider />
          </Box>
        </Box>
      </Grid>
    </>
  );

  handleMore = (id: any, e: any) => {
    // console.log(e.currentTarget, "Ka")
    this.getUserStatus(id)
    this.setState({ isMenu: e.currentTarget, userId: id })
  }
  
  handleMenuClose = (e: any) => {
    this.setState({ isMenu: null })
  }
  handleMenuStatus = (e: any) => {

    this.setState({ isSubMenu: e.currentTarget, })
  }
  handleSubMenuClose = (e: any) => {
    this.setState({ isSubMenu: null, isChecked: false, isCheckedFalse: false })
  }

  handleEdit = (e: any) => {
    let imagesconfig = configJSONBase.baseURL;
    this.state.usersData?.map((item: any) => {
      if (item.id === this.state.userId) {
        let binaryData = [] as any;
        binaryData.push(item.attributes.image);
        this.setState((prev) => {

          return {
            ...prev,
            genericModalOpen: true,
            firstname: item.attributes.first_name,
            lastname: item.attributes.last_name,
            birthDate: item.attributes.date_of_birth,
            contactNo: item.attributes.full_phone_number,
            email: item.attributes.email,
            desc: item.attributes.description,
            designation: item.attributes.designation,
            role: item.attributes.role_id,
            workspace: item.attributes.workspace?.id,
            joiningDate: item.attributes.joining_date,
            isEditMode: true,
            fileData: imagesconfig + item.attributes.image

          }

        })

      }
    })

  }
  handleStatus = (e: any) => {

    const innerText = e.target.innerText
    let status = false
    if (innerText === "Active") {
      status = true

    }
    this.setState({ activeValueId: status, isSubMenu: null, isChecked: false, isCheckedFalse: false })
    this.updateUserStatus(this.state.userId, innerText)

  }
  handleChangePage = (event: unknown, newPage: number) => {

    this.setState({ pageNo: newPage })
  };
  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: +event.target.value })
    this.setState({ pageNo: 0 })
  };

  requestSearch = (e: any) => {

    this.setState({ search: e }, () => this.getUserList(e))
  };
  
  cancelSearch = () => {
    this.setState({ search: "",initialTableLoad:true});
    this.requestSearch(this.state.search);
    this.setState({ search: "" })
  };
  handleWorkspaceChange = (e: any) => {
    const { value } = e.target;

    if (value[value.length - 1] == "all") {
      this.setState({ selectedWorkspace: this.state.workspaceData.length == this.state.selectedWorkspace.length ? [] : this.state.workspaceData })
      this.getClientData(this.state.workspaceData)
    }
    else {
      this.setState({ selectedWorkspace: value }, () => this.getClientData(this.state.selectedWorkspace));

    }
  }
  handleClientChange = (e: any) => {
    const { value } = e.target;
    if (value[value.length - 1] == "all") {
      this.setState({ assignedClient: this.state.dataAssignedClientList?.length == this.state.assignedClient?.length ? [] : this.state.dataAssignedClientList })
      this.getSubClientData(this.state.dataAssignedClientList)

    } else {
      this.setState({ assignedClient: value }, () => this.getSubClientData(this.state.assignedClient));
    }

  }
  handleSubClientChange = (e: any) => {
    const { value } = e.target;
    if (value[value.length - 1] == "all") {
      this.setState({ assignedSubclient: this.state.dataSubClientList?.length == this.state.assignedSubclient?.length ? [] : this.state.dataSubClientList })
      return;
    }
    this.setState({ assignedSubclient: value });
  }
  handleApply = () => {
    if(this.state.role.length || this.state.isStatus.length|| this.state.selectedWorkspace.length){
      this.getUserList(this.state.search)
      this.setState({ filterOpen: null, initialTableLoad: true, appliedFilter:true})
    }
    this.setState({filterOpen:null})
  }
  handleFilterMenuClose = () => {
    this.setState({ isConfirm: false })
  }
  handleFilterClose = () => {
    this.setState({ isConfirm: false, isStatus: "",  filterOpen: null, selectedWorkspace: [],assignedClient:[],assignedSubclient:[] })
  }
  handleFilterClick = (e: any) => (
    this.setState({ filterOpen: e.currentTarget })
  )

  handleDeleteBtn = () => {
    this.setState({ isConfirm: true })

  }
  handleCancelBtn = () => {
    this.setState({ isConfirm: false })
  }
  handleYes = () => {
    this.deleteUser()
    this.setState({ isConfirm: false })
  }
  getNoDataViewForSearch = () => {
    if (this.state.search?.length > 0 || this.state.search?.length > 0 && this.state.usersData?.length == 0) {
      return <p className="norecord">No invoices found</p>
    }
  }
  confirmDelete = () => (
    <Modal
      className="modal-backdrop"
      //   open={false}
      open={this.state.isConfirm}
      onClose={this.handleCancelBtn}
      style={{
        overflow: "auto",
      }}
    >
      <div
        // className="modal-successcontent"
        style={{
          zIndex: 10,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          width: "20%",
          // height: "20%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          // padding:"20px"
        }}
      >
        <Grid>
          <div
            style={{ textAlign: "center" }}

          >
            <div className="confirm-heading" style={{ padding: "10px" }} >
              <Typography style={webStyle.delteUserBtn}>Delete User</Typography>
              <CloseIcon onClick={this.handleCancelBtn} />
            </div>
            <Divider />
            <div style={{ padding: "20px" }} className="confirmbody">
              <Typography>Are you sure,you want to delete selected user ?</Typography>
            </div>
            <Divider />
            <div style={{ padding: "20px" }}>
              <Button
                onClick={this.handleCancelBtn}
                className="cancelbtn"

              >
                Cancel
              </Button>
              <Button
                onClick={this.handleYes}
                className= {localStorage.getItem('user_type')=='Superadmin'?"yesbtn":"yesadminbtn"}
                style={{ backgroundColor: "#0096FF " }}
              >
                Yes
              </Button>
            </div>
          </div>
        </Grid>
      </div>
    </Modal>
  )

  checkStatus = () => (<CheckIcon />)
  handleReset = () => {

    this.getUsers()
    this.setState({ isStatus: "", selectedWorkspace: [], role: "", assignedClient: [], assignedSubclient: [], assignedWorkspace: [],appliedFilter:false })
  }
  subUserItem = () => (
    <Menu
    // className="subuseritem"
      id="simple-menu"
      anchorEl={this.state.isMenu}
      keepMounted
      open={this.state.isMenu != null ? true : false}
      onClose={this.handleMenuClose}
      className="menu-field"
      onClick={this.handleMenuClose}
    >
      <MenuItem className="menu-field" onClick={this.handleMenuStatus} >Status</MenuItem>
      <MenuItem onClick={this.handleEdit} className="edituserid">Edit</MenuItem>
      <MenuItem onClick={this.handleDeleteBtn}>Delete</MenuItem>
    </Menu>
  )
  activeMenuItem = () => (
    <Menu
      id="simple-menu"
      anchorEl={this.state.isSubMenu}
      keepMounted
      open={this.state.isSubMenu != null ? true : false}
      onClose={this.handleSubMenuClose}

      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      className="subMenu"

    >
      <MenuItem onClick={this.handleStatus}> <div>Active</div> {this.state.isChecked && this.checkStatus()
      }</MenuItem>
      <MenuItem onClick={this.handleStatus}  >Inactive  {this.state.isCheckedFalse && this.checkStatus()}</MenuItem>

    </Menu>
  )
  roleMenuItem = () => (
    <Menu

      anchorEl={this.state.filterOpen}
      id="account-menu"
      open={Boolean(this.state.filterOpen)}

      onClose={this.handleFilterClose}

      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    // style={{width:"50%"}}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
        <InputLabel className="inputfilter">  Filter</InputLabel>
        <Box onClick={this.handleReset} className="reset-btn" style={{ textDecoration: "underline", cursor: "pointer" }}>Clear Filter</Box>

      </div>

      <div style={{ display: "flex" }}>
        <MenuItem  >
          <Grid item sm={12}  >
            <InputLabel className="inputLabel">Role*</InputLabel>
            <Select

              labelId="demo-simple-select-label"
              id="demo-simple-select"

              renderValue={
                this.state.role !== "" ? undefined : () => <Placeholder>Select Role</Placeholder>
              }
              value={this.state.role}
              name="role"
              displayEmpty

              onChange={this.handleChange}
              variant="outlined"
              required
              // fullWidth
              style={{ height: "40px", textAlign: "left", width: "15vw" }}
            >

              {this.state.roleData && this.state.roleData.map((item: any) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}

            </Select>
          </Grid>
        </MenuItem>
        <MenuItem >
          <Grid item sm={12}>
            <InputLabel className="inputLabel">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"

              renderValue={
                this.state.isStatus !== "" ? undefined : () => <Placeholder>Select Status</Placeholder>
              }
              value={this.state.isStatus}
              name="isStatus"
              displayEmpty

              onChange={this.handleChange}
              variant="outlined"
              required
              fullWidth
              style={{ height: "40px", textAlign: "left", width: "15vw" }}
            >

              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">
                Inactive
              </MenuItem>


            </Select>
          </Grid>
        </MenuItem>
      </div>
      <div style={{ display: "flex" }}>
        <MenuItem >
          <Grid item sm={12}>
            <InputLabel className="inputLabel">Workspace</InputLabel>
            <Select
            className="selectedWorkspace_filter"
              labelId="mutiple-select-label"
              multiple
              value={this.state.selectedWorkspace}
              onChange={(e) => this.handleWorkspaceChange(e)}
              renderValue={(selected: any) => {
                return this.state.selectedWorkspace?.map((item: any) => item?.attributes?.name).join(",");
              }}
              // renderValue={(selected) => selected.join(", ")}
              variant="outlined"
              required
              fullWidth
              style={{ height: "40px", textAlign: "left", width: "15vw" }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}

            >

              <MenuItem
                value="all"
              >
                <ListItemIcon>
                  <Checkbox

                    checked={
                      this.state.workspaceData?.length > 0 &&
                      this.state.selectedWorkspace?.length === this.state.workspaceData?.length
                    }
                    indeterminate={
                      this.state.selectedWorkspace?.length > 0 && this.state.selectedWorkspace?.length < this.state.workspaceData?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText

                  primary="Select All"
                />
              </MenuItem>
              {this.state.workspaceData?.map((option: any) => (
                <MenuItem key={option.id} value={option} >
                  <ListItemIcon>
                    <Checkbox checked={this.state.selectedWorkspace.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option.attributes.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </MenuItem>
        <MenuItem >
          <Grid item sm={12}>
            <InputLabel className="inputLabel">Assigned Client</InputLabel>
            <Select
            className="clientDatalist"
              labelId="mutiple-select-label"
              multiple
              value={this.state.assignedClient}
              onChange={(e) => this.handleClientChange(e)}
              renderValue={(selected: any) => {
                return this.state.assignedClient?.map((item: any) => item.name).join(",");
              }}

              variant="outlined"
              required
              fullWidth
              style={{ height: "40px", textAlign: "left", width: "15vw" }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}

            >
              <MenuItem
                value="all"

              >
                <ListItemIcon>
                  <Checkbox

                    checked={
                      this.state.dataAssignedClientList?.length > 0 &&
                      this.state.assignedClient.length === this.state.dataAssignedClientList?.length
                    }
                    indeterminate={
                      this.state.assignedClient.length > 0 && this.state.assignedClient.length < this.state.dataAssignedClientList?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText

                  primary="Select All"
                />
              </MenuItem>
              {this.state.dataAssignedClientList?.map((option: any) => (
                <MenuItem key={option.id} value={option} >
                  <ListItemIcon>
                    <Checkbox checked={this.state.assignedClient.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} className="client_headingmenu" />
                </MenuItem>
              ))}
            </Select>

          </Grid>

        </MenuItem>
      </div>
      <div style={{ display: "flex" }}>
        <MenuItem >
          <Grid item sm={12}>
            <InputLabel className="inputLabel">Assigned Subclient</InputLabel>
            <Select
            className="assignedsubclient"
              labelId="mutiple-select-label"
              multiple
              value={this.state.assignedSubclient}
              onChange={(e) => this.handleSubClientChange(e)}
              renderValue={(selected: any) => {
                return this.state.assignedSubclient?.map((item: any) => item.name).join(",");
              }}

              variant="outlined"
              required
              fullWidth
              style={{ height: "40px", textAlign: "left", width: "15vw" }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}

            >
              <MenuItem
                value="all"

              >
                <ListItemIcon>
                  <Checkbox

                    checked={
                      this.state.dataSubClientList?.length > 0 &&
                      this.state.assignedSubclient?.length === this.state.dataSubClientList?.length
                    }
                    indeterminate={
                      this.state.assignedSubclient?.length > 0 && this.state.assignedSubclient?.length < this.state.dataSubClientList.length
                    }
                  />
                </ListItemIcon>
                <ListItemText

                  primary="Select All"
                />
              </MenuItem>
              {this.state.dataSubClientList?.map((option: any) => (
                <MenuItem key={option.id} value={option} >
                  <ListItemIcon>
                    <Checkbox checked={this.state.assignedSubclient.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} className="client_headingmenu" />
                </MenuItem>
              ))}
            </Select>


          </Grid>

        </MenuItem>

      </div>

      <div style={{ padding: "10px", justifyContent: "center", gap: "10px", display: "flex" }}>
        <Button style={{ backgroundColor: "grey" }} onClick={this.handleFilterClose} className="btn-cancelmenu">Cancel</Button>
        <Button className={localStorage.getItem('user_type')=='Superadmin'?"btn-addmenu":"btn-applyadminaddmenu"} onClick={this.handleApply} disabled={!this.state.isLoadedTrue}>Apply</Button>
      </div>


    </Menu>
  )
  clientNameDisplay=(data:any)=>{
    if (data) {
      const charLength = 10;
      if (data.length > 10) {
        const processedString = data.substring(0, charLength).concat("..");
        return processedString.length <= 11 ? data : processedString;
      }
    }
    return data;
  }
  returnTableCellOrNAAssigned = (value: string) => {

    if (!value || value === 'not found'||null) return 'NA';
    else {
      const charLength = 10;
      if (value.length > 10) {
        const processedString = value.substring(0, charLength).concat("..");
        return (
          <Tooltip title={value} aria-label={`tooltip-${value}`}>
                <Typography noWrap={true} className="toolTipTypography"> {processedString.length <= 11 ? value : processedString}</Typography>
          </Tooltip>
        )
       
      }
      
    }
    return value;
    
  }
  returnTableCellOrNA = (value: string) => {

    if (!value || value === 'not found'||null) return 'NA';
    else return value
    
  }
showTableCellData=(item:any)=>(
  <>
   <TableCell className="tablecell tabledata">
             
                  
             {this.returnTableCellOrNAAssigned(item.attributes.workspace_assign?.name)}
         
         </TableCell>
         <TableCell className="tablecell tabledata">     
           {item.attributes.client === null ? "NA" :<> <Tooltip title={item.attributes.client?.client_name} aria-label={`tooltip-${item.attributes.client.client_name}`}><Typography noWrap={true} className="toolTipTypography">{this.clientNameDisplay(item.attributes.client.client_name)}</Typography></Tooltip></>}
           </TableCell>
         <TableCell className="tablecell tabledata">{item.attributes.sub_client === null ? "NA" :<> <Tooltip title={item.attributes.sub_client.team_title} aria-label={`tooltip-${item.attributes.sub_client.team_title}`}><Typography noWrap={true} className="toolTipTypography">{this.clientNameDisplay(item.attributes.sub_client.team_title)}</Typography></Tooltip></> }</TableCell>
         <TableCell className="tablecell optiontable" >{item?.attributes?.status === "Approved" && <MoreHorizIcon onClick={(e) => this.handleMore(item.id, e)}  className="moreoptions"/>}</TableCell>
  </>
)
  tableUsermangement = () => {
    if (this.state.usersData?.length) {
      return this.state.usersData?.slice(this.state.pageNo * this.state.rowsPerPage, this.state.pageNo * this.state.rowsPerPage + this.state.rowsPerPage).map((item: any) => {
        return (
          <TableBody>
            <TableRow>
              <TableCell className="tablecell tabledata" >{item?.slNo}</TableCell>
              <TableCell className="tablecell tabledata">
              <Tooltip title={item.attributes?.first_name} aria-label={`tooltip-${item.attributes?.first_name}`}>
                <Typography noWrap={true} className="toolTipTypography">
                  {this.clientNameDisplay(item.attributes?.first_name)}
                </Typography>
              </Tooltip>
                </TableCell>
              <TableCell className="tablecell tabledata">
              <Tooltip title={item.attributes?.last_name} aria-label={`tooltip-${item.attributes?.last_name}`}>
                <Typography noWrap={true} className="toolTipTypography">
                  {this.clientNameDisplay(item?.attributes?.last_name)}
                </Typography>
              </Tooltip></TableCell>
              <TableCell className="tablecell tabledata">{moment(item.attributes?.date_of_birth)?.format("DD/MM/YYYY")}</TableCell>
              <TableCell className="tablecell tabledata"> {(item.attributes.full_phone_number.length<=1)?"NA":"+" + item.attributes?.full_phone_number}</TableCell>
              <TableCell className="tablecell tabledata">{this.returnTableCellOrNA(item.attributes?.designation)}</TableCell>
              <TableCell className="tablecell ">{this.returnTableCellOrNA(item.attributes?.role_id)}</TableCell>
              <TableCell className="tablecell"> <Tooltip title={item.attributes?.email} aria-label={`tooltip-${item?.attributes?.email}`}>
                <Typography noWrap={true} className="toolTipTypography">
                  {this.clientNameDisplay(item?.attributes?.email)}
                </Typography>
              </Tooltip>
                </TableCell>
              <TableCell className="tablecell">{item?.attributes?.joining_date === null ? "NA" : moment(item?.attributes?.joining_date)?.format("DD/MM/YYYY")}</TableCell>
              <TableCell className="tablecell tabledata">{item.attributes.workspace === "not found" ? "NA" :<>
              <Tooltip title={item.attributes.workspace?.name} aria-label={`tooltip-${item.attributes.workspace?.name}`}>
                <Typography noWrap={true} className="toolTipTypography">
                  {this.clientNameDisplay(item.attributes.workspace?.name)}
                </Typography>
              </Tooltip></> }</TableCell>
             {this.showTableCellData(item)}
            </TableRow>
          </TableBody>
        )
      }
      )
    }
  }

  render() {


    return (
      <>

        {this.state.isConfirm && this.confirmDelete()}
        {this.getNoDataViewForSearch()}
        {this.state.genericModalOpen &&
          <GenericModal
            heading={this.state.isEditMode ? "Edit User" : "Add user"}
            isOpen={this.state.genericModalOpen}
            closeModal={this.handleClose}
            modalBody={this.modalBody}
            handleAction={this.handleAction}
            isEditMode={this.state.isEditMode}
            handleButton={this.state.isEditMode ? "Save" : "Add"}
          //  dataRoles={this.state.roleData}
          />
        }
        {this.state.isSuccessModal &&
          <GenericSuccessModal
            isOpen={this.state.isSuccessModal}
            succesModalBody={this.successErrModalBody}
            modalVarient={this.state.modalVarient}
            close={this.modalClose}
          />
        }


        {this.subUserItem()}
        {this.activeMenuItem()}
        {this.roleMenuItem()}
        <Box
          sx={{

            height: "100%",
            width: "85vw",
            top: "50px",
            right: 0,
            boxSizing: "border-box",
            padding: "50px 20px 10px 20px",
            position: "absolute",

            // spacing:2
          }}
          style={{ backgroundColor: "#e8e8e8" }}
          justifyContent="space-between"
          className="maintablehead"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              User Management
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",

              }}
            >
              {this.state.appliedFilter?

              <div style={{ display: "flex", alignItems: "center" }} className="filter_icon">
                {this.state.appliedFilter &&
                <img src={darkFilter} style={{ width: 22, height: 22, marginRight: "15px" , cursor:"pointer"}} onClick={this.handleFilterClick}  />}
              </div>
              : <div style={{ display: "flex", alignItems: "center" }} className="filter_icon">
              <img src={filter} style={{ width: 30, height: 30, marginRight: "15px" ,cursor:"pointer"}} onClick={this.handleFilterClick} />

            </div>
  }

              <SearchBar
                placeholder="Search User"
                value={this.state.search}
                onChange={this.requestSearch}
                onCancelSearch={this.cancelSearch}
                disabled={!this.state.isLoadedTrue}
              />

              <Button
                className= {localStorage.getItem('user_type')=='Superadmin'?"btn-adduser":"adminbtn-adduser"}
                onClick={this.handleOpen}
              >
                Add User
              </Button>
            </div>
          </Box>
          {
            !this.state.initialTableLoad ?
              <>
                <div style={{ overflowX: 'scroll' }} className="tableuserdata">
                  <Table stickyHeader aria-label="sticky table" className="usermanagement-table"  >
                    <TableHead className="maintablehead">
                      <TableRow>
                        <TableCell className="tableheader">S.no</TableCell>
                        <TableCell className="tableheader">First Name</TableCell>
                        <TableCell className="tableheader">Last Name</TableCell>
                        <TableCell className="tableheader">Birthdate</TableCell>
                        <TableCell className="tableheader"> Contact number</TableCell>
                        <TableCell className="tableheader">Designation</TableCell>
                        <TableCell className="tableheader">Role</TableCell>
                        {/* <TableCell>Description</TableCell> */}
                        <TableCell className="tableheader">Offical Email id</TableCell>
                        <TableCell className="tableheader">Joining Date</TableCell>
                        <TableCell className="tableheader">Workspace</TableCell>
                        <TableCell className="tableheader">Assigned Workspace</TableCell>
                        <TableCell className="tableheader">Assigned Client</TableCell>
                        <TableCell className="tableheader">Assigned Subclient</TableCell>

                        <TableCell className="tableheader  optiontable" ></TableCell>
                      </TableRow>
                    </TableHead>


                    {this.tableUsermangement()}

                  </Table>
                  {this.state.usersData?.length > 0 ? null : <p className="norecord">No Data Found</p>
                  }
                </div>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={this.state.usersData?.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.pageNo}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}

                />
              </>
              :
              <Box className="progres">  <CircularProgress size={50} /></Box>
          }
        </Box>

      </>
    );
  }
}

const webStyle = {
  parent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "fit-content",
    position: "relative",
    backgroundRepeat: "no-repeat",
    // height: "fit-content",
  },
  boxes: {
    marginTop: "10px",
  },
  delteUserBtn: {
    fontFamily: "sans-serif",
    fontWeight: "bold",
    fontSize: 16,
  } as React.CSSProperties,
  toolTipTypography: {
    // flexWrap: "nowrap",
   
    // fontSize: "14px",
    // fontFamily: "sans-serif",
    
} as React.CSSProperties,


};

//Customizable Area End
