import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSONBase = require("../../../framework/src/config");
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react'
const chartRef = React.createRef()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
    analyticsData: any;
    optionspie: any;
    bardataclientwise: any;
    bardatateamwise:  any;
    bardataoverdue: any;
    selecteddate: any;
    dateRange: any;
    from: any;
    to: any;
    baroptionclientwiseAdmin: any;
    baroptionclientwiseNodataAdmin: any;
    baroptionteamwise: any;
    baroptionteamwiseNodataAdmin: any;
    baroptionoverdueAdmin: any;
    baroptionoverdueNodataAdmin: any;
    TLdoughnutChart: any;
    TLDoughnutChartOptions: any;
    loader: any;
    totalNoOfProjectsTypewise: any;
    totalProjectsTypewise: any;
    totalProjectsClientWise: any;
    totalProjectsStatusWise: any;
    noOfErrorsClientWise: any;
    overdueClientWise: any;
    drilldownData: any;
    myDataCount: any;
    drilldowntotalNoOfProjectsTypewise: any;
    selectedData: any;
    analyticsDatatypewise: any;
    analyticsDatastatuswise: any;
    totalProjectsTypewiseanalytics: any;
    totalProjectsStatuswiseanalytics: any;
    clientValue: any;
    chartError : any;
    errorStatement: any;
    myDatacountBarteamwise: any;
    myDatacountOverdue: any;
    activeBarIndex: any;
    OtherErrorStatement: any;
    tokenError: any;
    isSuccessModal: any;
    drilldownErrorStat: any;
    overdueDataArray: any;
    buttondisableLeftClient: any;
    buttondisableLeftError: any;
    buttondisableLeftOverdue: any;
    buttondisableRightClient: any;
    buttondisableRightError: any;
    buttondisableRightOverdue: any;
    ZoomData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminAnalyticsController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  userGetApiCallId: any;
  analyticsWorkspaceApiCallId :any;
  analyticsDetailsApiCallId: any;
  analyticsDetailsfullsizeApiCallId: any;
  _isMounted: any;
    chartRef: any;
    chartReference: any;
    chartReferenceTeamwise: any;
    chartReferenceOverdue: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
      Chart.register(ChartDataLabels);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      selecteddate: "",
      dateRange: "",
      from: JSON.parse(sessionStorage.getItem("analyticsData") || '{}').from || "",
      to: JSON.parse(sessionStorage.getItem("analyticsData") || '{}').to || "",
      loader:true,
      chartError :false,
      errorStatement: "",
      activeBarIndex: 0,
      totalNoOfProjectsTypewise: "",
      totalProjectsTypewise: [],
      totalProjectsStatuswiseanalytics: [],
      totalProjectsTypewiseanalytics: [],
      totalProjectsClientWise: [],
      totalProjectsStatusWise: [],
      noOfErrorsClientWise: [],
      overdueClientWise: [],
      drilldownData: {},
      myDataCount :[],
      myDatacountBarteamwise: [],
      myDatacountOverdue: [],
      clientValue: "",
      tokenError: false,
      isSuccessModal: false,
      OtherErrorStatement: "",
      drilldownErrorStat: "",
      overdueDataArray: [],
      buttondisableLeftClient: true,
      buttondisableLeftError: true,
      buttondisableLeftOverdue: true,
      buttondisableRightClient: false,
      buttondisableRightError: false,
      buttondisableRightOverdue: false,
      analyticsData : {},
      analyticsDatastatuswise: {},
      analyticsDatatypewise: {},
      TLdoughnutChart : {
        datasets: [
          {
            data: [10, 10, 12, 20, 16],
            backgroundColor: [
              '#4056CF',
              '#FF6699',
              '#FD890A',
              '#0AC20E',
              '#7185EF',
            ],
            radius: 120,
            innerWidth: 120,
            innerHeight:120,
          },
        ],
      },

      TLDoughnutChartOptions: {
        elements: {
          arc: {
            offset: 10
          }
        },
        responsive: true, 
        maintainAspectRatio: false, 
        plugins: {
          labels:{
            display: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      },
      optionspie: {
        elements: {
          arc: {
            offset: 10
          }
        },
        responsive: true, 
        maintainAspectRatio: false, 
        plugins: {
          labels:{
            display: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold"
            },
            formatter: (value: any, context: any) => {
              return value;
            },
          },
        },
      },
      baroptionclientwiseAdmin: {
        onClick: this.drillDownFunction,
        barThickness: 38, 
        borderWidth: 1,
        plugins: {
        legend: {
          display: false
        },
        datalabels: {
          font: {
            weight: "bold",
            color: "black"
          },
          display: true,
          color: "black",
      }
      },
        scales: {
          y: {
            max: this.maxValueClientwise,
            min: 0,
            stepSize: 50,
            beginAtZero: true,
            title: {
              text: "No of projects  ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              display: true,
            },
            border: {
              dash: 10,
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 2,
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              },
              callback: function(this: any, value: any){
                return this.getLabelForValue(value).substr(0,4).concat('...')
              }
            },
            title: {
              display: true,
              text: "Client folder/ subfolder - Template  ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
          }
        },
        layout: {
          padding: {
            top: 15,
          }
        }
      },

      baroptionclientwiseNodataAdmin: {
        onClick: this.drillDownFunction,
        plugins: {
          datalabels: {
            display: true,
            font: {
              color: "black",
              weight: "bold"
            },
            color: "black",
        },
        legend: {
          display: false
        },
        },
        borderWidth: 1,
        barThickness: 38, 
        scales: {
          y: {
            beginAtZero: true,
            title: {
              text: "No of projects   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              display: true,
              padding: 10,
              align: "start",
            },
            stepSize: 5,
            border: {
              dash: 10,
            },
            grid: {
              borderDash: [5],
              display: true,
              lineWidth: 1,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            beginAtZero: true,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            ticks:{
              minRotation: 20,
              font: {
                weight: "600"
              },
              maxRotation: 20,
            },
            grid: {
              display: false
            },
            max: 3,
          }
        },
      },
      bardataclientwise: {},
      baroptionteamwise: {
        scales: {
          y: {
            min: 0,
            max: this.maxValueErrorwise,
            stepSize: 50,
            beginAtZero: true,
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            },
            title: {
              align: "start",
              padding: 10, 
              display: true,
              text: "No of errors   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start", 
            },
          },
          x: {
            min: 0,
            max: 2,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks:{
              callback: function(this: any, value: any){
                return this.getLabelForValue(value).substr(0,4).concat('...')
              },
              font: {
                weight: "600"
              },
              minRotation: 20,
              maxRotation: 20,
            }
          }
        },
        borderWidth: 1,
        barThickness: 38, 
        layout: {
          padding: {
            top: 15,
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold",
              color: "black"
            }
          }
        },
      },

      baroptionteamwiseNodataAdmin: {
        plugins: {
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold",
              color: "black"
            }
          },
          legend: {
            display: false
          },
        },
        borderWidth: 1,
        barThickness: 38, 
        scales: {
          y: {
            stepSize: 5,
            beginAtZero: true,
            title: {
              display: true,
              text: "No of errors   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,  
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 2,
            beginAtZero: true,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              }
            }
          }
        },
      },

      baroptionoverdueAdmin: {
        borderWidth: 1,
        barThickness: 38, 
        scales: {
          y: {
            min: 0,
            max: this.maxValueOverduewise,
            stepSize: 50,
            beginAtZero: true,
            title: {
              display: true,
              text: "No of files   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,  
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 2,
            beginAtZero: true,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template  ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              },
              callback: function(this: any, value: any){
                return this.getLabelForValue(value).substr(0,4).concat('...')
              }
            }
          }
        },
        layout: {
          padding: {
            top: 15,
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold",
              color: "black"
            }
          }
        },
      },

      baroptionoverdueNodataAdmin:  {
        scales: {
          y: {
            title: {
              display: true,
              text: "No of files   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,  
            },
            stepSize: 5,
            beginAtZero: true,
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              }
            },
            min: 0,
            max: 3,
            beginAtZero: true,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
          }
        },
        borderWidth: 1,
        barThickness: 38, 
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            color: "black",
            display: true,
            font: {
              weight: "bold",
              color: "black"
            }
          }
        },
      },

      bardatateamwise: {},
      bardataoverdue: {},
      drilldowntotalNoOfProjectsTypewise: "",
      selectedData: false,
      ZoomData: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

handleErrorchart = (responseJson: any) => {

  if(responseJson?.errors) {
    if(responseJson?.errors[0]?.token){
      console.clear()
      console.log("Calling error functionality")
      this.setState({OtherErrorStatement: responseJson?.errors[0]?.token})
      this.setState({tokenError: true})
      this.setState({isSuccessModal: true})
      this.setState({loader: false})
    }
    else{
    this.setState({chartError: true})
    this.setState({errorStatement: responseJson?.errors[0]})
    this.setState({loader: false})
    }
  }
}
  analyticsGraphDetails = () => {
    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type" : "application/json",
    };
  
    const attrs = {
      "start_date": this.state.from,
      "end_date": this.state.to,
    };
  
    const analytics = {
      filter: attrs,
    };
  
    const httpBody = {
      analytics: analytics,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.analyticsDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_dashboard/analytics/report");
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }

  analyticsGraphDetailsDate = (reqType: any, data1: any, data2: any) => {
    if(reqType == "date"){
      this.setState({from: data1})
      this.setState({to: data2})
    }
    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type" : "application/json",
    };
  
    const attrs = {
      "start_date": data1,
      "end_date": data2,
    };
  
    const analytics = {
      filter: attrs,
    };
  
    const httpBody = {
      analytics: analytics,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.analyticsDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_dashboard/analytics/report");
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }

  displayLabelName = (data: any) => {
    data?.map((item: any) => {
      if(data) {
        return data;
      }
    })
    
  }
  maxValueClientwise = () => {
    let maxLimit = Math.max(...this.state.myDataCount)
      return ((Math.round(maxLimit / 100)) *100 + 100);
  }
  
  maxValueErrorwise = () => {
    let maxLimit = Math.max(...this.state.myDatacountBarteamwise)
      return ((Math.round(maxLimit / 100)) * 100 + 100);
  }
  
  maxValueOverduewise = () => {
    let maxLimit = Math.max(...this.state.overdueDataArray)
      return ((Math.round(maxLimit / 100)) * 100 + 100);
  }

  handleDataForChart = (responseJson: any) => {
    this.setState({totalNoOfProjectsTypewise: responseJson?.result?.total_projects_type_wise_all_clients})
    this.setState({totalProjectsTypewise: responseJson?.result?.total_projects_type_wise})
    this.setState({noOfErrorsClientWise: responseJson?.result?.no_of_errors_client_wise})
    this.setState({totalProjectsClientWise: responseJson?.result?.total_project_client_wise})
    this.setState({drilldownData: responseJson?.result?.total_project_client_wise})
    this.setState({overdueClientWise: responseJson?.result?.overdue_client_wise})

    let piedata = this.state.totalProjectsTypewise?.map((item: any) => {
      if(item.count > 0 ) {return(item.count)}
    })
    let piecolor = this.state.totalProjectsTypewise?.map((item: any) => {return(item.color_code)})
    let piedatalabel = this.state.totalProjectsTypewise?.map((item: any) => {return(item.type)})
    let dataset = [
      {
        data: piedata,
        backgroundColor: piecolor,
        radius: 120,
        innerWidth: 120,
        innerHeight: 120,
      }
    ]
    let analyticsData = {
      labels: piedatalabel,
      datasets: dataset
    }
    this.setState({analyticsData: analyticsData})
    let barclientWiseLabels = this.state.totalProjectsClientWise?.map((item: any) => {return (this.capitalizeFirstLetter(item?.client_name))})


    let barclientWisedata = this.state.totalProjectsClientWise?.map((item: any) => {return(item.count)})
    this.setState({myDataCount: barclientWisedata})
    let bardataLabels =  {
      anchor: 'end',
      padding: -2,
      align: 'end',
    }
    let backgroundColor = [
      '#00FFFF', '#EADDCA', '#EE4B2B', '#AAFF00', '#F2D2BD', '#89CFF0', 
      '#E1C16E', '#800020', '#E4D00A', '#FFB6C1', '#7393B3', '#FFFF8F', 
      '#E97451', '#50C878', '#DA70D6', '#0047AB', '#FAD5A5', '#D70040',
      '#228B22', '#F8C8DC', '#00008B', '#FFC000', '#954535', '#90EE90', 
      '#673147', '#40e0d0']

    let bardatasetclientwise = [
      {
        // data: barclientWisedata,
        data: this.state.myDataCount,
        datalabels: bardataLabels,
        backgroundColor: backgroundColor,
        barPercentage: 0.5,
      }
    ] 

    let bardataclientwise = {
      labels: barclientWiseLabels,
      datasets: bardatasetclientwise,
    }
    this.setState({bardataclientwise: bardataclientwise})

    let barTeamWiseLabels = this.state.noOfErrorsClientWise?.map((item: any) => {return (this.capitalizeFirstLetter(item.client_name))})

    let barTeamWiseData = this.state.noOfErrorsClientWise?.map((item: any) => {if (item.count > 0 ) {return(item.count)}})
    this.setState({myDatacountBarteamwise: barTeamWiseData})
    let bardatasetTeamwise = [
      {
        data: barTeamWiseData,
        datalabels: bardataLabels,
        backgroundColor: backgroundColor,
        barPercentage: 0.5,
      }
    ]

    let bardatateamwise = {
      labels: barTeamWiseLabels,
      datasets: bardatasetTeamwise
    }

    this.setState({bardatateamwise: bardatateamwise})

    let bardataOverdueLabels = this.state.overdueClientWise?.map((item: any) => {return(this.capitalizeFirstLetter(item.client_name))})
    this.setState({myDatacountOverdue: bardataOverdueLabels?.length})
    let bardataOverdueDatapd = this.state.overdueClientWise?.map((item: any) => {if(item.overdue_production > 0) {return(item.overdue_production)}})
    let bardataOverdueDataqc = this.state.overdueClientWise?.map((item: any) => { if(item.overdue_qc > 0) {return(item.overdue_qc)}})
    let bardatapdmax = this.state.overdueClientWise?.map((item: any) => { return(item.overdue_production)})
    let bardataqcmax = this.state.overdueClientWise?.map((item: any) => { return(item.overdue_qc)})
    let bardataoverduepdqc = bardatapdmax?.concat(bardataqcmax)
    this.setState({overdueDataArray: bardataoverduepdqc})
    let bardatasetOverdue = [
      {
        grouped: true,
        label: "overdue files for production",
        data: bardataOverdueDatapd,
        backgroundColor: "#1F51FF",
        datalabels: bardataLabels,
        borderWidth: 1,
        borderRadius: 5,
      },
      {
        grouped: true,
        label: "overdue files for qc",
        data: bardataOverdueDataqc,
        backgroundColor: "#FFBF00",
        datalabels: bardataLabels,
        borderWidth: 1,
        borderRadius: 5,
      }
    ]

    let bardataoverdue = {
      labels: bardataOverdueLabels,
      datasets: bardatasetOverdue
    }


    this.setState({bardataoverdue: bardataoverdue})
    this.setState({loader: false})

  }

  capitalizeFirstLetter(str: any){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }

  handleDatePicker = (a: any) => {
    if (a == null){
      this.setState({
        dateRange: null,
        from: "",
        to: "",
      })
      return
    }
    
    this.setState({
      dateRange:a,
      from: new Date(a[0]).toLocaleDateString("en-GB"),
      to: new Date(a[1]).toLocaleDateString("en-GB"),
    })
  }

  drillDownFunction = async (click: any, myDataCount: any) => {
    if(myDataCount?.length > 0) {
    if(myDataCount[0]) {
      let x = myDataCount[0].index
      let dataValue = await this.state.totalProjectsClientWise[x]
      let clientnameValue = this.state.totalProjectsClientWise[x]?.client_name
      this.setState({clientValue: clientnameValue})
      this.setState({drilldowntotalNoOfProjectsTypewise: dataValue?.type_wise_all_clients})
      let drilldowntypewisedata = dataValue?.type_wise   
      this.setState({totalProjectsTypewiseanalytics: dataValue?.type_wise}) 
      let piedatatype_wise = drilldowntypewisedata?.map((item: any) => {
        if(item.count > 0 ) {return(item.count)}
      })
      let piecolortype_wise = drilldowntypewisedata?.map((item: any) => {return(item.color_code)})
      let piedatavaluetype_wise = drilldowntypewisedata?.map((item: any) => {return(item.type)})
      let datasettype_wise = [
        {
          data: piedatatype_wise,
          backgroundColor: piecolortype_wise,
          radius: 120,
          innerWidth: 120,
          innerHeight: 120,
        }
      ]
      let analyticsDatatype_wise = {
        labels: piedatavaluetype_wise,
        datasets: datasettype_wise
      }

      this.setState({analyticsDatatypewise: analyticsDatatype_wise})

      let drilldownstatuswisedata = dataValue?.status_wise 
      this.setState({totalProjectsStatuswiseanalytics: dataValue?.status_wise})
      let piedatastatus_wise = drilldownstatuswisedata?.map((item: any) => {
        if(item.count > 0 ) {return(item.count)}
      })
      let piecolorstatus_wise = drilldownstatuswisedata?.map((item: any) => {return(item.color_code)})
      let piedatalabelstatus_wise = drilldownstatuswisedata?.map((item: any) => {return(item.status)})
      let datasetstatus_wise = [
        {
          data: piedatastatus_wise,
          backgroundColor: piecolorstatus_wise,
          radius: 120,
          innerWidth: 120,
          innerHeight: 120,
        }
      ]
      let analyticsDatastatus_wise = {
        labels: piedatalabelstatus_wise,
        datasets: datasetstatus_wise
      }
      this.setState({analyticsDatastatuswise: analyticsDatastatus_wise})
    }
    this.setState({selectedData: true})
  }
  // else {
  //   this.setState({drilldownErrorStat: "Drilldown functionality error"})
  // }
  }

  // handleDataBarChart =(data: any) => {
  //   localStorage.setItem("graph_name", data)
  // }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.state.dateRange && this.state.dateRange !== prevState.dateRange) {
      this.setState({ loader: true });
      const fromDate = new Date(this.state.dateRange[0]).toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
      const toDate = new Date(this.state.dateRange[1]).toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
      sessionStorage.setItem("FromDate", fromDate)
      sessionStorage.setItem("ToDate", toDate)
      this.analyticsGraphDetailsDate("date",fromDate,toDate);
    }else if(!this.state.dateRange && this.state.dateRange !== prevState.dateRange){
      this.setState({ loader: true });
      sessionStorage.setItem("FromDate", "")
      sessionStorage.setItem("ToDate", "")
      this.analyticsGraphDetailsDate("date",null,null);
    }
  }

  previousDataExists = () => {
    let to: any;
    let from: any;
    to = sessionStorage.getItem("ToDate")
    from = sessionStorage.getItem("FromDate")
    const fromDate = new Date(from)
    const toDate = new Date(to)
    if(to == "" || from == "" ){
      this.setState({
        dateRange:null,
        from: "",
        to: ""
      });
    }else{
      const datearr = [fromDate, toDate]
      this.setState({
        dateRange: datearr,
        from: from,
        to: to
      });
    }
  }
  bardataoverduestart = (startScale: any, chartConfig: any) => {
    console.log(startScale, "startvaloverdue")
    if (startScale <= 0) {
      chartConfig.x.min = 0;
      chartConfig.x.max = 2;
      this.setState({buttondisableLeftOverdue: true})
    }
    else {
      this.setState({buttondisableLeftOverdue: false})
    }
  }
  
  bardataoverdueend = (endScale: any, chartConfig: any) => {
    console.log(endScale, "endscaleoverdue")
    if(endScale >= this.state.myDatacountOverdue) {
      chartConfig.x.min = this.state.myDatacountOverdue - 4;
      chartConfig.x.max  = this.state.myDatacountOverdue - 1;
      this.setState({buttondisableRightOverdue: true})
    }
    else {
      this.setState({buttondisableRightOverdue: false})
    }
  }

  bardatateamwisestart = (startScale: any, chartConfigOp: any)  => {
    console.log(startScale, "startteam")
    if (startScale <= 0) {
      chartConfigOp.min = 0;
      chartConfigOp.max = 2;
      this.setState({buttondisableLeftError: true})
    }
    else {
      this.setState({buttondisableLeftError: false})
    }
  }
  
  bardatateamwiseend = (endScale : any, chartConfigOp : any) => {
    console.log(endScale, 'endteam')
    if(endScale >= this.state.myDatacountBarteamwise.length) {
      chartConfigOp.min = this.state.myDatacountBarteamwise.length - 2;
      chartConfigOp.max  = this.state.myDatacountBarteamwise.length;
      this.setState({buttondisableRightError: true})
    }
    else {
      this.setState({buttondisableRightError: false})
    }
  }

  bardatastart = (startScale: any, chartConfigData: any) => {
    console.log(startScale, "startva")
    if (startScale <= 0) {
      chartConfigData.scales.x.min = 0;
      chartConfigData.scales.x.max = 2;
      this.setState({buttondisableLeftClient: true})
    }
    else {
      this.setState({buttondisableLeftClient: false})
    }
  }
  
  bardataend = (endScale: any, chartconfigData: any) => {
    console.log(endScale, "endva")
    if(endScale >= this.state.myDataCount.length) {
      chartconfigData.scales.x.min = this.state.myDataCount.length - 2;
      chartconfigData.scales.x.max  = this.state.myDataCount.length;
      this.setState({buttondisableRightClient: true})
    }
    else {
      this.setState({buttondisableRightClient: false})
    }
  }

  bardata = async (start: any, end: any) => {
    const chart = await this.chartReference;
    const startScale = chart.config?.options?.scales.x.min + start;
    const endScale = chart.config?.options?.scales.x.max + end;
    chart.config.options.scales.x.min = startScale;
    chart.config.options.scales.x.max = endScale;
    this.bardatastart(startScale, chart.config.options)
    this.bardataend(endScale, chart.config.options)
    chart.update()
    
  }
  
  bardatateamwise = async (start: any, end: any) => {
    const chart = await this.chartReferenceTeamwise;
    const startScale = chart.config?.options?.scales.x.min + start;
    const endScale = chart.config?.options?.scales.x.max + end;
    chart.config.options.scales.x.min = startScale;
    chart.config.options.scales.x.max = endScale;
    this.bardatateamwisestart(startScale, chart.config.options.scales.x)
    this.bardatateamwiseend(endScale, chart.config.options.scales.x)
    chart.update()
    
  }
  
  bardataoverdue = async (start: any, end: any) => {
    const chart = await this.chartReferenceOverdue;
    const startScale = chart.config?.options?.scales.x.min + start;
    const endScale = chart.config?.options?.scales.x.max + end;
    chart.config.options.scales.x.min = startScale;
    chart.config.options.scales.x.max = endScale;
    this.bardataoverduestart(startScale, chart.config.options.scales)
    this.bardataoverdueend(endScale,chart.config.options.scales)
    chart.update()
    
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start  
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(this.analyticsDetailsApiCallId === apiRequestCallId) {
        if(responseJson && responseJson.result) {
          this.handleDataForChart(responseJson)
        }
        else {
          this.handleErrorchart(responseJson)
        }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 }
}
