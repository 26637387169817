import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  MenuItem,
  FormControl,
  TextField,
  CircularProgress,
  Select
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AiOutlineLeft } from "react-icons/ai";
import { ImAttachment } from "react-icons/im";
import "../../KanbanBoard/src/ProjectCard.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MentionsInput, Mention } from "react-mentions";
let bottomButtom:string[] = ['File Completed']
let indexCounter = 0
import { commentProps, customMentionType, projectDataType } from "../../utilities/src/types";

import { Attachments, AttachmentsForComments, Centered, CheckListModal, Comment, LabelWithInput, LabelWithSelect, SectionHead, ShowActivity } from "../../utilities/src/KanbanComponents";

// Customizable Area End

import ProjectTaskTrackingController, {
  Props,
} from "./ProjectTaskTrackingController";

export default class ProjectTaskTracking extends ProjectTaskTrackingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  defaultValueCheck = (result: number | string, item: {associated_class_name: string}) =>{
    if(result == "NA" || result == -1)
      return ""
    else if(item.associated_class_name == "AccountBlock::Account")
      return this.binarySearchforDropDown(Number(result), this.state.dropDownOptions)
    else
      return result
  }
  
  finulentTrackingCount = 0;
  timeTrackerCount = 0;
  clientFeedbackCount = 0;
  customFieldCount = 0;

  displayButton = (value: string | string[], prefix: string = "", customStyle = {}, disabled: boolean = false) => {
      customStyle={
        fontSize: "0.7rem",
        fontWeight: "bold",
        textTransform: "none",
        padding: "5 10",
        cursor: 'pointer',
        marginRight: 10,
        height: 40,
        ...customStyle
      }
    if(typeof value == "string")
      value = [value]
    return value.map((el:string) => {
      return <Button
      style={customStyle}
      className={"sendBtn"}
      variant={"contained"}
      disabled={disabled}
      onClick={()=>{this.updateProjectDetails(el, {id :localStorage.getItem("tempValue") as string});}}
      >
        {`${prefix} ${el}`}
      </Button>
    })
  }


  CustomMention = (props: {onChange: (e:{target:{value:string}},newValue:string, newPlainTextValue:string, mentions:{id:string}[]) => unknown, value: string, placeholder: string}) => {
    const { onChange, value } = props;
  
    return (
      <MentionsInput 
      {...props}
      value={value} 
      onChange={onChange} 
      singleLine={false}
      onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
      customSuggestionsContainer={(children)=><div id ="customSuggestionsContainer">{children}</div>}
      allowSuggestionsAboveCursor={true}
      >
        <Mention
          trigger="@"
          data={this.state.mentionsData as []}
          style={{ backgroundColor: "#edab29" }}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused
          ) => (
            <div tabIndex={-1} className={`user MentionSuggestions ${focused ? 'focused' : ''}`} id={`mentionid-${index}`} >
              {focused ? this.setfocusedValue(`mentionid-${index}`)  : ""}
              {highlightedDisplay}
            </div>
          )}
          
        />
      </MentionsInput>
    );
  };

  displayFooter = () => {

    let currentValue = this.state.currentFinulentStatus

    if(currentValue == "Project in Queue")
      bottomButtom = ['Production Assigned']

    if(currentValue == "Production Assigned")
      bottomButtom = ['Production Initiated']

    if(currentValue == "Production Initiated")
      bottomButtom = ['Production Sent for QC','Production Sent for QA', 'File Completed']

    if(currentValue == "Production Sent for QC")
      bottomButtom = ['QC Initiated']

    if(currentValue == "QC Initiated")
      bottomButtom = ['File Sent for Corrections', 'Production Sent for QA','File Completed']

    if(currentValue == "Production Sent for QA")
      bottomButtom = ['QA Initiated']

    if(currentValue == "File Sent for Corrections")
      bottomButtom = ['Design Corrections Initiated']

    if(currentValue == "Design Corrections Initiated")
      bottomButtom = ['Revised File sent for QC']

    if(currentValue == "Revised File sent for QC")
      bottomButtom = ['File Sent for Corrections', 'Production Sent for QA','File Completed']
      
    if(currentValue == "QA Initiated")
      bottomButtom = ['File Completed']

    this.state.fieldData.forEach((el : {field_name: string, id: number})=>{
      if(el.field_name == "Finulent Status"){
        localStorage.setItem("tempValue", el.id.toString())
       
      }
    })

    const isDisabled = (el: string) => {

       if(this.state.finulentStatusChoices.find((str:string) => str === el) == undefined){
        return true
       }else{
        return this.state.currentFinulentStatus == "File Completed" ?  true : false
       }
    }
    
    
    if((localStorage.getItem('user_type')== "Admin" && this.props.history.location.state.prevPath == "/managertaskboard") || (localStorage.getItem('user_type') == "TL/Manager" && this.state.currentFinulentStatus == "In Progress")){
      return <Box
      width={"50%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-evenly"}
    >
      <Box fontSize={"0.7rem"} fontWeight={"bold"}>
        Move to
      </Box>
      <Box>
        <Box sx={{ minWidth: 120, bgcolor: "white" }}>
          <FormControl
            size={"small"}
            variant={"outlined"}
            fullWidth
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              defaultValue={()=>{
                return "Select"
              }}
              // label="Select"
              onChange={(e)=> this.changeFinulentStatusValue(e.target.value as string)}
            >
              <MenuItem key={"Select"} value={"Select"}>
                        {"Select"}
              </MenuItem>
              {this.state.fieldData.map((el: {field_name: string, id: number})=>{
                if(el.field_name == "Finulent Status"){
                  localStorage.setItem("tempValue", el.id.toString())
                 return this.state.finulentStatusChoices.map((e: string, index: number)=>{
                    return (
                      <MenuItem key={index} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })
                }
              })}
              
            </Select>
        </FormControl>
        </Box>
      </Box>
      <Box fontSize={"0.7rem"} fontWeight={"bold"}>
        Status
      </Box>
      <Box>
        <Button
          className={"sendBtn"}
          style={{
            fontSize: "0.7rem",
            fontWeight: "bold",
            textTransform: "none",
            padding: "5 10",
          }}
          variant={"contained"}
          onClick={()=>{this.updateProjectDetails(this.state.bottomStatusValue, {id :localStorage.getItem("tempValue") as string}); }}
          disabled={this.state.bottomStatusValue == "Select"}
          >
          Confirm
        </Button>
      </Box>
    </Box>
    }else if (localStorage.getItem('user_type') == "TL/Manager" || localStorage.getItem('user_type') == "Designer/QC/QA" || (localStorage.getItem('user_type')== "Admin" && this.props.history.location.state.prevPath == "/taskboard") ){
      console.log("running 4")
      if((this.state.currentFinulentStatus == "File Completed" 
      || this.state.currentFinulentStatus == "Query" 
      || this.state.currentFinulentStatus == "Query - File Completed")
      ){
        return null
      }else{
        return <Box
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      <Box
      style={{
        fontSize: "0.7rem",
        fontWeight: "bold",
        textTransform: "none",
        padding: "5 10",
        marginRight: 10
      }}>

        { (!bottomButtom.includes("Production Sent for QA") && !bottomButtom.includes("Production Sent for QC") && !bottomButtom.includes("File Completed")
        && !bottomButtom.includes("Revised File sent for QC"))
        && <Typography component={"span"} style={{color: 'grey', fontStyle: 'italic', fontSize: 10, marginRight: 10}} >
          *Checklist not required
          </Typography>}
        {bottomButtom.map((el:string)=>{
          return this.displayButton(el, "Move to", isDisabled(el) ? {cursor: 'default'} : {} , isDisabled(el))
        })}
      </Box>
    </Box>
      }
    }
  }

  echoInputFields = (str : "custom_field" | "finulent_tracking" | "time_tracking"| "client_feedback" ) => {
    if(str == "custom_field"){
      this.customFieldCount++
    }
    this.customFieldCount = 0
    this.finulentTrackingCount = 0
    this.timeTrackerCount = 0
    this.clientFeedbackCount = 0

    return this.state.fieldData.map((item: any) => {
      if (item[str]) {
        this.customFieldCount++
        const result = this.findValueByFieldName(item.id, this.state.projectData);
        if (item.field_type == "Yes/No") {
          return <Box 
          className={"identifier"} ><LabelWithSelect
            label={item.field_name}
            value={[{ value: "Yes" }, { value: "No" }] as unknown as {value:string, id:number, on_leave:boolean, image:string}[]}
            available={true}
            defaultValue={this.defaultValueCheck(result,item)}
            disabled={item.is_frozen || item.auto_filled}
            data={item}
            updateProjectDetails={this.updateProjectDetails}
            personID={this.findValueByFieldName(item.id, this.state.projectData)}
            currentFinulentStatus={this.state.currentFinulentStatus}
          />
          </Box >
        }

        if (item.field_type == "Dropdown") {
          if(item.field_name == "Code")
            sessionStorage.setItem("code_field_id",item.id.toString())

          return <Box   
          className={"identifier"} ><LabelWithSelect
            label={item.field_name}
            //@ts-ignore
            value={item.field_name != "Code" ? this.dropDownValueCheck(item): this.state.codeDropDownOptions}
            available={true}
            defaultValue={this.dropDownDefaultValue(result, item)}
            disabled={item.is_frozen || item.auto_filled}
            data={item}
            updateProjectDetails={this.updateProjectDetails}
            personID={this.findValueByFieldName(item.id, this.state.projectData)}
            currentFinulentStatus={this.state.currentFinulentStatus}
          />
          </Box  >
        }

        if(item.field_name == "AHJ" || item.field_name == "Utility"){
          return <Box   
          className={"identifier"} >
            <Box height={"100%"} width={"90%"} padding={'10px'}>
              <Box className={"labelFont"} marginBottom={"3%"}>
                <Box height={"32px"} style={{display:'flex', alignItems:'flex-end'}} >
                  {item.field_name}
                </Box>
              </Box>
              <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={item.field_name == "AHJ"? this.state.ahjDropDown.map((el)=>el.value) : this.state.utilityDropDown.map((el)=>el.value)}
              value={this.dropDownDefaultValue(result, item) as string}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ backgroundColor: (item.is_frozen || item.auto_filled) ? "#EFEFEF" : "" }}
                  size={"medium"}
                  fullWidth
                  id="outlined-basic"
                  disabled={item.is_frozen || item.auto_filled}
                  variant="outlined"
                  onBlur={(e)=>this.debouncerHandler(e.target.value, item, "onBlur")}
                  onFocus={(e)=>this.debouncerHandler(e.target.value, item, "onFocus")}
                />
              )}
              />
              </Box>
            </Box>
        }

        return <Box    
        className={"identifier"} ><LabelWithInput
          label={
            item.field_name
          }
          isDisable={item.is_frozen || item.auto_filled}
          value={result == "-1" ? "NA" : result}
          data={item}
          updateProjectDetails={this.updateProjectDetails}
          debouncerHandler={this.debouncerHandler}
        /></Box>
      }
    })
  }

  dropDownValueCheck = (item:projectDataType["fields"][0]) => {
    
    if(item.field_values.length){
      return item.field_values.map((i: string) => {
        return { value: i, id: i }
      })
    }
    if(item.associated_class_name == "BxBlockDashboard::TypeOfProject"){
      return this.state.typeDropDownOptions
    }

    if(item.associated_class_name == "AccountBlock::Account" && item.field_name == "Designer"){
      return this.state.designerDropDownOptions
    }

    if(item.field_name == "AHJ"){
      return this.state.ahjDropDown
    }
    
    if(item.field_name == "Utility"){
      return this.state.utilityDropDown
    }

    return this.state.dropDownOptions
  }
  ;
  
  dropDownDefaultValue = (result: string | number, item: {associated_class_name: string}) => {
    if((result == "NA" || result == -1)){
      return ""
    }
    if(item.associated_class_name == "AccountBlock::Account")
      return this.binarySearchforDropDown(result, this.state.dropDownOptions)

    if (item.associated_class_name == "BxBlockDashboard::TypeOfProject"){
      return this.binarySearchforDropDown(result, this.state.typeDropDownOptions)
    }

    if (item.associated_class_name == "BxBlockDashboard::ProjectCode"){
      return this.binarySearchforDropDown(result, this.state.codeDropDownOptions)
    }

    return result
  }
  // Customizable Area End

  render() {
    let topBottons = ['In Progress','Query','Query - File Completed']
    let output = ""
    let tags: string[] | unknown[] = []
    const handleTags = () => {
      
      if(this.props.history.location?.state?.data?.is_overdue){
        tags.push("Overdue")
      }
      if(this.props.history.location?.state?.data?.priority == "Yes"){
        tags.push("Priority")
      }
      
      const tempArr: unknown[] = [];

      if(tags.length > 0){
        tags.forEach((el,index)=>{
          if(index != 0){
            tempArr.push(<Box component={"span"} marginX={'5px'} style={{borderLeft: '1px solid red', height: '50px', opacity: 0.8}}></Box>)
          }
         tempArr.push(el)
        })
      }

      tags = tempArr
      return <Box  display={"flex"} justifyContent= {"flex-end"}>
              <Box fontSize= {"0.8rem"} paddingX={"2px"} style={{borderRadius: '0 3px 0 3px'}} bgcolor={"#FFD3D2"} border= {"1px solid red"}>{tags.map((item, index)=>{
                if(index > 0){
                  output += " | " 
                }
                return item
              })}</Box>
            </Box>
    }


    const createTextDate = (oldDate: string) => {
      const oldFromateDate = oldDate.split("/");
      const newFormatDate = new Date(
        [oldFromateDate[1], oldFromateDate[0], oldFromateDate[2]].join("/")
      ).toDateString();
      const newFormatSplittedDate = newFormatDate.split(" ");
      const [date, month, year] = [
        newFormatSplittedDate[2],
        newFormatSplittedDate[1],
        newFormatSplittedDate[3],
      ];
      const tempObj = {
        date,
        month,
        year,
      };
      return tempObj;
    };
    return (
      // Customizable Area Start
      <>
      {this.state.loader ? (
        <Box display={"flex"} justifyContent={"center"} width={"85vw"} marginLeft={"15vw"} fontFamily={"sans-serif"} marginTop={"25vh"}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box width={"85vw"} marginLeft={"15vw"} marginTop={"65px"} fontFamily={"sans-serif"}>
          {/* Nav Start */}
          <Box
            height={"8.5vh"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingLeft={"5vw"}
            paddingRight={"7vw"}

          // bgcolor={"red"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width="60%"
            >
              <Box marginRight={"3.5%"}>
                <Button
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                  variant="contained"
                >
                  <AiOutlineLeft style={{ marginRight: "5" }} /> Back
                </Button>
              </Box>

              <Box
                width={"auto"}
                display={"flex"}
                height={"100%"}
                flexDirection={"column"}
              >
                <Box
                  fontSize={"2vh"}
                  display={"flex"}
                  alignItems={"center"}
                  height={"55%"}
                >
                  {this.state.projectData.find((item: {field_name: string, id: number}) => item.field_name == "Project Name" || item.field_name == "Site Name")?.data || "NA"}
                </Box>
                <Box display={"flex"} alignItems={"center"} height={"40%"}>
                  <Box>
                    <Box component={"span"} className={"detailsText"}>
                      Project id
                    </Box>{" "}
                    <Box component={"span"} className={"detailsValue"}>
                      {this.state.projectDisplayId || "NA"}
                    </Box>
                  </Box>
                  <Box marginLeft={"2.5vw"} marginRight={"2.5vw"}>
                    <Box component={"span"} className={"detailsText"}>
                      Client
                    </Box>{" "}
                    <Box component={"span"} className={"detailsValue"}>
                      {this.state.client}
                    </Box>
                  </Box>
                  <Box>
                    <Box component={"span"} className={"detailsText"}>
                      Project Created
                    </Box>{" "}
                    <Box component={"span"} className={"detailsValue"}>
                      {createTextDate(this.state.projectDate).date +
                        " " +
                        createTextDate(this.state.projectDate).month +
                        " " +
                        createTextDate(this.state.projectDate).year}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display={'flex'} flexDirection="column" alignItems={"center"} >
              <Box
                padding={"5px"}
                borderRadius={"30px 30px 30px 30px;"}
                component={"span"}
                color={"white"}
                bgcolor={ this.props.history.location?.state?.projectStatusColors[this.state.currentFinulentStatus]}
                marginBottom={"5"}
                width={"105%"}
                textAlign={"center"}
              >
                {this.state.currentFinulentStatus}
              </Box>
              {this.state.projectPriority && handleTags()}
            </Box>
            {localStorage.getItem('user_type') != "Admin" && this.state.currentFinulentStatus != "Project in Queue" && <Box
              width={"30%"} 
              display={"flex"} 
              flexDirection="row"
              justifyContent={"end"}
            >
              <Box >
              {topBottons.map((el: string)=>{
                return this.displayButton(el,"",{maxWidth:74, padding: 4},localStorage.getItem('user_type') == "Designer/QC/QA" || el == this.state.currentFinulentStatus || this.state.currentFinulentStatus == "Query - File Completed" || this.props.history.location.state.prevPath.toLowerCase() == "/taskboard" )
              })}
              </Box>
            </Box>}
            </Box>
          {/* // Nav End  */}

          <Box bgcolor={"#F7F8FA"} paddingTop={"20"}>
            <Box margin={"auto"} width={"75%"}>
              <Box marginBottom={"20"} fontSize={"19px"}>
                Details
              </Box>

              <Box
                bgcolor={"white"}
                borderTop={"2px solid #E89717"}
                borderRadius={"5px"}
                border={"1px solid #EAECED"}
                padding={"3 3"}
                height={"auto"}
              >
                <Centered>
                  {/* Finulent Tracking*/}
                  <Box
                    padding={"20 0"}
                    borderRadius={"5px"}
                    width={"100%"}
                    border={"1px solid #EAECED"}
                    margin={"2 0"}
                    height={"auto"}
                  >
                    <SectionHead>Finulent Tracking</SectionHead>

                    {/* Finulent Tracking */}
                    <Box
                      className="wrapper" 
                    >
                      {this.echoInputFields("finulent_tracking")}
                    </Box   >
                    {/* Finulent Tracking End*/}
                  </Box>
                </Centered>

                <Centered>
                  {/* Time Tracking*/}
                  <Box
                    padding={"20 0"}
                    borderRadius={"5px"}
                    width={"100%"}
                    border={"1px solid #EAECED"}
                    margin={"2 0"}
                    height={"auto"}
                  >
                    <SectionHead>Time Tracking</SectionHead>
                    <Box
                      className="wrapper"
                    >
                      {this.echoInputFields("time_tracking")}
                    </Box>
                  </Box>
                  {/* Time Tracking End*/}
                </Centered>

                <Centered>
                  {/* Client Feedbacks */}
                  <Box
                    padding={"20 0"}
                    borderRadius={"5px"}
                    width={"100%"}
                    border={"1px solid #EAECED"}
                    margin={"2 0"}
                  >
                    <SectionHead>Client Feedback</SectionHead>
                    {/* Client Feedback */}
                    <Box
                      className="wrapper"
                    >
                      {this.echoInputFields("client_feedback")}
                    </Box>
                  </Box>
                  {/* Client Feedbacks End*/}
                </Centered>

                {this.state.showCustomBox && <Centered>
                  {/* Custom Fields */}

                  <Box
                    padding={"20 0"}
                    borderRadius={"5px"}
                    width={"100%"}
                    border={"1px solid #EAECED"}
                    margin={"2 0"}
                  >
                    <SectionHead>Custom Fields</SectionHead>

                    <Box
                      className="wrapper"
                    >
                      {this.echoInputFields("custom_field")}
                    </Box>
                  </Box>
                </Centered>}
              </Box>
              {/* Attachments */}

              <Attachments
                isUploading={this.state.isUploading}
                handleUpload={this.handleUpload}
                Attachments={this.state.Attachments}
                deleteAttachment={this.deleteAttachment}
              />

              {/* Toggler */}
              <Box
                width={"26.5%"}
                display={"flex"}
                justifyContent={"space-evenly"}
                padding={"3"}
                fontSize={"1.5vh"}
                margin={"10"}
                border={"1px solid #D4D4D4"}
                borderRadius={"5"}
              >
                <Box
                  padding={"4.5 6"}
                  width={"49%"}
                  bgcolor={this.state.toggler ? "#EDAB29" : ""}
                  borderRadius={"5px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={"togglers"}
                  onClick={this.commentStateToggler}
                >
                  Comments
                </Box>
                <Box
                  padding={"1 10"}
                  width={"49%"}
                  bgcolor={this.state.toggler ? "" : "#EDAB29"}
                  borderRadius={"5px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={"togglers"}
                  onClick={this.commentStateToggler}
                >
                  Show Activity
                </Box>
              </Box>
              {/* Toggler End*/}

              {/* Comments */}
              {this.state.toggler ? <>
              <Box id={"TaskBoardCommentParent"}>
                <Box style={{
                  background: 'white',
                  minHeight: 'inherit',
                  borderRadius: '4px',
                  display: 'flex',
                  width: "100%",
                  justifyContent: 'flex-end',
                  position: 'relative',

                }} >
                  <Box style={{width: '100%', position: 'absolute'}}>
                    <this.CustomMention 
                    onChange={(e:{target:{value:string}},newValue:string, newPlainTextValue:string, mentions:{id:string}[]) => {this.handleCommentTextChange(e.target.value); this.handleMention(newPlainTextValue, newValue, mentions);}}
                    value={this.state.comment} 
                    placeholder={"Add a Comment"}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    marginBottom={'10px'}
                    marginRight={"10px"}
                    style={{alignItems: 'end'}}
                  >
                    <Box style={{position: 'relative', cursor: 'pointer', marginBottom: "7px"}} >
                      <ImAttachment fill={this.state.commentInProgress ? "gray" : 'black'} />
                    <input type="file" disabled={this.state.commentInProgress} className={this.state.commentInProgress? "upload-input-project-disabled" : "upload-input-project"} id="commentfileupload" onChange={(event)=>{this.handleProjectAttachment(event)}} />
                      
                    </Box>
                    <Box>
                      <Button
                        style={{
                          fontSize: "1.5vhv",
                          fontWeight: "bolder",
                          textTransform: "none",
                          marginLeft: '15'
                        }}
                        variant={"contained"}
                        className="sendBtn"
                        onClick={() => { this.submitComment() }}
                        disabled={(!this.state.comment.trim().length && !this.state.projectAttachmentToBeUploaded.length) || this.state.commentInProgress }
                      >
                        Send
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {this.state.commentInProgress && 
              <div className='LoaderContainer'>
                <div>
                  <div style={{marginBottom: 5}} >
                    <b>Processing...</b>
                  </div>
                  <div className='bar' />
                </div>
              </div>
                }
              {this.state.projectAttachmentToBeUploaded.length >0 && 
              <Box className="attachmentWrapper" id={"commentAttachmentWrapper"} style={{marginTop: 15}}>
                {this.state.projectAttachmentToBeUploaded.map((el, index:number)=>{
                  return <AttachmentsForComments 
                  data={el as unknown as {name:string}} 
                  deleteCommentAttachment={this.deleteCommentAttachment} 
                  index={index} 
                  key={index} 
                  imageLink={URL.createObjectURL(el)}
                  />
                })}
              </Box>
              }

              <Box width={"85%"}>
                {this.state.displayComments.map((item:commentProps['comment']) => {
                  return <Comment key={item.id} comment={item} editComment={this.editComment} deleteComment={this.deleteComment} CustomMention={this.CustomMention as unknown as customMentionType['customMention']} handleChange={this.handleMention} />
                })}
              </Box> </>
              : <ShowActivity activity={this.state.activityData} CustomMention={this.CustomMention}/>}
              {/* Comments End*/}

              {/* State Changer */}
              {<Box
                display={"flex"}
                justifyContent={"flex-end"}
                bgcolor={"#F3F4F6"}
                padding={"10 0"}
                border={"2px solid #EAECED"}
                borderRadius={"10px"}
                minHeight={40}
              >
                {this.displayFooter()}
              </Box>}
              {/* State Changer  End*/}
              <Box 
              bgcolor={'#f7f8fa'}
              height={50}
              >

            <Box zIndex={1112}>
              <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                />
            </Box>
              </Box>
              {/* CheckListModal */}
              <CheckListModal
                key={Date.now()}
                open={this.state.openCheckListModal}
                handleClose={this.handleCheckListModalClose} 
                checkListData= {this.state.checkListData}
                updateCheckList={this.updateCheckList}
                finulentStatus={this.state.currentFinulentStatus}
                disableDesginerChecklist= {this.state.disableDesginerChecklist}
                handleCheckListModalClose= {this.handleCheckListModalSave}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
