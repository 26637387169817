//@ts-nocheck
import React from "react";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "material-ui-pickers";

import Avatar from "@material-ui/core/Avatar";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import {
  createMuiTheme,
  Box,
  Button,
  Typography,
  InputLabel,
  TextField,
  Grid,
  Select,
  MenuItem,
  Modal,
  Menu,
  withStyles,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import SearchBar from "material-ui-search-bar";
import CloseIcon from "@material-ui/icons/Close";
import { filter, darkFilter } from "./assets";
// Customizable Area End

import ClientInvoicesSuperadminController, { Props } from "./ClientInvoicesSuperadminController";
import "./ClientInvoicesSuperadmin.css";
export const configJSONBase = require("../../../framework/src/config");

const materialTheme = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "black"
      },
      dayLabel: {
          color: "black"
      },
    },
    MuiPickersToolbar: {
      toolbar: {
          color: "black",
          backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
          color: "black",
      },
      toolbarBtnSelected: {
          color: "black"
      },
   },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px",
        },
      },
    },

    palette: {
      primary: "red",
    },
    MuiButton: {
      textPrimary: {
        color: "black",
      },
    },
    MuiPickersDay: {
      day: {
      color: "black"
      },
      daySelected: {
          backgroundColor: "#e8e8e8"
      },
      dayDisabled: {
          color: "#e8e8e8"
      },
      current: {
          color: "#e8e8e8"
      },
      isSelected: {
        color: "white",
        backgroundColor: "#e8e8e8"
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#8bc34a",
      },
    },
    myComponent: {
      "& .MuiPickersDay-isSelected": {
        backgroundColor: "red",
      },
    },

  },
});
//istanbul ignore next
const useStyles = ((theme) => ({ 
  selectRoot: {  
    '&:focus':
    { backgroundColor:'yellow' } 
  },
  menuStyle : {
    "& .MuiPaper-root":{
       maxWidth : "500px",
     }
   },
}));
//istanbul ignore next
const usePlaceholderStyles = makeStyles(theme => ({
  placeholder: {
    color: "#aaa"
  }
}));

export class ClientInvoicesSuperadmin extends  ClientInvoicesSuperadminController{

  constructor(props: Props) {
    super(props);
    
  }

  handleBack = async () => {
    let isFromTemplatePath = await localStorage.getItem("isFromTemplatePath")
    let tid = await localStorage.getItem("fromTemplateTid")
    let cid = await localStorage.getItem("fromTemplateCid")
    
    if(isFromTemplatePath == "true"){
      this.props.history.push(`/reviewChecklist?cid=${cid}&tid=${tid}`);
    }else{
      this.props.history.push(`/client_subfolder?cid=${this.query.get('clientId')}`);
    }    
  };


  handleChangeStatus = (element: any) => {
    this.setState({ status: element.target.value },()=>{});
  };

handleChangePage = (event: unknown, newPage1: number) => {
  this.setState({pageNo:newPage1})
};
handleChangeRowsPerPage = (event1: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({pageNo:0})
  this.setState({rowsPerPage:+event1.target.value})
};

requestSearch = (el:any) => {
  this.setState({search:el})
};
 cancelSearch = () => {
  this.setState({search:""},()=>{})
  this.requestSearch(this.state.search);
  this.setState({search:""})
};

handleApply =() =>{
  let isError = false
  let shouldApplyFilter = true;
  
  if(this.state.toDate || this.state.fromDate){
    if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
      this.setState({ filterToDateError:true})
      isError = true;
  
    }
  }
        
 if (isError) {
   return;
 }

 if(!this.state.toDate && !this.state.fromDate && this.state.status == ""){
  shouldApplyFilter=false;
}

  this.getApply()
  this.setState({filterOpen:null ,initialTableLoad:true, filterToDateError: false, filterApplied: shouldApplyFilter})
}

handleFilterClose =()=>{
  this.setState({isConfirm:false,status:"", toDate:"", fromDate:"",filterOpen:null, filterToDateError:false})
}

handleFilterClick =(e:any) =>(
 this.setState({filterOpen:e.currentTarget})
)

handleCancelBtn =() =>{
  this.setState({isConfirm:false})
}
handleYes=() =>{
   this.deleteUser()
   this.setState({isConfirm:false})
}

  handleReset=()=>{
    this.getInvoices();
    this.setState({fromDate:"",toDate:"",status:"",filterToDateError: false, filterApplied:false})
  }

  getFilteredData=(filteredList:any)=>{
    let data = [];

    data = filteredList.filter((item:any)=>{
      
      if(!this.state.search) return true
      if(item?.attributes?.client_name?.toLocaleLowerCase()?.startsWith(this.state.search.toLocaleLowerCase()))return true
    }).slice(this.state.pageNo * this.state.rowsPerPage, this.state.pageNo * this.state.rowsPerPage + this.state.rowsPerPage)

    this.searchedRecordsLength = data?.length;
    return data
  }

    getNoDataViewForSearch=()=>{
    if(this.state.search?.length > 0 && this.searchedRecordsLength == 0 || this.state.search?.length > 0 && this.state.invoiceData?.length == 0){
      return <p className="norecord">No invoices found</p>
    }
  }

  getCount=()=>{
    if(this.state.search?.length > 0 && this.searchedRecordsLength == 0){
      return 0
    }
    if(this.state.search?.length > 0 && this.searchedRecordsLength > 0){
      return this.searchedRecordsLength
    }
    return this.state.invoiceData?.length
  }

    getNoDataView=()=>{
    if(this.state.search?.length == 0 && this.state.isFilterApplied && this.state.invoiceData?.length == 0){
      return <p className="norecord">No invoices found</p>
    }
    else if(this.state.search?.length == 0 && this.state.invoiceData?.length == 0){
      return <p className="norecord">No invoices Found</p>
    }
  }

  getDownloadButton=(item:any)=>{
    if(!item?.attributes?.pdf){
      return <ArrowDownwardOutlinedIcon style={{cursor:'pointer',color:'gray'}}/>
    }
      return <ArrowDownwardOutlinedIcon onClick={()=>{
        this.downloadPDF(item?.attributes?.pdf);
      }}
      style={{cursor:'pointer'}}/>
  }

  getStatusDropdown=()=>{
    return(
      <Grid item sm={12}>
      <InputLabel className="inputLabel">Status</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        renderValue={
          this.state.status !== "" ? undefined : () => <Placeholder>Select</Placeholder>
        }
        value={this.state.status}
        name="role"
        onChange={this.handleChangeStatus}
        variant="outlined"
        required
        fullWidth
        displayEmpty
        style={{ height: "40px",textAlign:"left" }}
      >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        {this.state.statusData && this.state.statusData.map((item:any) =>(
          <MenuItem value={item}
          >{item}</MenuItem>
        ))}

      </Select>
      {this.state.status === "" && !this.state.page && (
        <>
          <p
          className="errMsg"
          >
            Status is required
          </p>
        </>
      )}
    </Grid>
    )
  }

  handleErrorForDate=()=>{                     
    //istanbul ignore next      
    if(this.state.modalToDate && this.state.modalFromDate){
      if(!moment(this.state.modalToDate).isSame(this.state.modalFromDate) && !moment(this.state.modalToDate).isAfter(this.state.modalFromDate)){
        this.setState({toDateError : true})
      }else{
        this.setState({toDateError:false})
      }
    }
  }

  renderFilterPopup=(classes:any)=>{
    return  <Menu
    id="account-menu"
    anchorEl={this.state.filterOpen}
    open={Boolean(this.state.filterOpen)}
     onClose={this.handleFilterClose}
     getContentAnchorEl={null}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      className={classes?.menuStyle}
     
 >
    <Box style={{ padding: "10px" }}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
          <InputLabel className="inputfilter"> Filter</InputLabel>
            <Box onClick={this.handleReset} style={{ textDecoration: "underline", cursor :"pointer", paddingRight:"16px" }}>Clear Filter</Box>
          </Box>
          <MenuItem>
          <Grid xs={12} container>
            <Grid item xs={6} container style={{paddingRight : "10px"}}>
              <Grid item xs={12}>
                <InputLabel className="inputLabel">From Date</InputLabel>
              </Grid>
              <Grid item xs={12} >                 
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                  >
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        data-test-id="datePickerFromDate"
                        keyboard
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={webStyle.text}
                        placeholder="DD/MM/YYYY"
                        format={"DD/MM/YYYY"}
                        mask={(value) =>
                          value
                            ? [
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]
                            : []
                        }
                        value={this.state.fromDate}
                        onChange={(date)=>{
                          this.setState({fromDate:date},handleError)
                          function handleError(){
                            if(this.state.fromDate && this.state.toDate){
                              //istanbul ignore next
                              if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
                                this.setState({filterToDateError: true})
                              }else{
                                this.setState({filterToDateError: false})
                              }
                              
                            }
                          }
                        }}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable        
                        onInputChange={(e: any) => {
                          //istanbul ignore next
                          if(e.target.value == ""){
                           this.setState({fromDate:"",filterToDateError:false});
                          }}
                        }                
                      />
                    
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
              </Grid>
              
            </Grid>
            <Grid item xs={6} container style={{paddingLeft : "10px"}}>
              <Grid item xs={12} >
                <InputLabel className="inputLabel">To Date</InputLabel>
              </Grid>
              <Grid item xs={12}>
              <MuiPickersUtilsProvider
                    utils={MomentUtils}
                  >
                    <ThemeProvider theme={materialTheme}>
                      <DatePicker
                        data-test-id="datePickerToDate"
                        keyboard
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={webStyle.text}
                        placeholder="DD/MM/YYYY"
                        format={"DD/MM/YYYY"}
                        mask={(value) =>
                          value
                            ? [
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]
                            : []
                        }
                        value={this.state.toDate}
                        onChange={(date)=>{
                          this.setState({toDate:date},()=>{
                            if(this.state.fromDate && this.state.toDate){
                              //istanbul ignore next
                              if(!moment(this.state.toDate).isSame(this.state.fromDate) && !moment(this.state.toDate).isAfter(this.state.fromDate)){
                                this.setState({filterToDateError: true})
                              }else{
                                this.setState({filterToDateError: false})
                              }
                            }
                          })
                        }}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable
                              
                        onInputChange={(e: any) => {
                          //istanbul ignore next
                          if(e.target.value == ""){
                           this.setState({toDate:"",filterToDateError:false});
                          }}
                        }       
                      />

                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
              </Grid>
              
            </Grid>
          </Grid>
          

        </MenuItem>
        <Grid item xs={12} >
            
                  <Grid style={{paddingLeft : "20px"}}>
{this.state.filterToDateError && <Typography style={webStyle.errorText}>From Date can not be greater than To Date</Typography>}
                  </Grid>

                 
                
              </Grid>
          <MenuItem >
         {this.getStatusDropdown()}
          </MenuItem>
          <div
          style={{
            padding: "10px 15px 0px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            style={{ backgroundColor: "grey" }}
            onClick={this.handleFilterClose}
            className="btn-cancelmenu"
          >
            Cancel
          </Button>
          <Button
            className="btn-addmenu"
             onClick={this.handleApply}
          >
            Apply
          </Button>
        </div>
        </Box>
  </Menu>
  }

  getFromToDateError=()=>{
    return this.state.toDateError && <Typography style={webStyle.errorText}>From Date can not be greater than To Date</Typography>
  }

  getProjectInvoicingStructure=(webStyle:any)=>{
    return <TextField variant="outlined"    fullWidth  value={this.state.projectInvoicingStructure == "project_completion_date" ? "Project completion date" : "Project creation date"} disabled style={webStyle.textfieldDisabled}/>
  }

  getModalToDateView=(webStyle:any)=>{
    return  <DatePicker
    data-test-id="modalToDatePicker"
    keyboard
    variant="outlined"
    size="small"
    fullWidth
    style={webStyle.text}
    placeholder="DD/MM/YYYY"
    format={"DD/MM/YYYY"}
    mask={(value) =>
      value
        ? [
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]
        : []
    }
    value={this.state.modalToDate}
    onChange={(date)=>{
      this.setState({modalToDate:date,isModalToDateError:false},this.handleErrorForDate)
    }}
    disableOpenOnEnter
    animateYearScrolling={false}
    autoOk={true}
    clearable
    onInputChange={(e: any) =>{
      //istanbul ignore next
      if(e.target.value==""){
        this.setState({modalToDate:"",isModalToDateError:false, toDateError:false})
      }
    }}
  />
  }

  renderDefineInvoice = () => {
    if(this.state.defineInvoiceModal) {
        return (
            <Modal style={webStyle.modalbackdrop} 
            open={this.state.defineInvoiceModal} 
            onClose={this.handleCloseDefineInvoiceModal}
             data-test-id={"addModalpopup"}>
                  <div className="modalcontentsuccess" style={webStyle.modalcontentsuccess}>
                        <div style={webStyle.modalContent}>
                        <div style={webStyle.addworkspacemodaldiv}>
                                 <Grid 
        container
        style={{
        borderBottom : "1px solid rgb(232, 232, 232)", 
        padding: "1.3rem 1.3rem",
      }}
         justifyContent="space-between">
                <Box fontSize={"20px"} fontWeight={"bold"}>
                Generate Invoice
          </Box>
            <CloseIcon
             onClick={this.handleCloseDefineInvoiceModal}
             />
        </Grid>
                            </div>
                            <div style={webStyle.addworkspacemaincontentdiv}>
                                <div style={webStyle.addworkspacedivcontent} className="addclientdivcontent">
                                    <div style={webStyle.addworkspaceelements}>
                                        <p style={webStyle.modaltextboxheader}><strong>{"Client Name"}</strong></p>
                                        <TextField variant="outlined" style={webStyle.textfieldDisabled} fullWidth disabled value={this.state.subfolderClientName}
                                          placeholder="TATA Power"
                                          
                                        />
                                                                                <p style={webStyle.modaltextboxheader}><strong>Client id</strong></p>
                                        <TextField variant="outlined" placeholder="23WSF"  fullWidth  value={this.state.subfolderClientId} disabled style={webStyle.textfieldDisabled}/>

                                        <p style={webStyle.modaltextboxheader}><strong>Project invoicing structure</strong></p>
                                        {this.getProjectInvoicingStructure(webStyle)}
                                        
                                        <p style={webStyle.modaltextboxheader}><strong>Overdue Date</strong></p>
                                        <MuiPickersUtilsProvider
                        utils={MomentUtils}
                      >
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            data-test-id="datePickerModalDueDate"
                            keyboard
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={webStyle.text}
                            placeholder="DD/MM/YYYY"
                            format={"DD/MM/YYYY"}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            value={this.state.modalOverdueDate}
                            onChange={(date)=>this.setState({modalOverdueDate:date, isModalOverdueDateError:false})}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            autoOk={true}
                            clearable
                            onInputChange={(e: any) => {
                            //istanbul ignore next
                              if(e.target.value == ""){
                               this.setState({modalOverdueDate:""});
                              }}
                            }
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                                        <Grid container>
                                          <Grid item xs={6}>
                                          <p style={webStyle.modaltextboxheader}><strong>From  Date*</strong></p>
                                          <MuiPickersUtilsProvider
                        utils={MomentUtils}
                      >
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            data-test-id="modalFromDatePicker"
                            keyboard
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={webStyle.text}
                            placeholder="DD/MM/YYYY"
                            format={"DD/MM/YYYY"}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            value={this.state.modalFromDate}
                            onChange={(date)=>{
                              this.setState({modalFromDate:date,isModalFromDateError:false},this.handleErrorForDate)
                            }}
                            
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            autoOk={true}
                            clearable
                            onInputChange={(e: any) => {
                              //istanbul ignore next
                              if(e.target.value == ""){
                               this.setState({modalFromDate:"",isModalFromDateError:false, toDateError:false});
                              }}
                            }
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                                        {this.state.isModalFromDateError && <Typography style={webStyle.errorText}>From  Date is required</Typography>}
                                          </Grid>

                                          <Grid item xs={6} style={{paddingLeft:"10px"}}>
                                          <p style={webStyle.modaltextboxheader}><strong>To Date*</strong></p>
                                          <MuiPickersUtilsProvider
                        utils={MomentUtils}
                      >
                        <ThemeProvider theme={materialTheme}>
                         {this.getModalToDateView(webStyle)}
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                                        {this.state.isModalToDateError && <Typography style={webStyle.errorText}>To Date is required</Typography>}

                                        
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                        {this.getFromToDateError()}

                                        </Grid>
                                        <Grid container sm={12} style={{margin:"25px auto auto auto"}}>
                                            

                                            
                                          <Grid item sm={6}>
                                          <Button                   size="large"
 style={webStyle.cancelButton} onClick={this.handleCloseDefineInvoiceModal}>Cancel</Button>

                                          </Grid>
                                          

                                          <Grid item sm={6}>
                                             <Button
                  style={{
                    textTransform: "none",
                    backgroundColor: "#4EABF8",
                    color:"#ffff",
                    width:"97%",
                    marginLeft:"10px"
                  }}
                  size="large"
                  variant="contained"
                  onClick={this.handelGenerateInvoice}
                >
                  Generate 
                                              </Button>
                                          </Grid>

                                        </Grid>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </Modal>
        )
    }
  }

  parseImg(img: string | null) {
    if (!img) return undefined;
    const imageLink = configJSONBase.baseURL;
    const imageFragment =
      typeof img === "string" && img.split("/").includes("rails");
    const imgSrc = imageFragment ? `${imageLink}/${img}` : img;
    return imgSrc;
  }

  getClientName=(item:any)=>{
    if(item?.attributes?.client_name?.length > 26){
      return <Tooltip title={item.attributes.client_name}>
      <Typography display="inline">
         {item.attributes.client_name}
         </Typography>
         </Tooltip>
    }else{
      return item.attributes.client_name
    }
  }

  
  getTableCells=(item:any)=>{
    return <>
    <TableCell className="tablecell tabledata" >{item?.slNo}</TableCell>
    <TableCell className="tablecell" style={{textOverflow:'ellipsis', maxWidth:"200", whiteSpace:'nowrap', overflow:'hidden'}}>
    {this.getClientName(item)}
       </TableCell>
    <TableCell className="tablecell"> {item?.attributes?.client_id}</TableCell>
    <TableCell className="tablecell"> { item?.attributes?.client_invoice_list?.from_date===null ?"NA" :moment(item?.attributes?.client_invoice_list?.from_date).format("DD/MM/YYYY")}</TableCell>
    <TableCell className="tablecell"> { item?.attributes?.client_invoice_list?.to_date===null ?"NA" :moment(item?.attributes?.client_invoice_list?.to_date).format("DD/MM/YYYY")}</TableCell>
    <TableCell className="tablecell"> { item?.attributes?.client_invoice_list?.overdue_date===null ?"NA" :moment(item?.attributes?.client_invoice_list?.overdue_date).format("DD/MM/YYYY")}</TableCell>
   
    <TableCell className="tablecell tabledata">{item?.attributes?.client_invoice_list?.invoice_status}</TableCell>
    <TableCell className="tablecell tabledata">{item?.attributes?.client_invoice_list?.invoice_amount}</TableCell>
    </>
  }


  getFilterIcon=()=>{
    if(this.state.filterApplied){
      return <img src={darkFilter} style={{ width: 22, height: 22,marginRight:"15px", cursor :"pointer"}} onClick={this.handleFilterClick}/>
    }else{
      return <img src={filter} style={{ width: 30, height: 30,marginRight:"15px", cursor :"pointer"}} onClick={this.handleFilterClick}/>
    }
  }

  render() {
    const {classes} = this.props
    
    const filteredList = this.state.invoiceData

    return (
      <>
     
     {this.renderFilterPopup(classes)}
      
       {this.state.showLoader ? <Box style={{marginLeft:"50px"}} display={"flex"} minWidth={"100vw"} justifyContent={"center"} alignItems={"center"} minHeight={"100vh"} position="absolute">
            <CircularProgress size={50} />
          </Box> : 
        <Box
          sx={{
            height:"100%",
            width: "85vw",
            top: "50px",
            right:0,
            boxSizing:"border-box",
            padding:"50px 20px 10px 20px",
            position: "absolute",
          }}
          style={{ backgroundColor:"#e8e8e8"}}
          justifyContent="space-between"
          className="maintablehead"
        >
          <Box sx={{ display: "flex" ,justifyContent:"space-between",alignItems:"center"}}>
            
            <div style={{display: "flex", alignItems: "center"}}> 
            <Box style={webStyle.arrowbox}>
                  <ArrowLeftIcon
                    onClick={this.handleBack}
                  />
                </Box>
            <Typography
              style={{
                fontFamily: "sans-serif",
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
           Client Invoices
            </Typography>
            </div>
          
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div style={{display:"flex",alignItems:"center"}}>
              {this.getFilterIcon()}
              </div>

              <SearchBar
                placeholder="Search Invoices"
                  value={this.state.search}
                  onChange={this.requestSearch}
                  onCancelSearch={this.cancelSearch}
              />
              <div>
              <Button
               data-test-id="generateInvoiceBtn"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#4EABF8",
                    color:"#ffff",
                    height:"100%",
                    marginLeft:"15px"
                  }}
                  size="large"
                  variant="contained"
                  onClick={()=>this.setState({defineInvoiceModal:true})}
                >
                  Generate Invoice
                </Button>
              </div>
            </div>
          </Box>
            { this.state.loading ? <Box className="progres">  <CircularProgress size={50}/> </Box> :
              <>
              <div className="tableuserdata">
               <Table aria-label="sticky table" className="usermanagement-table"  >
              <TableHead className="maintablehead">
                <TableRow>
                 <TableCell className="tableheader">S.no</TableCell>
                 <TableCell className="tableheader">Client Name</TableCell>
                 <TableCell className="tableheader">Client id</TableCell>
                 <TableCell className="tableheader">From Date</TableCell>
                 <TableCell className="tableheader"> To Date</TableCell>
                 <TableCell className="tableheader">Overdue Date</TableCell>
                 <TableCell className="tableheader">Invoice Status</TableCell>
                 <TableCell className="tableheader">Invoice Amount</TableCell>
                 <TableCell className="tableheader">Finulent Admin</TableCell>
                 <TableCell className="tableheader">Actions</TableCell>
              </TableRow>
              </TableHead>


              <TableBody>
                {this.state.invoiceData?.length >0  ?this.getFilteredData(filteredList).map((item:any,id:any) =>
                 {
                  return (
                    <TableRow>
                      {this.getTableCells(item)}

      <TableCell className="tablecell  name-break1"> 
      
      <Box sx={{display : "flex", alignItems : "center", justifyContent:'center'}} >                     
           <Avatar className="smallSize" alt="Remy Sharp" src={item?.attributes?.finulent_admin?.length > 0 && item?.attributes?.finulent_admin[0]?.image ? this.parseImg(item?.attributes?.finulent_admin?.length > 0 && item?.attributes?.finulent_admin[0].image) : "/static/images/avatar/1.jpg"}/> 
            <Typography style={{paddingLeft : "8px"}}>{item?.attributes?.finulent_admin?.length > 0 && item?.attributes?.finulent_admin[0]?.first_name}{" "}{item?.attributes?.finulent_admin?.length > 0 && item?.attributes?.finulent_admin[0]?.last_name}</Typography>
        </Box>
      </TableCell>


      <TableCell className="tablecell"> 
      
      <Box sx={{display : "flex", gap : "10px", alignItems : "center", justifyContent:'center'}} >  
      <VisibilityOutlinedIcon style={{cursor:'pointer'}}  onClick={this.handleVisibilityOutLinedIcon?.bind(this,item)}/>
      <EditOutlinedIcon data-test-id="GetDownloadsID" style={{cursor:'pointer'}} onClick={()=>this.props.history.push("/genricInvoiceClientSuperadmin?id="+item?.id+"&clientId="+this.query.get('clientId')+'&isEdit=true')}/>
      {this.getDownloadButton(item)}

        </Box>
      </TableCell>
      </TableRow>
                  )
                }):null
                }
                  </TableBody>

            </Table>
            
            {this.getNoDataViewForSearch()}
            {this.getNoDataView()}
            </div>

        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={this.getCount()}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.pageNo}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
      </>
            }
            {this.renderDefineInvoice()}
        </Box>}

      </>
      
    );
  }
}

const Placeholder:React.FunctionComponent = ({children}) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default withStyles(useStyles)(ClientInvoicesSuperadmin)

const webStyle = {
  parent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "fit-content",
    position: "relative",
    backgroundRepeat: "no-repeat",
  },
  boxes: {
    marginTop: "10px",
  },
  arrowbox: {
    backgroundColor: "#fff",
    width: 35,
    height: 35,
    margin: 10,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  },
  modalbackdrop: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    alignContent: "center"
  },
  modalcontentsuccess: {
    width: "500px",
    height: "73%",
    fontFamily: "sans-serif",
    backgroundColor: "white",
    alignItems: "left",
    justifyContent: "left",
    margin: 20,
    overflowY: "scroll",
} as React.CSSProperties,
modalcontent: {
  fontSize: "16px",
  fontFamily: "sans-serif",
  fontWeight: 500,
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
  alignSelf: "center",
  display: "flex"
},
addworkspacemodaldiv: {
  backgroundColor: "white",
  marginLeft: 0,
  marginright: 0,
},
headerBox: {
  borderBottom: '1px solid #e8e8e8',
  display: "flex",
  justifyContent: "space-between",
  width: 520,
} as React.CSSProperties,
cancelButton: {
  width: "100%",
  border: "1px solid #cecece",
  backgroundColor: "#e8e8e8",
  color: "black",
  fontWeight: 600,
  textTransform: "none",
}as React.CSSProperties,
addEditHeading: {
  marginLeft: 20,
},
closebuttonstyle: {
  marginTop: 20,
  marginRight: 25,
},
addworkspacemaincontentdiv: {
  backgroundColor: "white",
  marginBottom: 10,
},
addworkspacedivcontent: {
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  marginRight: 20,
} as React.CSSProperties,
addworkspaceelements: {
  marginBottom: 20
},
modaltextboxheader: {
  color: "#7a7a7a"
},
textfieldDisabled: {
  backgroundColor: "#e8e8e8"
},
errorText: {color:"#f44336", fontSize:15, marginTop:5},

};
