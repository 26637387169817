import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSONBase = require("../../../framework/src/config");
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react'
const chartRef = React.createRef()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
    analyticsData: any;
    optionspie: any;
    bardataclientwisedes: any;
    selecteddate: any;
    dateRange: any;
    from: any;
    to: any;
    plugins: any;
    baroptionclientwisedes: any;
    baroptionclientwiseNodatades: any;
    TLChartRolewise: any;
    TLOptionRolewise: any;
    TLOptionRolewiseNodata: any;
    loader: any;
    totalNoOfProjectsTypewise: any;
    totalProjectsTypewisedes: any;
    totalProjectsClientWisedes: any;
    totalProjectsStatusWisedes: any;
    noOfErrorsClientWisedes: any;
    overdueClientWisedes: any;
    backgroundColordes: any;
    analytics_workspace_iddes :any;
    fullSizeDatades: any;
    fullSizeClientWisedes: any;
    fullSizeNoofErrorsdes: any;
    fullSizeOverduedes: any;
    drilldownDatades: any;
    myDataCountdes: any;
    myDataLabelsdes: any;

    drilldownoptiondes: any;
    drilldownanalyticsdatades: any;
    drilldowntotalNoOfProjectsTypewisedes: any;
    drilldowntotalProjectsTypewisedes: any;
    indexValuedes: any;
    passedDatades: any;
    drilldownconsolidatedDatades: any;
    drilldowntype_wisedes: any;
    selectedDatades: any;
    analyticsDatastatuswisedes: any;
    totalNoOfProjectsStatusWisedes: any;
    totalProjectsStatuswiseanalyticsdes: any;
    TLDataErrorDesigneerWisedes: any;
    clientValue: any;
    chartError : any;
    errorStatement: any;
    myDatacountBarteamwise: any;
    myDatacountOverdue: any;
    activeBarIndex: any;
    buttonkeycode: any;
    tokenError: any;
    isSuccessModal: any;
    OtherErrorStatement: any;
    drilldownErrorStat: any;
    overdueDataArray: any;

    buttondisableLeftoverdue: any;
    buttondisableLeftError: any;
    buttondisableRightOverdue: any;
    buttondisableRightError: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DesignerAnalyticsController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  userGetApiCallId: any;
  analyticsWorkspaceApiCallId :any;
  analyticsDetailsDesignerApiCallId: any;
  analyticsDetailsDesignDateApiCallId: any;
  analyticsDetailsfullsizeApiCallId: any;
  _isMounted: any;
    chartRef: any;
    chartReferencedes: any;
    chartReferenceTeamwisedes: any;
    chartReferenceOverduedes: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    Chart.register(ChartDataLabels);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      buttonkeycode: "",
      totalNoOfProjectsTypewise: "",
      totalNoOfProjectsStatusWisedes: "",
      totalProjectsTypewisedes: [],
      totalProjectsStatuswiseanalyticsdes: [],
      TLDataErrorDesigneerWisedes: [],
      totalProjectsClientWisedes: [],
      totalProjectsStatusWisedes: [],
      noOfErrorsClientWisedes: [],
      dateRange: "",
      from: JSON.parse(sessionStorage.getItem("analyticsData") || '{}').from || "",
      to: JSON.parse(sessionStorage.getItem("analyticsData") || '{}').to || "",
      selecteddate: "",
      loader:true,
      chartError :false,
      tokenError: false,
      isSuccessModal: false,
      arrayHolder: [],
      token: "",
      OtherErrorStatement: "",
      errorStatement: "",
      activeBarIndex: 0,
      overdueClientWisedes: [],
      analytics_workspace_iddes: "",
      fullSizeDatades: {},
      fullSizeClientWisedes: {},
      fullSizeNoofErrorsdes: {},
      fullSizeOverduedes: {},
      drilldownDatades: {},
      myDataCountdes :[],
      myDatacountBarteamwise: [],
      myDatacountOverdue: [],
      clientValue: "",
      myDataLabelsdes: [],
      drilldownErrorStat: "",
      overdueDataArray: [],
      buttondisableLeftoverdue: true,
      buttondisableLeftError: true,
      buttondisableRightOverdue: false,
      buttondisableRightError: false,
      plugins: [],
      analyticsData : {},
      analyticsDatastatuswisedes: {},
      backgroundColordes: [
                '#673147','#00FFFF', '#EADDCA', '#FFB6C1', '#7393B3',  '#D70040',
                '#228B22', '#F8C8DC', '#00008B', '#FFC000', '#954535','#FFFF8F', 
                '#E97451', '#50C878', '#DA70D6', '#0047AB', '#FAD5A5', '#90EE90', 
                 '#40e0d0','#EE4B2B', '#AAFF00', '#F2D2BD', '#89CFF0', 
                '#E1C16E', '#800020', '#E4D00A',
              ],

      optionspie: {
        elements: {
          arc: {
            offset: 10
          }
        },
        responsive: true, 
        maintainAspectRatio: false, 
        plugins: {
          labels:{
            display: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold"
            },
            formatter: (value: any, context: any) => {
              return value;
            },
          },
        },
      },
      TLChartRolewise: {
        datasets: [
          {
            backgroundColor: "#1F51FF",
            barPercentage: 0.7,
            borderRadius: 5,
            grouped: true,
            label: "Designer",
            datalabels: {
              anchor: 'end',
              align: 'start',
              rotation: 270,
            },
            data: [
              82, 65, 22, 
              41, 84, 75, 51,25, 97, 20, 55, 
              20, 40, 22, 31, 38, 20, 82, 40, 68, 
              79, 50, 86, 35,  63, 44 
            ],
          },
          {
            data: [
              72, 36,  84, 99, 40, 38, 
              43, 73, 58, 68, 69 ,122, 31, 82, 100, 60, 
              50, 20, 33, 91, 96, 37, 44, 
              51, 28, 126,
            ],
            grouped: true,
            label: "QC",
            backgroundColor: "#FFBF00",
            datalabels: {
              align: 'start',
              rotation: 270,
              anchor: 'end',
            },
            barPercentage: 0.7,
            borderRadius: 5
          },
          {
            backgroundColor: "#660066",
            grouped: true,
            datalabels: {
              anchor: 'end',
              rotation: 270,
              align: 'start',
            },
            barPercentage: 0.7,
            label: "QA",
            data: [
              24, 10, 9,
              51, 28, 126, 84, 99, 40, 38, 
              50, 20, 33, 91, 96, 37,12, 26, 32, 13,  44, 
              34, 37, 85, 28, 63 
            ],
            borderRadius: 5
          },    
        ],
        labels: [
          'zemmm mmmmmm mmm mmmmmmm','a12345','new_client', 'neww', 'one', 'Rajdeep', 'ROCK', 
          'Solar 1 client', 'Solar 1 client 1', 'Solar client 28 dec', 'son', 
          'Temp tEst', 'Testing email value', 'Client 1001', 'client 1601', 'dsd', 'Hi', 'HONDA', 'humtum', 
          'J Client', 'jony',  'Test Solar 23', 'UFO', 'uu', 'VG', 'Z Email validation front end', 
        ],
      },

      TLOptionRolewise: {
        onClick: this.drillDownFunction,
        borderWidth: 1,
        barThickness: 24,
        layout: {
          padding: {
            top: 15,
          }
        },
        barPercentage: 0.5,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold",
              color: "black"
            }
          }
        },
        categoryPercentage: 0.2,
        scales: {
          y: {
            min: 0,
            border: {
              dash: 10,
            },
            max: this.maxValueOverduewise,
            stepSize: 50,
            beginAtZero: true,
            ticks:{
              font: {
                weight: "600"
              }
            },
            title: {
              display: true,
              text: "No of projects   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
          },
          x: {
            min: 0,
            grid: {
              display: false
            },
            beginAtZero: true,
            title: {
              display: true,
              text: "Clients   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            max: 2,
            ticks:{
              callback: function(this: any, value :any) {
                return (this.getLabelForValue(value).substr(0, 4).concat('...'))
              },
              font: {
                weight: "600"
              },
              maxRotation: 20,
              minRotation: 20,
            }
          }
        },

      },

      TLOptionRolewiseNodata: {
        onClick: this.drillDownFunction,
        borderWidth: 1,
        barThickness: 38,
        plugins: {
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold",
              color: "black"
            }
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Clients   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            min: 0,
            max: 4,
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              }
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "No of projects   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              
            },
            grid: {
              display: true,
              lineWidth: 1,
              borderDash: [5]
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
        },
      },
      
      baroptionclientwisedes: {
        borderWidth: 1,
        barThickness: 38, 
        plugins: {
          datalabels: {
            font: {
              weight: "bold",
              color: "black"
            },
            display: true,
            color: "black",
        },
        legend: {
          display: false
        },
      },
      layout: {
        padding: {
          top: 15,
        }
      },
        scales: {
          y: {
            min: 0,
            stepSize: 50,
            beginAtZero: true,
            border: {
              dash: 10,
            },
            title: {
              align: "start",
              padding: 10,
              text: "No of errors   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              display: true,
              
            },
            grid: {
              borderDash: [5],
              display: true,
              lineWidth: 1,
            },
            ticks:{
              font: {
                weight: "600"
              }
            },
            max: this.maxValueErrorwise,
          },
          x: {
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              callback: function(this: any, value :any) {
                return (this.getLabelForValue(value).substr(0, 4).concat('...'))
              },
              font: {
                weight: "600"
              },
            },
            min: 0,
            max: 2,
            title: {
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              display: true,
            },
            grid: {
              display: false
            },
            beginAtZero: true,
          }
        },
      },

      baroptionclientwiseNodatades: { 
        plugins: {
        legend: {
          display: false
        },
        datalabels: {
          display: true,
          color: "black",
          font: {
            weight: "bold",
            color: "black"
          }
      }
      },
      borderWidth: 1,
        barThickness: 38,
        scales: {
          y: {
            title: {
              display: true,
              text: "No of errors   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
              padding: 10,
              
            },
            stepSize: 5,
            beginAtZero: true,
            grid: {
              display: true,
              borderDash: [5],
              lineWidth: 1,
            },
            border: {
              dash: 10,
            },
            ticks:{
              font: {
                weight: "600"
              }
            }
          },
          x: {
            min: 0,
            max: 2,
            beginAtZero: true,
            title: {
              display: true,
              text: "Client folder/ subfolder - Template   ⎯⎯⎯⎯⎯⎯⎯›",
              anchor: "start",
              align: "start",
            },
            grid: {
              display: false
            },
            ticks:{
              minRotation: 20,
              maxRotation: 20,
              font: {
                weight: "600"
              }
            }
          }
        },
      },
      drilldownoptiondes: {},
      drilldownanalyticsdatades: {},
      drilldownconsolidatedDatades: {},
      drilldowntype_wisedes: {},
      drilldowntotalNoOfProjectsTypewisedes: "",
      drilldowntotalProjectsTypewisedes: [],
      bardataclientwisedes: {},
      indexValuedes: "",
      passedDatades: {},
      selectedDatades: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
}


handleDatePickerDesigner = (a: any) => {
  if (a == null){
    this.setState({
      from: "",
      to: "",
      dateRange: null,
    })
    return
  }
  
  this.setState({
    to: new Date(a[1]).toLocaleDateString("en-GB"),
    dateRange:a,
    from: new Date(a[0]).toLocaleDateString("en-GB"),
  })
}


  analyticsGraphDetailsDesigner = () => {
    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type" : "application/json",
    };
  
    const attrsdes = {
      "start_date": this.state.from,
      "end_date": this.state.to,
    };
  
    const analyticsdes = {
      filter: attrsdes,
    };
  
    const httpBody = {
      analytics: analyticsdes,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.analyticsDetailsDesignerApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_dashboard/analytics/report");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody)); 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  capitalizeFirstLetter(str: any){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }
  
    maxValueErrorwise = () => {
      let maxLimit = Math.max(...this.state.myDataCountdes)
        return ((Math.round(maxLimit / 100)) * 100 + 100);
    }

    maxValueOverduewise = () => {
      let maxLimit = Math.max(...this.state.overdueDataArray)
        return ((Math.round(maxLimit / 100)) * 100 + 100);
    }

    previousDataExistsDesigner = () => {
      let fromDesigner: any
      let toDesigner: any;
      toDesigner = sessionStorage.getItem("ToDate")
      fromDesigner = sessionStorage.getItem("FromDate")
      const toDateDesigner = new Date(toDesigner);
      const fromDateDesinger = new Date(fromDesigner);
      const datearrDEsigner = [fromDateDesinger, toDateDesigner]
      if(fromDesigner == ""  || toDesigner == "" ){
        this.setState({
          from: "",
          to: "",
          dateRange:null,
        });
      }else{
        this.setState({
          from: fromDesigner,
          dateRange:datearrDEsigner,
          to: toDesigner
        });
      }
    }

  handleDataForChartDesign = (responseJson: any) => {

    let rolewiseLabels = responseJson.result?.client_projects_role_wise?.map((item: any) => {return(this.capitalizeFirstLetter(item.client_name))})
    this.setState({totalProjectsClientWisedes: responseJson.result?.client_projects_role_wise})
    this.setState({myDatacountOverdue: rolewiseLabels?.length})
    let rolwiseTL = responseJson.result?.client_projects_role_wise
    let designer = rolwiseTL?.map((item: any) => {if(item.designer > 0) {return(item.designer)}})
    let qc = rolwiseTL?.map((item: any) => { if(item.qc > 0) {return(item.qc)}})
    let qa = rolwiseTL?.map((item: any) => { if(item.qa > 0) {return (item.qa)}})
    let bardatadesmax = rolwiseTL?.map((item: any) => {return(item.designer)})
    let bardataqcmax = rolwiseTL?.map((item: any) => {return(item.qc)})
    let bardataqamax = rolwiseTL?.map((item: any) => {return (item.qa)})
    let bardataoverduepdqc = bardatadesmax?.concat(bardataqcmax, bardataqamax)
    this.setState({overdueDataArray: bardataoverduepdqc})

    let rolewisedatalabels =  {
        anchor: 'end',
        padding: -2,
        align: 'end',
      }

    let rolewisedatasets = [
      {
        grouped: true,
        label: "Designer",
        data: designer,
        backgroundColor: "#1F51FF",
        datalabels: rolewisedatalabels,
        barPercentage: 0.5,
      },
      {
        grouped: true,
        label: "QC",
        data: qc,
        backgroundColor: "#FFBF00",
        datalabels: rolewisedatalabels,
        barPercentage: 0.5,
      },
      {
        grouped: true,
        label: "QA",
        data: qa,
        backgroundColor: "#660066",
        datalabels: rolewisedatalabels,
        barPercentage: 0.5,
      },
    ]

    let rolewise = {
      labels: rolewiseLabels,
      datasets: rolewisedatasets
    }

    this.setState({TLChartRolewise: rolewise})
    
    let clientwiseLabelsDesigner = responseJson.result?.no_of_errors_client_wise?.map((item: any) => {return(this.capitalizeFirstLetter(item.client_name))})
    let barclientWisedatadesign = responseJson.result?.no_of_errors_client_wise?.map((item: any) => {return(item.count)})
    this.setState({myDataCountdes: barclientWisedatadesign})
    let bardataLabelsdesign =  {
      padding: -2,
      align: 'end',
      anchor: 'end',
    }
    let backgroundColordesign = [
      '#00FFFF', '#EADDCA', '#EE4B2B', '#AAFF00', '#F2D2BD', '#89CFF0', 
      '#E1C16E', '#800020', '#E4D00A', '#FFB6C1', '#7393B3', '#FFFF8F', 
      '#E97451', '#50C878', '#DA70D6', '#0047AB', '#FAD5A5', '#D70040',
      '#228B22', '#F8C8DC', '#00008B', '#FFC000', '#954535', '#90EE90', 
      '#673147', '#40e0d0']

    let bardatasetclientwiseDesigner = [
      {
        data: this.state.myDataCountdes,
        barPercentage: 0.5,
        datalabels: bardataLabelsdesign,
        backgroundColor: backgroundColordesign,
      }
    ] 

    let bardataclientwisedes = {
      labels: clientwiseLabelsDesigner,
      datasets: bardatasetclientwiseDesigner,
    }
    this.setState({bardataclientwisedes: bardataclientwisedes})
    this.setState({loader: false})
  }

  drillDownFunction = async (click: any, myDataCountdes: any) => {
    if(myDataCountdes?.length >0){
    if(myDataCountdes[0]) {
      let x = myDataCountdes[0].index
      let dataValue = await this.state.totalProjectsClientWisedes[x]
      let clientnameValue = this.state.totalProjectsClientWisedes[x]?.client_name
      this.setState({clientValue: clientnameValue})
      this.setState({drilldowntotalNoOfProjectsTypewisedes: dataValue?.type_wise_all_clients})
      let drilldowntypewisedata = dataValue?.type_wise  
      this.setState({totalProjectsTypewisedes: dataValue?.type_wise}) 
      let piedatatype_wise = drilldowntypewisedata?.map((item: any) => {
        if(item.count > 0 ) {return(item.count)}
      })
      let piecolortype_wise = drilldowntypewisedata?.map((item: any) => {return(item.color_code)})
      let piedataValuetype_wise = drilldowntypewisedata?.map((item: any) => {return(item.type)})
      let datasettype_wise = [
        {
          data: piedatatype_wise,
          backgroundColor: piecolortype_wise,
          radius: 120,
          innerWidth: 120,
          innerHeight: 120,
        }
      ]
      let analyticsDatatype_wise = {
        labels: piedataValuetype_wise,
        datasets: datasettype_wise
      }

      this.setState({analyticsData: analyticsDatatype_wise})

      let drilldownstatuswisedata = dataValue?.status_wise 
      this.setState({totalProjectsStatuswiseanalyticsdes: dataValue?.status_wise})
      let piedatastatus_wise = drilldownstatuswisedata?.map((item: any) => {
        if(item.count > 0 ) {return(item.count)}
      })
      let piecolorstatus_wise = drilldownstatuswisedata?.map((item: any) => {return(item.color_code)})
      let piedatavaluestatus_wise = drilldownstatuswisedata?.map((item: any) => {return(item.status)})
      let datasetstatus_wise = [
        {
          data: piedatastatus_wise,
          backgroundColor: piecolorstatus_wise,
          radius: 120,
          innerWidth: 120,
          innerHeight: 120,
        }
      ]
      let analyticsDatastatus_wise = {
        labels: piedatavaluestatus_wise,
        datasets: datasetstatus_wise
      }
      this.setState({analyticsDatastatuswisedes: analyticsDatastatus_wise})
    }
    this.setState({selectedDatades: true})
  }
  // else {
  //   this.setState({drilldownErrorStat: "Drilldown functionality error "})
  // }
  }

  analyticsGraphDetailsDateDesigner = (reqType: any, data1: any, data2: any) => {
    if(reqType == "date"){
      this.setState({to: data2})
      this.setState({from: data1})
    }
    const header = {
      'token': window.localStorage.getItem('token'),
      "Content-Type" : "application/json",
    };
  
    const attributesdesugn = {
      "start_date": data1,
      "end_date": data2,
    };
  
    const analyticsDesignerdata = {
      filter: attributesdesugn,
    };
  
    const httpBody = {
      analytics: analyticsDesignerdata,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.analyticsDetailsDesignerApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_dashboard/analytics/report");
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
  
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }


  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.state.dateRange !== prevState.dateRange  && this.state.dateRange) {
      this.setState({ loader: true });
      const toDate = new Date(this.state.dateRange[1]).toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
      const fromDate = new Date(this.state.dateRange[0]).toLocaleDateString("en-GB", {year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
      this.analyticsGraphDetailsDateDesigner("date",fromDate,toDate);
      sessionStorage.setItem("FromDate", fromDate)
      sessionStorage.setItem("ToDate",toDate)
    }else if(this.state.dateRange !== prevState.dateRange && !this.state.dateRange){
      this.setState({ loader: true });
      sessionStorage.setItem("ToDate","")
      sessionStorage.setItem("FromDate", "")
      this.analyticsGraphDetailsDateDesigner("date",null,null);
    }
  }


  handleErrorchartDesigner = (responseJson: any) => {
    if(responseJson.errors) {
      if(responseJson.errors[0]?.token){
        console.clear()
        console.log("Calling error functionality")
        this.setState({OtherErrorStatement: responseJson.errors[0]?.token})
        this.setState({isSuccessModal: true})
        this.setState({loader: false})
        this.setState({tokenError: true})
      }
      else{
      this.setState({errorStatement: responseJson.errors[0]})
      this.setState({chartError: true})
      this.setState({loader: false})
      }
    }
  }

  bardata = async (start: any, end: any) => {
    const chart = await this.chartReferencedes;
    const startScale = chart.config?.options?.scales.x.min + start;
    const endScale = chart.config?.options?.scales.x.max + end;
    chart.config.options.scales.x.min = startScale;
    chart.config.options.scales.x.max = endScale;
    this.bardatastart(startScale, chart.config.options)
    this.bardataend(endScale, chart.config.options)
    chart.update()
    
  }
    
  bardataoverdue = async (start: any, end: any) => {
    const chart = await this.chartReferenceOverduedes;
    const startScale = chart.config?.options?.scales.x.min + start;
    chart.config.options.scales.x.min = startScale;
    const endScale = chart.config?.options?.scales.x.max + end;
    chart.config.options.scales.x.max = endScale;
    this.bardataoverdueend(endScale,chart.config.options.scales)
    this.bardataoverduestartdesign(startScale, chart.config.options.scales)
    chart.update()
    
  }

  bardataoverduestartdesign = (startScale: any, chartConfigDesign: any) => {
    console.log(startScale, "startvaloverdue")
    if (startScale <= 0) {
      chartConfigDesign.x.max = 2;
      chartConfigDesign.x.min = 0;
      this.setState({buttondisableLeftoverdue: true})
    }
    else {
      this.setState({buttondisableRightOverdue: false})
    }
  }
  
  bardataoverdueend = (endScale: any, chartConfig: any) => {
    console.log(endScale, "endscaleoverdue")
    if(endScale >= this.state.myDatacountOverdue) {
      chartConfig.x.min = this.state.myDatacountOverdue - 4;
      chartConfig.x.max  = this.state.myDatacountOverdue - 1;
      this.setState({buttondisableLeftoverdue: true})
    }
    else {
      this.setState({buttondisableRightOverdue: false})
    }
  }

  bardatastart = (startScale: any, chartConfigData: any) => {
    console.log(startScale, "startva")
    if (startScale <= 0) {
      chartConfigData.scales.x.min = 0;
      chartConfigData.scales.x.max = 2;
      this.setState({buttondisableLeftError: true})
    }
    else {
      this.setState({buttondisableLeftError: false})
    }
  }
  
  bardataend = (endScale: any, chartconfigData: any) => {
    console.log(endScale, "endva")
    if(endScale >= this.state.myDataCountdes?.length) {
      chartconfigData.scales.x.min = this.state.myDataCountdes?.length - 2;
      chartconfigData.scales.x.max  = this.state.myDataCountdes?.length;
      this.setState({buttondisableRightError: true})
    }
    else {
      this.setState({buttondisableRightError: false})
    }
  }

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start  
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.analyticsDetailsDesignerApiCallId) {
       if(responseJson && responseJson.result) {
          this.handleDataForChartDesign(responseJson)
        }
        else {
          this.handleErrorchartDesigner(responseJson)
        }
        }
        

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 }
}
