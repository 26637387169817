import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { projectCarouselData, projectDataType } from "../../utilities/src/types";
import moment, { Moment } from "moment";
import {
  ProjectList2,
  ProjectInQueue,
  QCInitiated,
  sectionList,
  settingList1,
} from '../../utilities/src/KanbanInterface';
import { Alert, Platform } from "react-native";
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";
interface ApiData {
  contentType: string,
  method: string,
  endPoint: string,
  body?: object
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  projects: projectDataType,
  statusFlow: string[],
  data: {
    history: { location: { pathname: string } },
    projectStatusColors: projectCarouselData["projectStatusColors"],
    projects: projectDataType
  },
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  ActiveKeyBoardControl: boolean,
  currentSlide: number,
  prevSlide: number,
  isVertical: boolean
  startDate: string,
  endDate: string,
  email: string,
  password: string,
  selectedStartDate: string;
  selectedEndDate: string;
  modalVisible: boolean;
  project_in_Queue: Array<ProjectInQueue>
  qc_Initiated: Array<QCInitiated>;
  statusFlow: string[]
  statusData: { attributes: { color: string, section_type: string; name: string } }[]
  settingData: { name: string, direction: string, theme: string, active: boolean }
  isHorizontal: boolean
  theam: string
  dataLength: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class KanbanBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  projectListApiId: string = ""
  sectionListApiId: string = ""
  settingListApiId: string = ""
  sectionAPIID: string = ""
  settingsAPIID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      ActiveKeyBoardControl: false,
      currentSlide: 0,
      prevSlide: 0,
      isVertical: false,
      startDate: '19/02/2023',
      endDate: '25/03/2023',
      email: "",
      password: "",
      selectedStartDate: '',
      selectedEndDate: '',
      modalVisible: false,
      qc_Initiated: [],
      project_in_Queue: [],
      statusFlow: Platform.OS === 'web' && this.props.data.history.location.pathname == "/bb_taskboard" ? [] : this.props.statusFlow,
      statusData: [],
      settingData: { name: "", direction: "", theme: "", active: false },
      isHorizontal: false,
      theam: '',
      dataLength: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    if (Platform.OS != "web") {
      this.projectList()
      this.sectionList()
      this.settingList()
      await getStorageData("USER_TOKEN")
    } else {
      this.props.data.history.location.pathname == "/bb_taskboard" && await this.ContentAPICall() && this.DataSettingAPICall()
    }
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.projectListApiId) {
        this.projectListResponse(responseJson, errorReponse)
      }
      if (apiRequestCallId === this.sectionListApiId) {
        this.sectionListResponse(responseJson, errorReponse)
      }
      if (apiRequestCallId === this.settingListApiId) {
        this.settingListResponse(responseJson, errorReponse)
      }

      else if (apiRequestCallId === this.sectionAPIID) {
        let tempArr: string[] = []
        responseJson.data.map((item: { attributes: { name: string } }) => {
          tempArr.push(item.attributes.name)
        })
        this.setState({ statusFlow: tempArr, statusData: responseJson.data })
      }
      else if (apiRequestCallId === this.settingsAPIID && responseJson.data) {
        this.setState({ settingData: responseJson.data.attributes, isVertical: responseJson.data.attributes.direction.toLowerCase() == "vertical" ? true : false })
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(JSON.stringify(prevProps.statusFlow) != JSON.stringify(this.props.statusFlow)){
      this.setState({statusFlow : this.props.statusFlow})
    }
  }

  apiCallFunction = async (data: ApiData) => {
    const { contentType, method, endPoint, body, } = data;

    const header = {
      "Content-Type": contentType,
      "token": await getStorageData("USER_TOKEN")
    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return requestMessagee.messageId;
  };


  ContentAPICall = async () => {
    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  DataSettingAPICall = async () => {

    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.settingsAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.settingsEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  projectListResponse = (responseJson: ProjectList2, errorReponse: string) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successOrderResponse(responseJson)
      }

    }
  }
  sectionListResponse = (responseJson: sectionList, errorReponse: string) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSectionListResponse(responseJson)
      }

    }
  }
  settingListResponse = (responseJson: settingList1, errorReponse: string) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSettingListResponse(responseJson)
      }

    }
  }

  projectList = async () => {
    this.projectListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_taskallocator/kanbanboard?from=${this.state.startDate}&to=${this.state.endDate}`,
    });
  }

  sectionList = async () => {
    this.sectionListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/sections`,
    });
  }
  settingList = async () => {
    this.settingListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/settings`,
    });
  }

  successOrderResponse(responseJson: ProjectList2) {
    this.setState(
      {
        qc_Initiated: responseJson['QC Initiated'],
        project_in_Queue: responseJson['Project in Queue'],
        dataLength: responseJson['Project in Queue'].length,
      })

  }
  successSectionListResponse(responseJson: sectionList) {
    let tempArray: string[] = []
    responseJson.data.map((item: { attributes: { section_type: string } }) => {
      tempArray.push(item.attributes.section_type)
    })
    this.setState({ statusFlow: tempArray, statusData: responseJson.data })
  }

  successSettingListResponse(responseJson: settingList1) {
    this.setState({ theam: responseJson.data.attributes.theme })

    if (responseJson.data.attributes.direction == "vertical") {
      this.setState({ isHorizontal: false })
    }
    else {
      this.setState({ isHorizontal: true })
    }
  }

  // Customizable Area End

  // Customizable Area Start

  userDateTime = (dateTime: string) => {
    let time = new Date(dateTime).toLocaleString('en-US', { timeZone: 'Asia/Kolkata', hour12: true })
    let newDate = moment(time).format('DD/MM/YYYY')
    return `${newDate}`
  }

  calendarClose = () => {
    this.setState({ modalVisible: false, startDate: this.userDateTime(this.state.selectedStartDate), endDate: this.userDateTime(this.state.selectedEndDate) }, () => {
      this.projectList()
    })
  }

  calendarOpen = () => {
    this.setState({ modalVisible: true })
  }


goBack=()=>{
  Alert.alert("Logout")
  removeStorageData("USER_TOKEN")
  const message = new Message(
    getName(MessageEnum.NavigationEmailLogInMessage)
  );

  message.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );

  this.send(message);

}
  onDateChange = (date: Moment, type: string) => {
    let dateType = date
    if (type === 'END_DATE') {
      this.setState({
        selectedEndDate: dateType.toString(),
      });
    } else {
      this.setState({
        selectedStartDate: dateType.toString(),
        selectedEndDate: '',
      });
    }
  }

  // Customizable Area End
}
