import React from 'react'
import {Box} from "@material-ui/core";

import {
    AiOutlineLeft,
    AiOutlineRight,
  } from "react-icons/ai";
  
import ProjectCarouselController, {
    Props,
    responsive
  } from "./ProjectCarouselController.web";
import Carousel from "react-multi-carousel";
import { projectCardData } from '../../utilities/src/types';

export class ProjectCarousel extends ProjectCarouselController{
    carouselRef:React.RefObject<Carousel>
    constructor(props: Props) {
      super(props);
    
        this.carouselRef = React.createRef();
    
    }
  
  
    render() {
      const {projectStatus, isVertical, statusColor} = this.props;
      const {projects,history,projectStatusColors} = this.props.data;
  
    
  
      return (
    
        <>
          <Box bgcolor={"white"} marginTop={"20"} paddingTop={"1.2vh"} className={"whatISThis"}>
            <Box
              marginTop={"10px"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Box>
                <Box
                  padding={"5px"}
                  borderRadius={"0px 30px 30px 0px;"}
                  component={"span"}
                  color={"white"}
                  bgcolor={window.location.pathname == "/bb_taskboard" ? statusColor : projectStatusColors[projectStatus] ? `#${projectStatusColors[projectStatus]}` :  "#F6C03D"}
                >
                  {projectStatus}
                </Box>
                <Box
                component={"span"}
                marginLeft={"0.5rem"}
                >
                  ( {projects[projectStatus]?.length || "0"} )
                </Box>
              </Box>
              {!isVertical && <Box
                display={"flex"}
                alignItems={"center"}
                className={"leftRightDir"}
              >
                <Box component={"div"} padding={"5"} bgcolor={"#F3F4F6"}>
                
                  <AiOutlineLeft onClick={()=>{
                    this.carouselRef.current?.previous(1)}
                    }
                    style={this.state.currentSlide == 0 ? {cursor: 'default', fill: 'gray'} : {cursor: 'pointer'}}
                    />
                </Box>
                <Box
                  marginRight={"2vw"}
                  component={"div"}
                  padding={"5"}
                  bgcolor={"#F3F4F6"}
                >
                  <AiOutlineRight onClick={()=>{
                    this.carouselRef.current?.next(1)
                    }}
                    style={(this.state.currentSlide == projects[projectStatus]?.length - responsive[this.state.deviceType].items as number) || projects[projectStatus]?.length - responsive[this.state.deviceType].items as number < 0  ? {cursor: 'default', fill: 'gray'} : {cursor: 'pointer'} }
                    />
                </Box>
              </Box>}
            </Box>
  
  
            <Box
            onFocus={this.activeKeyBoardControlToggleTrue}
            onMouseEnter={this.activeKeyBoardControlToggleTrue}
            onBlur={this.activeKeyBoardControlToggleFalse}
            onMouseLeave={this.activeKeyBoardControlToggleFalse}
            className={"newBoxx"}
            >
            {projects[projectStatus] && <Carousel
              ref = {this.carouselRef}
              arrows={false}
              slidesToSlide={1}
              renderButtonGroupOutside={true}
              responsive={responsive}
              containerClass="makeItScrollable"
              ssr
              draggable={isVertical ? false : true}
              keyBoardControl={this.state.ActiveKeyBoardControl}
              beforeChange={(nextSlide,element) => this.beforeChangeHandler(nextSlide, element.deviceType)}
              afterChange={prevSlide => this.afterChangeHandler(prevSlide)}
            >
             
              {projects[projectStatus].map((element ,index:number)=>{
                return (
                  <this.props.card key={index}  data={{"projectData":element as unknown as projectCardData['projectData'],"history":history ,"dotColor":projectStatusColors[projectStatus] ? `#${projectStatusColors[projectStatus]}` : "red", projectStatusColors: projectStatusColors }}  priority={index == 2}/>
                )
              })}
  
  
            </Carousel>}
            </Box>
          </Box>
        </>
      
      );
    }
  }